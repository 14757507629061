import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { filter } from 'lodash';

import ProfileHeader from './ProfileHeader';
import ProfilePosts from './ProfilePosts';
import { getUser } from '../../actions/ProfileActions';

const ProfilePage = ({ getUserAction, magazines, match, user }) => {
  const { userId } = match.params;
  const [userPosts, setUserPosts] = useState([]);

  useEffect(() => {
    let posts = [];
    getUserAction(userId);
    magazines.forEach((magazine) => {
      posts = posts.concat(magazine.posts);
    });
    posts = filter(posts, post => post.user.uid === userId);
    posts.reverse();
    setUserPosts(posts);
  }, [getUserAction, magazines, userId]);

  return (
    <div>
      <ProfileHeader posts={userPosts} user={user} />
      <ProfilePosts posts={userPosts} />
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.userProfile.info,
});

const mapDispatchToProps = {
  getUserAction: getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
