import { DateTime } from 'luxon';
import {
  MAGAZINE_INSERT_START,
  MAGAZINE_INSERT_SUCCEESS,
  MAGAZINE_INSERT_FAILURE,
  LOAD_GROUP_MAGAZINES_START,
  LOAD_GROUP_MAGAZINES_FAIL,
  LOAD_GROUP_MAGAZINES_SUCCESS,
  LOAD_ACTIVE_MAGAZINES_START,
  LOAD_ACTIVE_MAGAZINES_FAIL,
  LOAD_ACTIVE_MAGAZINES_SUCCESS,
  LOAD_PAST_MAGAZINES_START,
  LOAD_PAST_MAGAZINES_FAIL,
  LOAD_PAST_MAGAZINES_SUCCESS,
  SET_BUYING_MAGAZINE_WITH_NEW_CARD,
} from '../actions/types';

const INITIAL_STATE = {
  actualGroupMagazines: [],
  isLoading: false,
  errorMessage: '',
  loadSucceededGroupCode: false,
  succeeded: false,
  activeMagazine: {},
  buyingMagazineWithNewCard: false,
  newMagazine: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
  activeMagazines: {
    isLoading: false,
    error: false,
    succeeded: false,
    data: [],
  },
  pastMagazines: {
    isLoading: false,
    error: false,
    succeeded: false,
    data: [],
  },
  design: {
    curiosities: [],
    isLoading: false,
    error: false,
    succeeded: false,
    data: [],
  },
};

let activeMagazine = {};

const setActiveState = (magazines = []) => magazines.map((magazine) => {
  const newMagazine = magazine;
  newMagazine.active = magazine.endDate >= DateTime.local().toISO();
  if (newMagazine.active) activeMagazine = newMagazine;
  return newMagazine;
});

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case MAGAZINE_INSERT_START:
      return { ...state,
        newMagazine: { ...state.magazine,
          isLoading: true } };
    case MAGAZINE_INSERT_SUCCEESS:
      return { ...state,
        actualGroupMagazines: [payload.magazine],
        newMagazine: { ...state.magazine,
          isLoading: false,
          errorMessage: '',
          succeeded: true } };
    case MAGAZINE_INSERT_FAILURE:
      return { ...state,
        newMagazine: { ...state.magazine,
          isLoading: false,
          errorMessage: payload.message,
          succeeded: false } };
    case LOAD_GROUP_MAGAZINES_START:
      return { ...state,
        isLoading: true };
    case LOAD_GROUP_MAGAZINES_FAIL:
      return { ...state,
        isLoading: false,
        errorMessage: payload.message };
    case LOAD_GROUP_MAGAZINES_SUCCESS: {
      const magazines = setActiveState(payload.magazines);
      return { ...state,
        isLoading: false,
        errorMessage: '',
        loadSucceededGroupCode: payload.groupCode,
        activeMagazine,
        actualGroupMagazines: magazines };
    }
    case LOAD_ACTIVE_MAGAZINES_START:
      return { ...state, activeMagazines: { ...state.activeMagazines, isLoading: true } };
    case LOAD_ACTIVE_MAGAZINES_FAIL:
      return { ...state, activeMagazines: { ...state.activeMagazines, isLoading: false, error: true } };
    case LOAD_ACTIVE_MAGAZINES_SUCCESS:
      return { ...state,
        activeMagazines: { ...state.activeMagazine,
          isLoading: false,
          succeeded: true,
          data: payload.magazines } };
    case LOAD_PAST_MAGAZINES_START:
      return { ...state, pastMagazines: { ...state.pastMagazines, isLoading: true } };
    case LOAD_PAST_MAGAZINES_FAIL:
      return { ...state, pastMagazines: { ...state.pastMagazines, isLoading: false, error: true } };
    case SET_BUYING_MAGAZINE_WITH_NEW_CARD:
      return { ...state, buyingMagazineWithNewCard: payload.flag };
    case LOAD_PAST_MAGAZINES_SUCCESS:
      return { ...state,
        pastMagazines: { ...state.pastMagazines,
          isLoading: false,
          succeeded: true,
          data: payload.magazines } };
    default:
      return state;
  }
};
