import firebase from 'firebase';
import { DateTime } from 'luxon';
import { isNil } from 'lodash';
import i18n from 'i18next';

import { signUp } from './UserActions';
import getS3Client from '../config/S3Client';
import { createCustomer, createSubscription } from '../services/stripe';

import {
  LOAD_GROUP_INFO_START,
  LOAD_GROUP_INFO_SUCCESS,
  LOAD_GROUP_INFO_FAILURE,
  LOAD_GROUP_USERS_START,
  LOAD_GROUP_USERS_SUCCESS,
  CLEAR_GROUP_INFO_START,
  GROUP_UPDATE_RESET,
  GROUP_UPDATE_START,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAILURE,
  LOAD_ALL_GROUPS_START,
  LOAD_ALL_GROUPS_SUCCESS,
  LOAD_ALL_GROUPS_FAIL,
  GROUP_CREATE_START,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAILURE,
  GROUP_CREATE_INIT,
  POST_OPEN_MODAL,
  CLOSE_POST_MODAL,
} from './types';

import { insertMagazine } from './MagazineActions';

export const loadGroupInfo = groupCode => (
  (dispatch) => {
    dispatch({ type: LOAD_GROUP_INFO_START });
    firebase.database().ref(`/groups/${groupCode}/info`).once('value')
      .then((snapshot) => {
        const groupInfo = snapshot.val();
        if (isNil(groupInfo)) {
          const errorMessage = 'Código de grupo incorrecto. Por favor intente nuevamente.';
          dispatch({ type: LOAD_GROUP_INFO_FAILURE, payload: { errorMessage } });
        } else {
          groupInfo.groupCode = groupCode;
          dispatch({ type: LOAD_GROUP_INFO_SUCCESS, payload: { groupInfo } });
        }
      });
  }
);

export const loadGroupUsers = groupCode => (
  (dispatch) => {
    dispatch({ type: LOAD_GROUP_USERS_START });
    firebase.database().ref(`/groups/${groupCode}/users`).on('value', (snapshot) => {
      const users = [];
      snapshot.forEach((snap) => { users.push({ ...snap.val(), key: snap.key }); });

      dispatch({ type: LOAD_GROUP_USERS_SUCCESS, payload: { users } });
    });
  }
);

export const likePost = (post, groupCode, magazineId) => (
  async (dispatch, getState) => {
    const like = { ...getState().user.info, createdAt: DateTime.local().toISO() };
    firebase.database().ref(`/magazines/${magazineId}/posts/${post.key}/likes`).push(like);
  }
);

export const unlikePost = (post, groupCode, likeCode, magazineId) => (
  async () => {
    firebase.database().ref(`/magazines/${magazineId}/posts/${post.key}/likes/${likeCode}`).remove();
  }
);

export const clearGroupInfo = () => ({
  type: CLEAR_GROUP_INFO_START,
});

export const initCreateGroup = () => ({
  type: GROUP_CREATE_INIT,
});

export const createGroup = (cardToken, coupon, type,
  name, planId, subscriptionType, postsLimit, usersLimit, eventDate, user, redirectAfterSuccess) => (
  (dispatch) => {
    dispatch({ type: GROUP_CREATE_START });
    const code = generateGroupCode(); // TODO: check if not repeated!! That would erease previous group
    const createdAt = DateTime.local().toISO();
    let stripeCustomerId = '';

    const info = { type,
      name,
      planId,
      subscriptionType,
      postsLimit,
      usersLimit,
      createdAt,
      eventDate,
      defaultRole: 'publisher',
      groupCode: code,
      subscriptionStatus: true,
      firstTime: true };

    const customer = {
      description: `account - ${user.email}`,
      email: user.email,
      name: `${user.name} ${user.lastname}`,
      source: cardToken,
    };

    const errorHandler = (message) => {
      dispatch({ type: GROUP_CREATE_FAILURE, payload: { message } });
    };

    // If the subscription succeeds create group in firebase
    const createSubscriptionSuccess = (stripeSubscription) => {
      firebase.database().ref(`/groups/${code}`).set({ info })
        .then(() => {
          const callback = () => redirectAfterSuccess(code);
          dispatch(insertMagazine(code, postsLimit, subscriptionType, 1));
          if (isNil(user.uid)) {
            dispatch(
              signUp(
                code,
                user.name,
                user.lastname,
                user.email,
                user.password,
                'payer',
                callback,
                stripeCustomerId,
                stripeSubscription.id,
                user.birthday,
              ),
            );
          } else {
            firebase.database().ref(`/users/${user.uid}/info`).set(
              { ...user,
                stripeSubscriptions: { ...user.stripeSubscriptions,
                  [code]: stripeSubscription.id },
                stripeId: stripeCustomerId },
            );
            firebase.database().ref(`/users/${user.uid}/groups/${code}`).set({
              name: info.name, type: info.type, createdAt: DateTime.local().toISO(),
            });
            firebase.database().ref(`/groups/${code}/users`).push(
              { ...user,
                role: 'payer',
                stripeId: stripeCustomerId,
                stripeSubscriptions: { ...user.stripeSubscriptions, [code]: stripeSubscription.id } },
            ).then(callback);
          }
        });
      dispatch({ type: GROUP_CREATE_SUCCESS });
    };

    // If create customer succeeds create subscription
    const createCustomerSuccess = (stripeCustomer) => {
      // Call create subscription
      stripeCustomerId = stripeCustomer.id;
      createSubscription(coupon, planId, stripeCustomer.id,
        createSubscriptionSuccess, errorHandler); // Handle errors
    };

    // Check if customer is created in stripe
    if (cardToken) {
      // If there is no card registered create customer
      createCustomer(customer, createCustomerSuccess, errorHandler); // Handle errors
    } else {
      // Set stripe customer id and call subscription
      stripeCustomerId = user.stripeId;
      createSubscription(coupon, planId, user.stripeId, createSubscriptionSuccess, errorHandler);
    }
  }
);

export const resetUpdateGroupState = () => (
  (dispatch) => {
    dispatch({ type: GROUP_UPDATE_RESET });
  }
);

export const updateGroup = (groupInfo, photo) => (
  async (dispatch) => {
    dispatch({ type: GROUP_UPDATE_START });
    const modifiedAt = DateTime.local().toISO();
    const info = { ...groupInfo, modifiedAt, firstTime: false };
    try {
      if (!isNil(photo)) {
        const S3Client = getS3Client('profilePics');
        const data = await S3Client.uploadFile(photo);
        info.photoURL = data.location;
      }
      
      await firebase.database().ref(`/groups/${groupInfo.groupCode}`).update({ info });
      dispatch({ type: GROUP_UPDATE_SUCCESS, payload: { info } });
    } catch (e) {
      dispatch({
        type: GROUP_UPDATE_FAILURE,
        payload: { message: i18n.t('No se pude guardar los cambios. Por favor intente nuevamente.') },
      });
    }
  }
);

export const loadAllGroups = () => (
  (dispatch) => {
    try {
      dispatch({ type: LOAD_ALL_GROUPS_START });
      firebase.database().ref('/groups').on('value', (snapshot) => {
        const groups = [];
        snapshot.forEach((snap) => {
          groups.push(snap.val());
        });
        groups.reverse();
        dispatch({ type: LOAD_ALL_GROUPS_SUCCESS, payload: { groups } });
      });
    } catch (e) {
      dispatch({ type: LOAD_ALL_GROUPS_FAIL });
    }
  }
);

export const openPostModal = key => (
  (dispatch) => {
    dispatch({ type: POST_OPEN_MODAL, payload: { key } });
  }
);

export const closePostModal = () => (
  (dispatch) => {
    dispatch({ type: CLOSE_POST_MODAL });
  }
);

const generateGroupCode = () => {
  let code = '';
  const db = 'abcdefghijklmnpqrstuvwxyz123456789';

  for (let i = 0; i < 5; i += 1) {
    code += db.charAt(Math.floor(Math.random() * db.length));
  }

  return code;
};
