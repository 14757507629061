import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch, Route, Link, withRouter } from 'react-router-dom';
import { Tabs, Tab, Typography } from '@material-ui/core';
import useStyles from '../../../theme/Styles';
import Curiosities from './Curiosities';
import FrontCover from './FrontCover';

const MagazineDesign = ({ t, location }) => {
  const classes = useStyles()();

  let newPathName = '/app/administration/magazine-design/curiosities';

  if (location.pathname.includes('/app/administration/magazine-design/curiosities')) {
    newPathName = '/app/administration/magazine-design/curiosities';
  }

  if (location.pathname.includes('/app/administration/magazine-design/front-cover')) {
    newPathName = '/app/administration/magazine-design/front-cover';
  }

  return (
    <div className={classes.verticalTabsAdminContainer}>
      <Tabs
        value={newPathName}
        classes={{
          indicator: classes.verticalTabsAdminTabIndicator,
          flexContainer: classes.verticalTabsAdminFlex,
        }}
        className={classes.verticalTabsAdmin}
      >
        <Tab
          label={t('Curiosidades')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazine-design/curiosities"
          value="/app/administration/magazine-design/curiosities"
        />
        <Tab
          label={t('Imagenes de Portada')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazine-design/front-cover"
          value="/app/administration/magazine-design/front-cover"
        />
      </Tabs>
      <div className={classes.adminInfoContainer}>
        <TabContainer>
          <Switch>
            <Route
              path="/app/administration/magazine-design/curiosities"
              component={() => <Curiosities />}
            />
            <Route
              path="/app/administration/magazine-design/front-cover"
              component={() => <FrontCover />}
            />
            <Redirect
              exact
              from="/app/administration/magazine-design"
              to="/app/administration/magazine-design/curiosities"
            />
            <Redirect
              exact
              from="/app/administration/magazine-design/*"
              to="/app/administration/magazine-design/curiosities"
            />
          </Switch>
        </TabContainer>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(MagazineDesign));

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">
      { children }
    </Typography>
  );
}
