import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { Redirect, Switch, Route, Link, withRouter } from 'react-router-dom';
import { Contacts, ImportContacts, ViewQuilt, Favorite, Settings } from '@material-ui/icons';

import useStyles from '../../theme/Styles';
import GridView from '../magazines/GridView';
import AlbumView from '../magazines/AlbumView';
import NewsfeedView from '../magazines/NewsfeedView';
import MagazineView from '../magazines/MagazineView';
import NewsLoader from '../common/NewsLoader';
import Config from '../config';
import ProfilePage from '../users/ProfilePage';

const GroupTabs = ({ magazines, groupCode, location }) => {
  const classes = useStyles()();
  let newPathName = `/app/g/${groupCode}/news_feed`;
  if (location.pathname.includes(`/app/g/${groupCode}/news_feed`)) newPathName = `/app/g/${groupCode}/news_feed`;
  if (location.pathname.includes(`/app/g/${groupCode}/album`)) newPathName = `/app/g/${groupCode}/album`;
  if (location.pathname.includes(`/app/g/${groupCode}/favorite`)) newPathName = `/app/g/${groupCode}/favorite`;
  if (location.pathname.includes(`/app/g/${groupCode}/magazines`)) newPathName = `/app/g/${groupCode}/magazines`;
  if (location.pathname.includes(`/app/g/${groupCode}/configuration`)) {
    newPathName = `/app/g/${groupCode}/configuration`;
  }
  return (
    <div>
      <Tabs
        value={newPathName}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.magazineTabsContainer}
      >
        <Tab
          icon={<Contacts />}
          component={Link}
          to={`/app/g/${groupCode}/news_feed`}
          value={`/app/g/${groupCode}/news_feed`}
        />
        <Tab
          icon={<ViewQuilt />}
          component={Link}
          to={`/app/g/${groupCode}/album`}
          value={`/app/g/${groupCode}/album`}
        />
        <Tab
          icon={<Favorite />}
          component={Link}
          to={`/app/g/${groupCode}/favorite`}
          value={`/app/g/${groupCode}/favorite`}
        />
        <Tab
          icon={<ImportContacts />}
          component={Link}
          to={`/app/g/${groupCode}/magazines`}
          value={`/app/g/${groupCode}/magazines`}
        />
        <Tab
          icon={<Settings />}
          component={Link}
          to={`/app/g/${groupCode}/configuration`}
          value={`/app/g/${groupCode}/configuration`}
        />
      </Tabs>
      <div className={classes.content}>
        {magazines.isLoading
          ? <NewsLoader />
          : (
            <Switch>
              <Route
                path={`/app/g/${groupCode}/news_feed`}
                component={() => <NewsfeedView magazines={magazines.actualGroupMagazines} />}
              />
              <Route
                path={`/app/g/${groupCode}/album`}
                component={() => <AlbumView magazines={magazines.actualGroupMagazines} />}
              />
              <Route
                path={`/app/g/${groupCode}/favorite`}
                component={() => <GridView magazines={magazines.actualGroupMagazines} />}
              />
              <Route
                path={`/app/g/${groupCode}/magazines`}
                component={() => <MagazineView magazines={magazines.actualGroupMagazines} />}
              />
              <Route
                path={`/app/g/${groupCode}/configuration`}
                component={() => <Config groupCode={groupCode} />}
              />
              <Route
                path="/app/g/:groupCode/profile/:userId"
                component={props => (
                  <ProfilePage
                    {...props}
                    groupCode={groupCode}
                    magazines={magazines.actualGroupMagazines}
                  />
                )}
              />
              <Redirect exact from={`/app/g/${groupCode}/*`} to={`/app/g/${groupCode}/news_feed`} />
              <Redirect exact from={`/app/g/${groupCode}`} to={`/app/g/${groupCode}/news_feed`} />
            </Switch>
          )
      }
      </div>
    </div>
  );
};

export default withRouter(GroupTabs);
