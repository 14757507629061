import React from 'react';
import { AppBar, Tabs, Tab, Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch, Route, Link, withRouter } from 'react-router-dom';
import Login from '../users/Login';
import RecoveryPassword from '../users/RecoveryPassword';
import SignUp from '../users/sign_up';
import NewGroup from '../groups/create_group';
import useStyles from '../../theme/Styles';


const SideBar = ({ t, location }) => {
  const classes = useStyles()();

  let newPathName = '';
  if (location.pathname.includes('/auth/sign_up')) newPathName = '/auth/sign_up';
  if (location.pathname.includes('/auth/new_group')) newPathName = '/auth/new_group';
  if (location.pathname.includes('/auth/login')) newPathName = '/auth/login';
  if (location.pathname.includes('/auth/recovery_password')) newPathName = '/auth/login';

  return (
    <Grid container>
      <Grid item lg={2} xs={false} />
      <Grid item lg={8} xs={12} className={classes.landingPageSideBarGrid}>
        <AppBar position="static">
          <Tabs
            value={newPathName}
            centered
            classes={{ indicator: classes.landingPageSideBarTabIndicator }}
            className={classes.landingPageSideBarTabs}
          >
            <Tab label={t('Iniciar sesión')} component={Link} to="/auth/login" value="/auth/login" />
            <Tab label={t('Unirme a un grupo')} component={Link} to="/auth/sign_up" value="/auth/sign_up" />
            <Tab label={t('Crear Nuevo Grupo')} component={Link} to="/auth/new_group" value="/auth/new_group" />
          </Tabs>
        </AppBar>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/recovery_password" component={RecoveryPassword} />
          <Route path="/auth/sign_up" component={SignUp} />
          <Route path="/auth/new_group" component={NewGroup} />
          <Redirect exact from="/auth/*" to="/auth/login" />
        </Switch>
      </Grid>
      <Grid item lg={2} xs={false} />
    </Grid>
  );
};

export default withRouter(withTranslation()(SideBar));
