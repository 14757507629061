import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import _en from '../lang/en';
import _es from '../lang/es';

const resources = {
  en: {
    translation: { ..._en },
  },
  es: {
    translation: { ..._es },
  },
};

export default (lang) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: lang,

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};
