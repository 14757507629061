/* eslint-disable */
export default {
  'Escribe tu mensaje': 'Write your message',
  'Publicar': 'Post',
  'Publicado': 'Posted',
  'Miembro': 'Member',
  'Miembros': 'Members',
  'Revistas': 'Magazines',
  'Revista': 'Magazine',
  'Publicación': 'Post',
  'Publicaciones': 'Posts',
  'Familiares': 'Family members',
  'Enviar Invitación': 'Send invitation',
  'Ver Perfil': 'See profile',
  'Editar Perfil': 'Edit Profile',
  'Configuración': 'Settings',
  'Español': 'Spanish',
  'Ingles': 'English',
  'Francés': 'French',
  'Frances': 'French',
  'Cambiar foto': 'Change picture',
  'Los datos fueron actualizados correctamente': 'Changes updated',
  'Nombre': 'First name',
  'Apellido': 'Last name',
  'Información Personal': 'Personal information',
  'Actualizar': 'Update',
  'Menú': 'Menu',
  'No se pude guardar los cambios. Por favor intente nuevamente.': 'We could not save the changes. Please try again.',
  'Nuevo Grupo': 'New group',
  'Familia': 'Family',
  'Negocio': 'Business',
  'Empresa': 'Company',
  'Asilo': 'Retirement home',
  'Geriatrico': 'Nursing home',
  'Evento': 'Event',
  'Atrás': 'Back',
  'Datos del nuevo grupo': 'New group information',
  'Nombre': 'Name',
  'Continuar': 'Continue',
  'Por favor seleccione su plan de suscripción': 'Please select your subscription plan',
  'Basic': 'Basic',
  'Home': 'Home',
  'Pro': 'Pro',
  'Mes': 'Month',
  'Usuarios': 'Users',
  'Usuarios ilimitados': 'Unlimited users',
  'Seleccionar': 'Select',
  'Datos del administrador': 'Administrator information',
  'Apellido': 'Last name',
  'Email': 'Email',
  'Contraseña': 'Password',
  'Crear Grupo': 'Create group',
  'de': 'of', // 10 de 20...
  'Descargar': 'Download',
  'Recortar imagen': 'Crop image',
  'Cancelar': 'Cancel',
  'Grupos': 'Groups',
  'Grupo': 'Group',
  'Código de grupo incorrecto. Por favor intente nuevamente.': 'Wrong group code. Please try again.',
  'No se pudo crear la revista. Por favor intente nuevamente.': 'The magazine could not be created correctly. Please try again.',
  'No se pudo cargar las revistas por grupo. Por favor intente nuevamente.': 'The magazines per group could not be uploaded. Please try again.',
  'No se pudo subir tu post. Por favor intente nuevamente.': 'Your post could not be uploaded. Please try again.',
  'Id de usuario incorrecto. Por favor intente nuevamente.': 'Incorrect User Id.Please try again.',
  'El código de grupo o familia es incorrecto. Por favor intente nuevamente.': 'The group or family code is incorrect. Please try again.',
  'No se pudo registrar la cuenta. Por favor verifica el estado de tu conexión a internet': 'The account could not be created. Please check your wifi connection',
  // Admin Panel
  'Id': 'Id',
  'Type': 'Type',
  'Subscription type': 'Subscription type',
  'Users count': 'Users count',
  'Tipo de Plan': 'Type of plan',
  'Los datos fueron actualziados correctamente': 'Information updated correctly',
  'Guardar cambios': 'Save changes',
  'Tipo de Grupo': 'Group type',
  'There are no magazines for this group,': 'There are no magazines for this group,',
  'Payer': 'Payer',
  'Viewer': 'Viewer',
  'Publisher': 'Publisher',
  'Admin': 'Admin',
  'Información': 'Information',
  'El grupo no tiene usuarios.': 'The group has no users.',
  'Para imprimir': 'For printing',
  'Activos': 'Active',
  'Activo': 'Active',
  'Pasados': 'Closed',
  'Pasado': 'Closed',
  'Edition': 'Edition',
  'Start date': 'Start date',
  'End Date': 'End Date',
  'Limit': 'Limit',
  'Post count': 'Post count',
  'Something happened loading the info. Please try again later.': 'Something happened loading the info. Please try again later.',
  'Cerrar Sesion': 'Sign out',
  'Zoom In': 'Zoom In',
  'Zoom Out': 'Zoom Out',
  'Suelta tu foto aquí': 'Put picture here',
  'Arrastra aquí tu foto o': 'Drag your picture here or',
  'Selecciona un archivo': 'Select a file',
  'Preview': 'Preview',
  'Bi semanal': 'Twice per week',
  'Bi mensual': 'Twice per month',
  'Mensual': 'Monthly',
  'Pago Mensual': 'Monthly payment',
  'Pago Anual': 'Annual payment',
  'Configurar tarjeta': 'Card settings',
  'Algo sucedio. Porfavor intentalo despues.': 'Something went wrong. Please try again later.',
  'Tarjeta actual': 'Current credit card',
  'No tienes una tarjeta registrada aun.': 'You did not register a card yet.',
  'Registrar una nueva tarjeta': 'Register a new card',
  'Registrar tarjeta': 'Register card',
  'Foto de Perfil': 'Profile picture',
  'Usuarios Del Grupo': 'Group users',
  'Editar Grupo': 'Edit Group',
  'Nombre del Grupo': 'Group name',
  'Texto de la revista': 'Text of the magazine',
  'Rol para nuevos miembros': 'New member rol',
  'Cambiar de Plan': 'Change plan',
  'Guardar Cambios': 'Save changes',
  'Los datos fueron actualziados correctamente': 'Information updated correctly',
  'Pagos': 'Payments',
  'Pago': 'Payment',
  'Información grupo': 'Group information',
  'Suscripción': 'Subscription',
  'Cambia la foto del grupo': 'Change the picture of the group',
  'Puedes cambiarla en un futuro en': 'You can change it in the future in',
  'Configuracion > Grupo': 'Settings > Group',
  'Configuracion > Revista': 'Settings > Magazine',
  'Cambia el texto de la revista': 'Change the magazines text',
  'Este texto se agregara en todas las revistas': 'This text will be added to all magazines',
  'Datos del grupo': 'Group information',
  'Nombre Grupo': 'Group name',
  'El cupón es invalido.': 'Invalid coupon.',
  '¿Tienes un cupón?': 'Do you have a coupon?',
  'Atras': 'Back',
  'fotos': 'Pictures',
  'por revista': 'per magazine',
  'Incluye una cuenta administrativa': 'Includes an administrative account',
  'adicional por sub-cuenta': 'Extra per sub-account',
  'por revista impresa': 'per printed magazine',
  'Numero de familias': 'Number of families',
  'Ilimitado': 'Unlimited',
  ' ilimitados': 'Unlimited',
  'por revista impresa (para las familias)': 'per printed magazine (for families)',
  'Papel de': 'Paper of',
  'alta calidad': 'high quality',
  'Envios a toda Bolivia': 'Delivery everywhere in Bolivia',
  'Por favor seleccione su plan de suscripción': 'Please select your subscription plan',
  'Obten 2 meses gratis por pago anual': 'Get 2 months free for an annual membership',
  'The email is already taken': 'The email is already taken',
  'Password should be at least 6 characters': 'Password should be at least 6 characters',
  'The email address is badly formatted.': 'The email address is badly formatted.',
  'Información Usuario': 'User information',
  'Confirmacion del pago': 'Payment confirmation',
  'Algo sucedio. Porfavor intentalo despues.': 'Something went wrong. Please try again later.',
  'Plan': 'Plan',
  'Precio': 'Price',
  'Card': 'Card',
  'Unirme a un grupo': 'Join a group',
  'Nuevo Grupo': 'New Group',
  'Faltan {{dias}} días': '{{dias}} days remaining',
  'Enviar invitación': 'Send Invitation',
  'Código de grupo': 'Group code',
  'Edit group image' : 'Edit group image',
  'Direccion de envio': 'Shipping Address',
  'Direccion de envío': 'Shipping Address',
  'Direccion': 'Address',
  'Titulo de revista': 'Title of magazine',
  'Frase de revista': 'Phrase of magazine',
  'Pais': 'Country',
  'Estado': 'State',
  'Ciudad': 'City',
  'Codigo Zip': 'Zip Code',
  'No le diste Me Gusta a ninguna publicacion': "You didn't Like any post",
  'Editar': 'Edit',
  'Eliminar': 'Delete',
  'Comprar': 'Buy',
  'Editar foto de perfil': 'Change profile photo',
  'Cumpleanos': 'Birthday',
  'Lenguaje del grupo': 'Group language',
  'Estado Suscripcion': 'Subscription Status',
  'Generar PDF': 'Generate PDF',
  'Generar': 'Generate',
  'Emails enviados': 'Emails sent',
  'No disponible, intente mas tarde': 'Not available, please try later',
  'Llenar emails': 'Fill emails',
  'Para invitar a otras personas a este grupo, enviales el siguiente codigo para que lo utilicen al momento de crear una cuenta':
  'To invite other people to this group, send them the following code to use when creating an account',
  'O enviales una invitacion por correo con el siguiente formulario': 'Or send them an invitation by mail with the following form',
  'Escribe los correos electronicos seguidos por un espacio': 'Write the emails followed by a space',
  'Enviar': 'Send',
  'Cerrar': 'Close',
  'Esta no revista no contiene ninguna publicación': 'This magazine has no posts',
  'Aún no hay ninguna publicación.': 'There is no post yet.',
  'Se el primero en publicar!': 'Be the first to publish!',
  'Nueva Publicación': 'New Post',
  'Notificaciones': 'Notifications',
  'Notificarme cuando alguien añada una publicacion': 'Notify me when someone adds a post',
  'Notificarme cuando la revista esta por cerrarse': 'Notify me when the magazine is about to close',
  'Notificarme cuando una nueva revista fue creada': 'Notify me when a new magazine was created',
  'Editar Publicación': 'Edit Post',
  'Por favor ingresar correos electrónicos': 'Please enter emails',
  'La invitación fue enviada correctamente': 'The invitation was sent successfully',
  'No se pudo enviar la invitación, por favor intente mas tarde': 'The invitation could not be sent, please try again later',
  'Para Enviar': 'To Send',
  'Enviados': 'Sent',
  'Enviado': 'Sent',
  'Compra exitosa. Enviaremos tu revista impresa a tu dirección!': 'Successful purchase. We will send your printed magazine to your address!',
  'Fecha compra': 'Purchase date',
  'Fecha envio': 'Shipping date',
  'Bienvenido': 'Welcome',
  'Cambia tu foto de perfil': 'Edit your profile picture',
  'Configuracion > Perfil': 'Configuration > Profile',
  'Cumpleaños': 'Birthday',
  'Faltan completar datos': 'Please complete all fields',
  'Por favor ingresa tu cumpleaños': 'Please insert your birthday',
  'Precio de revista': 'Magazine Price',
  'Deshacer Envio': 'Undo Shipping',
}
