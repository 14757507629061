import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_POSTS_START,
  GET_USER_POSTS_SUCCESS,
  GET_USER_POSTS_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  errorCode: '',
  errorMessage: '',
  info: {
    uid: '',
    email: '',
    displayName: '',
    photoUrl: '',
  },
  userPosts: [],
  userPostsLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case GET_USER_START:
      return { ...state, isLoading: true, errorMessage: '' };
    case GET_USER_SUCCESS: {
      const { user } = payload;
      return { ...state, isLoading: false, info: user };
    }
    case GET_USER_FAILURE: {
      const { errorMessage } = payload;
      return { ...state, isLoading: false, errorMessage };
    }
    case GET_USER_POSTS_START:
      return { ...state, userPostsLoading: true, errorMessage: '' };
    case GET_USER_POSTS_SUCCESS:
      return { ...state, userPosts: payload.posts, userPostsLoading: false };
    case GET_USER_POSTS_FAILURE:
      return { ...state, errorMessage: payload.errorMessage };

    default:
      return state;
  }
};
