import React from 'react';
import { Grid, Dialog, Typography, Button, DialogActions, Avatar, DialogContent } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { PersonAdd } from '@material-ui/icons';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import useStyles from '../../theme/Styles';

const UserProfilePic = ({ shortName, displayName, color, photoURL }) => (
  <React.Fragment>
    {!isEmpty(photoURL) ? (
      <Avatar alt={displayName} src={photoURL} style={{ width: '50px', height: '50px' }} />
    ) : (
      <Avatar
        style={{ backgroundColor: color, width: '50px', height: '50px' }}
      >
        {shortName}
      </Avatar>
    )}
  </React.Fragment>
);

const MembersModal = ({ onClose, open, t, users, openInviteMembersModal, ...other }) => {
  const classes = useStyles()();
  const userGrid = users.map((user) => {
    const { displayName, color, photoURL } = user;
    const shortName = `${displayName[0]}${displayName.split(' ').length > 1 ? displayName.split(' ')[1][0] : ''}`;
    return (
      <Grid container className={classes.membersModalUSerContent} key={displayName}>
        <Grid item xs={2}>
          <UserProfilePic
            displayName={displayName}
            color={color}
            photoURL={photoURL}
            shortName={shortName}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2" className={classes.membersModalDisplayName}>
            {user.displayName}
          </Typography>
        </Grid>
      </Grid>
    );
  });
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="post-dialog"
      onClose={onClose}
      {...other}
    >
      <DialogContent>
        <Grid container>
          {userGrid}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('Cerrar')}
        </Button>
        <Button color="inherit" size="small" variant="outlined" onClick={openInviteMembersModal}>
          <PersonAdd className={clsx(classes.iconLeft, classes.iconSmall)} />
          {t('Enviar invitación')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(MembersModal);
