import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import useStyles from '../../../theme/Styles';
import { loadActiveMagazines } from '../../../actions';
import MagazineCard from './MagazineCard';
import NewsLoader from '../../common/NewsLoader';
import ErrorCard from '../ErrorCard';

const MagazineCurrent = ({ t, loadActiveMagazinesAction, succeeded, isLoading, error, magazines }) => {
  const classes = useStyles()();

  /* eslint-disable */
  useEffect(() => {
    if (!succeeded) loadActiveMagazinesAction();
  }, []);
  /* eslint-enable */

  let magazineGrid = [];
  if (magazines) {
    magazineGrid = magazines.map(magazine => (
      <Grid item xs={12} sm={6} lg={4} key={magazine.key}>
        <MagazineCard magazine={magazine} />
      </Grid>
    ));
  }

  return (
    <div>
      { isLoading && <NewsLoader /> }
      { (!isLoading && error) && <ErrorCard />}
      { (!isLoading && !error)
          && (
          <span>
            <p className={classes.configFormTitle}>
              {t('Activos')}
            </p>
            <Grid container spacing={2}>
              {magazineGrid}
            </Grid>
          </span>
          )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.magazines.activeMagazines.isLoading,
  error: state.magazines.activeMagazines.error,
  succeeded: state.magazines.activeMagazines.succeeded,
  magazines: state.magazines.activeMagazines.data,
});

const mapDispatchToProps = {
  loadActiveMagazinesAction: loadActiveMagazines,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MagazineCurrent));
