import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import useStyles from '../../theme/Styles';
import { Images } from '../../theme';
import SideBar from './SideBar';

const LandingPage = ({ loggedIn, t }) => {
  const classes = useStyles()();
  return (
    <React.Fragment>
      {loggedIn === true && <Redirect to="/" />}
      <Grid container>
        <Grid item sm={6} xs={12} className={classes.landingPageImage}>
          <div className={classes.landingPageLogo}>
            <img src={Images.logo} alt="Logo" className={classes.landingPageLogoImage} />
            <p className={classes.landingPageLogoText}>
              {t('Vuelve a conectar con quienes mas amas!')}
            </p>
          </div>
        </Grid>
        <Grid item sm={6} xs={12} className={classes.landingPageSideBar}>
          <SideBar />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
});

export default connect(mapStateToProps)(withTranslation()(LandingPage));
