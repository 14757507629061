import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Fab, Grid, Card, CardContent, withMobileDialog } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import useStyles from '../../../theme/Styles';
import planTypes from '../../common/planTypes';
import groupTypes from '../../common/groupTypes';


const PlanCard = ({ plan, onClick, t }) => {
  const classes = useStyles()();
  return (
    <Grid item>
      <Card className={classes.landingPlanCard}>
        <CardContent>
          <p className={classes.landingPlanTitle}>{plan.name}</p>
          <span className={classes.landingPriceTag}>
            {`$${plan.price}`}
          </span>
          <span className={classes.landingPriceMonth}>
            /
            {t('Mes')}
          </span>
          <ul className={classes.landingPriceList}>
            <li>
              {plan.description}
            </li>
            <li>
              <strong>{`${plan.postsLimit} ${t('publicaciones')} `}</strong>
              {t('por revista')}
            </li>
          </ul>
          <Fab
            className={classes.landingPlanButton}
            variant="extended"
            size="large"
            onClick={onClick}
          >
            {t('Seleccionar')}
          </Fab>
        </CardContent>
      </Card>
    </Grid>
  );
};

const GroupPlanForm = ({ data }) => {
  const { t, getSteps, handlePlanSubmit, goPreviousStep, groupType } = data;
  const { plans } = groupTypes[groupType];
  const [annual, setAnnual] = useState(false);
  const classes = useStyles()();
  return (
    <React.Fragment>
      <p className={classes.landingInputFormTitle}>{t('Por favor seleccione su plan de suscripción')}</p>
      <p className={classes.landingInputFormSubtitle}>{t('Obten 2 meses gratis por pago anual')}</p>
      {groupType === 'family'
      && (
      <div style={{ marginLeft: '38%', marginBottom: '15px' }}>
        <Button
          variant={!annual ? 'contained' : 'outlined'}
          color="primary"
          className={classes.button}
          onClick={() => setAnnual(false)}
          style={{ marginLeft: '-5px' }}
        >
          Mensual
        </Button>
        <Button
          style={{ marginLeft: '5px' }}
          variant={annual ? 'contained' : 'outlined'}
          color="primary"
          className={classes.button}
          onClick={() => setAnnual(true)}
        >
          Anual
        </Button>
      </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={6}>
            {plans.map((plan) => {
              const planType = planTypes[plan];
              let result;
              if (planType) {
                if ((planType.discount === undefined && !annual) || planType.discount === annual) {
                  result = (
                    <PlanCard
                      key={plan}
                      plan={planType}
                      onClick={() => { handlePlanSubmit(plan); }}
                      t={t}
                    />
                  );
                }
              }
              return result;
            })}
          </Grid>
        </Grid>
      </Grid>

      <Button
        className={classes.landingBackButton}
        color="inherit"
        onClick={goPreviousStep}
      >
        <ArrowBackIos />
        {t('Atras')}
      </Button>
      {getSteps()}
    </React.Fragment>
  );
};


export default connect(null, null)(withTranslation()(withMobileDialog()(GroupPlanForm)));
