import React from 'react';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import ProfilePic from './ProfilePic';
import useStyles from '../../theme/Styles';

const ProfileHeader = ({ user, posts, t }) => {
  const classes = useStyles()();
  return (
    <div>
      <Grid container className={classes.content} spacing={2}>
        <Grid item style={{ paddingTop: 32, paddingBottom: 16 }}>
          <div style={{ display: 'inline-block' }}>
            <ProfilePic user={user} size={80} />
          </div>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <span style={{ fontSize: 28, marginLeft: 10 }}>{user.displayName}</span>
            <div style={{ fontSize: 18, marginLeft: 10 }}>{`${posts.length} ${t('Publicaciones')}`}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(ProfileHeader);
