import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { isNil } from 'lodash';
import { Images } from '../theme';

const AppLoader = ({ loggedIn, defaultGroup, redirectUrl }) => (
  <div style={{ textAlign: 'center', marginTop: 200 }}>
    {loggedIn === false && <Redirect to="/auth/login" />}
    {loggedIn
      && !isNil(defaultGroup)
      && (redirectUrl
        ? <Redirect to={`/app/g/${defaultGroup.code}/${redirectUrl}`} />
        : <Redirect to={`/app/g/${defaultGroup.code}`} />)}
    <img src={Images.logo} alt="Loading &Co." />
  </div>
);

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  redirectUrl: state.user.redirect,
  defaultGroup: state.groups.userGroups[0],
});

export default connect(mapStateToProps)(AppLoader);
