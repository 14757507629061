import React, { useState } from 'react';
import { Card, CardHeader, CardMedia, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import PostModal from './PostModal';
import PostLikeButton from './like/PostLikeButton';
import ProfilePic from '../users/ProfilePic';
import DateFormatted from '../common/DateFormatted';
import useStyles from '../../theme/Styles';

export default ({ post, groupCode, magazineId }) => {
  const classes = useStyles()();
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const openPostModal = () => setIsPostModalOpen(true);
  const onClosePostModal = () => setIsPostModalOpen(false);

  return (
    <div>
      <Card>
        <CardMedia
          className={classes.postCardImage}
          image={post.photoURL}
          title="Paella dish"
          onClick={openPostModal}
        >
          {!isEmpty(post.body) && (
            <Typography className={classes.postCardBody}>
              {post.body}
            </Typography>
          )}
        </CardMedia>
        <CardHeader
          avatar={<ProfilePic user={post.user} />}
          action={(
            <PostLikeButton post={post} groupCode={groupCode} magazineId={magazineId} />
          )}
          title={post.user.displayName.substr(0, 28)}
          titleTypographyProps={{ noWrap: true }}
          subheader={<DateFormatted date={post.createdAt} />}
          subheaderTypographyProps={{ noWrap: true }}
        />
      </Card>

      <PostModal
        post={post}
        groupCode={groupCode}
        magazineId={magazineId}
        open={isPostModalOpen}
        onClose={onClosePostModal}
      />
    </div>
  );
};
