import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  root: {
    flexGrow: 100,
  },
  marginTop: 300,
};

function LinearIndeterminate() {
  return (
    <div style={styles}>
      <LinearProgress
        variant="indeterminate"
      />
    </div>
  );
}

LinearIndeterminate.propTypes = {

};

export default withStyles(styles)(LinearIndeterminate);
