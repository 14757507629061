import React from 'react';
import firebase from 'firebase';
import { withTranslation } from 'react-i18next';
import { DeleteForever } from '@material-ui/icons';
import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';

import useStyles from '../../../theme/Styles';

const FrontCoverTable = ({ t, frontCoverImages }) => {
  const classes = useStyles()();

  const deletefrontCover = (key) => {
    firebase.database().ref(`/design/frontCoverImages/${key}`).remove();
  };

  return (
    <div>
      <p className={classes.configFormTitle}>{t('Imagenes')}</p>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Vista previa')}</TableCell>
            <TableCell />
            <TableCell />
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(frontCoverImages || {}).map(key => (
            <TableRow key={frontCoverImages[key].curiosityTextSP}>
              <TableCell align="left">
                <img src={frontCoverImages[key].photoURL} width="100px" height="100px" />
              </TableCell>
              <TableCell align="left">
                <a target="_blank" rel="noopener noreferrer" href={frontCoverImages[key].photoURL}>
                  {t('Ver en tamaño completo')}
                </a>
              </TableCell>
              <TableCell align="left">
                <Button
                  onClick={() => { deletefrontCover(key); }}
                >
                  <DeleteForever style={{ color: 'red' }} />
                </Button>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withTranslation()(FrontCoverTable);
