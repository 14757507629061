import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Update } from '@material-ui/icons';
import useStyles from '../../../theme/Styles';
import { generatePdfMagazine } from '../../../services/backend';

const GeneratePdfButton = ({ magazineKey, t }) => {
  const classes = useStyles()();
  const [isLoading, setIsLoading] = useState(false);
  const [typeResponse, setTypeResponse] = useState(null);

  const generatePdf = () => {
    setIsLoading(true);
    setTypeResponse(null);
    const success = () => {
      setIsLoading(false);
      setTypeResponse(true);
    };
    const error = () => {
      setIsLoading(false);
      setTypeResponse(false);
    };
    generatePdfMagazine(magazineKey, success, error);
  };

  return (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      className={classes.marginHorizontal}
      onClick={() => generatePdf()}
    >
      <Update className={clsx(classes.iconLeft, classes.iconSmall)} />
      {t('Generar')}
      {isLoading && <CircularProgress style={{ color: '#fff' }} className={classes.iconRight} size={20} />}
      {typeResponse && <DoneIcon className={clsx(classes.iconRight, classes.iconSmall)} />}
      {typeResponse === false && <CloseIcon className={clsx(classes.iconRight, classes.iconSmall)} />}
    </Button>
  );
};

export default withTranslation()(GeneratePdfButton);
