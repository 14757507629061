import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Divider, Button, CardActions } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import clsx from 'clsx';
import { isNil } from 'lodash';
import GeneratePdfButton from './GeneratePdfButton';
import useStyles from '../../../theme/Styles';

const MagazineCard = ({ t, magazine, groupName }) => {
  const classes = useStyles()();
  const start = new Date(magazine.startDate);
  const end = new Date(magazine.endDate);
  const { active } = magazine;
  return (
    <Card className={classes.adminCard}>
      <CardContent>
        <div style={{ width: '100%' }}>
          <Typography
            variant="h6"
            className={classes.titleColor}
            style={{ textAlign: 'left', float: 'left' }}
          >
            {groupName ? `${groupName} (${magazine.groupCode})` : magazine.groupCode}
          </Typography>
          { active
            ? (
              <Button
                disabled
                className={classes.adminCardActiveBadge}
                variant="contained"
              >
                {t('Activo')}
              </Button>
            )
            : <Button disabled className={classes.adminCardPastBadge} variant="contained">{t('Pasado')}</Button>
          }
        </div>
        <Divider className={classes.adminCardDivider} />
        <table>
          <tbody>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Edition')}
          :
              </th>
              <td>
                {magazine.edition}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Start date')}
          :
              </th>
              <td>
                {start.toDateString()}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('End Date')}
          :
              </th>
              <td>
                {end.toDateString()}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Limit')}
          :
              </th>
              <td>
                {magazine.limit}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Post count')}
            :
              </th>
              <td>
                {magazine.posts.length}
              </td>
            </tr>
          </tbody>
        </table>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            className={classes.marginHorizontal}
            target="_blank"
            href={magazine.pdfURL}
            disabled={isNil(magazine.pdfURL)}
            style={{ float: 'right' }}
          >
            <CloudDownload className={clsx(classes.iconLeft, classes.iconSmall)} />
            {t('PDF')}
          </Button>
          <GeneratePdfButton magazineKey={magazine.key} />
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default withTranslation()(MagazineCard);
