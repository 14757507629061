import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_SIGNUP_START,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILURE,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_RECOVERY_PASSWORD_START,
  USER_RECOVERY_PASSWORD_SUCCESS,
  USER_RECOVERY_PASSWORD_FAILURE,
  CHANGE_LANGUAGE,
  USER_UPDATE_INFO_RESET,
  USER_UPDATE_INFO_START,
  USER_UPDATE_INFO_SUCCEESS,
  USER_UPDATE_INFO_FAILURE,
  USER_DELETE_START,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_JOIN_NEW_GROUP_START,
  USER_JOIN_NEW_GROUP_SUCCESS,
  USER_JOIN_NEW_GROUP_FAILURE,
  USER_REDIRECT_AFTER_LOGIN,
} from '../actions/types';

const INITIAL_STATE = {
  isLoginLoading: false,
  errorCode: '',
  errorMessage: '',
  successMessage: '',
  loggedIn: null,
  firstLogin: false,
  redirect: null,
  info: {
    uid: '',
    email: '',
    displayName: '',
    photoURL: '',
  },
  config: {
    lang: 'es',
  },
  updateInfo: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
  deleteInfo: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
  joinNewGroup: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case USER_LOGIN_START:
      return { ...state, isLoginLoading: true, errorMessage: '' };
    case USER_LOGIN_SUCCESS:
      return { ...state, isLoginLoading: false, errorMessage: '' };
    case USER_LOGIN_FAILURE:
      return { ...state, isLoginLoading: false, errorCode: payload.code, errorMessage: payload.message };
    case USER_SIGNUP_START:
      return { ...state, isLoginLoading: true, errorMessage: '' };
    case USER_SIGNUP_SUCCESS:
      return { ...state, isLoginLoading: false, errorMessage: '', firstLogin: true };
    case USER_SIGNUP_FAILURE:
      return { ...state, isLoginLoading: false, errorCode: payload.code, errorMessage: payload.message };
    case USER_LOGGED_IN: {
      const { user } = payload;
      const lang = payload.lang ? payload.lang : INITIAL_STATE.config.lang;
      return { ...state, loggedIn: true, info: { ...user }, config: { ...state.config, lang } };
    }
    case USER_LOGGED_OUT:
      return { ...state, loggedIn: false, info: {} };
    case USER_RECOVERY_PASSWORD_START:
      return { ...state, isLoginLoading: true, errorMessage: '' };
    case USER_RECOVERY_PASSWORD_SUCCESS:
      return { ...state, isLoginLoading: false, successMessage: payload.message };
    case USER_RECOVERY_PASSWORD_FAILURE:
      return { ...state, isLoginLoading: false, errorCode: payload.code, errorMessage: payload.message };
    case USER_UPDATE_INFO_RESET:
      return { ...state,
        updateInfo: { ...state.updateInfo, isLoading: false, errorMessage: '', succeeded: false } };
    case USER_UPDATE_INFO_START:
      return { ...state,
        updateInfo: { ...state.updateInfo, isLoading: true, errorMessage: '', succeeded: false } };
    case USER_UPDATE_INFO_SUCCEESS:
      return { ...state, firstLogin: false, updateInfo: { ...state.updateInfo, isLoading: false, succeeded: true } };
    case USER_UPDATE_INFO_FAILURE:
      return { ...state,
        firstLogin: false,
        updateInfo: { ...state.updateInfo, isLoading: false, errorMessage: payload.message, succeeded: false } };
    case USER_DELETE_START:
      return { ...state, deleteInfo: { ...state.deleteInfo, isLoading: true, errorMessage: '', succeeded: false } };
    case USER_DELETE_SUCCESS:
      return { ...state, deleteInfo: { ...state.deleteInfo, isLoading: false, succeeded: true } };
    case USER_DELETE_FAILURE:
      return { ...state, deleteInfo: { ...state.deleteInfo, isLoading: false, errorMessage: 'Error' } };
    case USER_JOIN_NEW_GROUP_START:
      return { ...state, joinNewGroup: { isLoading: true, errorMessage: '', succeeded: false } };
    case USER_JOIN_NEW_GROUP_SUCCESS:
      return { ...state, joinNewGroup: { isLoading: false, errorMessage: '', succeeded: true } };
    case USER_JOIN_NEW_GROUP_FAILURE:
      return { ...state, joinNewGroup: { isLoading: false, errorMessage: payload.message, succeeded: false } };
    case CHANGE_LANGUAGE:
      return { ...state, config: { ...state.config } };
    case USER_REDIRECT_AFTER_LOGIN:
      return { ...state, redirect: payload.place };
    default:
      return state;
  }
};
