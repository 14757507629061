import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button, withMobileDialog, CircularProgress, FormControl, TextField } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';
import { initCreateGroup } from '../../../actions/GroupActions';
import { getCoupon } from '../../../services/stripe';

const GroupPaymentForm = ({ data, stripe, t, isLoading, errorMessage,
  initCreateGroupAction, succeeded }) => {
  /* eslint-disable */
    useEffect(() => {
      initCreateGroupAction();
    }, []);
  /* eslint-enable */

  const { getSteps, goPreviousStep, goNextStep } = data;
  const classes = useStyles()();
  const [cardError, setCardError] = useState('');
  const [loadingCard, setLoadingCard] = useState(false);
  const [coupon, setCoupon] = useState(null);

  const startPayment = () => {
    stripe.createSource({
      type: 'card',
    }).then((response) => {
      if (!isEmpty(response.error)) { setCardError(response.error.message); } else {
        goNextStep(response.source.id, coupon);
      }
      setLoadingCard(false);
    });
  };

  const handleButton = (btn) => {
    setLoadingCard(true);
    setCardError('');
    btn.preventDefault();

    if (coupon) {
      getCoupon(coupon, startPayment, () => {
        setCardError('El cupón es invalido.');
        setLoadingCard(false);
      });
    } else startPayment();
  };

  const spinning = isLoading || loadingCard || succeeded;
  const errorShowed = errorMessage || cardError;

  const handleChange = () => {
    setCardError('');
  };

  return (
    <React.Fragment>
      <p className={classes.landingInputFormTitle}>{t('Efectuar pago')}</p>
      <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
        <div className={classes.cardForm}>
          <CardElement
            hidePostalCode
            onChange={handleChange}
          />
        </div>
        <TextField
          id="couponfield"
          style={{ marginTop: '30px' }}
          label={t('¿Tienes un cupón?')}
          className={classes.textField}
          value={coupon}
          onChange={event => setCoupon(event.target.value)}
          margin="normal"
          variant="outlined"
        />
      </FormControl>
      <div className={classes.landingUserActions}>
        <Button
          className={classes.landingBackButton}
          color="inherit"
          onClick={goPreviousStep}
        >
          <ArrowBackIos />
          {t('Atras')}
        </Button>
        <Button
          className={classes.landingUserButton}
          color="inherit"
          variant="outlined"
          onClick={handleButton}
          disabled={spinning}
        >
          {spinning && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
          {t('Crear Grupo')}
        </Button>
        {!isEmpty(errorShowed)
          && <div className={classes.landingInputFormError}>{errorShowed}</div>
        }
      </div>
      {getSteps()}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isLoading: state.groups.newGroup.isLoading,
  errorMessage: state.groups.newGroup.errorMessage,
  succeeded: state.groups.newGroup.succeeded,
});

const mapDispatchToProps = {
  initCreateGroupAction: initCreateGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withMobileDialog()(injectStripe(GroupPaymentForm))),
);
