import { stripeSecretKey, stripeURL, planKeys, magazinePrice } from '../config/StripeConfig';

const toUrlEncoded = (obj) => {
  const str = [];
  Object.keys(obj).forEach((p) => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  });
  return str.join('&');
};


export const getCustomers = (success, error) => {
  fetch(`${stripeURL}/customers`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${stripeSecretKey}`,
    },
  }).then((response) => {
    if (response.ok) response.json().then(data => success(data));
    else response.json().then(err => error(err.error.message));
  }).catch(() => {
    error();
  });
};

export const getCardInfo = (customerId, success, error) => {
  fetch(`${stripeURL}/customers/${customerId}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${stripeSecretKey}`,
    },
  }).then((response) => {
    if (response.ok) response.json().then(data => success(data.sources.data[0].card));
    else response.json().then(err => error(err.error.message));
  }).catch(() => {
    error();
  });
};


export const createCustomer = (customerInfo, success, error) => {
  fetch(`${stripeURL}/customers`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${stripeSecretKey}`,
    },
    body: toUrlEncoded(customerInfo),
  }).then((response) => {
    if (response.ok) response.json().then(data => success(data));
    // else response.json().then(err => error(err.error.message));
    else response.json().then((err) => { error(err.error.message); console.log(err); });
  }).catch(() => {
    error('Unable to fetch');
  });
};

export const updateCustomerCard = (customerId, cardToken, success, error) => {
  fetch(`${stripeURL}/customers/${customerId}`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${stripeSecretKey}`,
    },
    body: toUrlEncoded({
      source: cardToken,
    }),
  }).then((response) => {
    if (response.ok) response.json().then(data => success(data));
    else response.json().then(err => error(err.error.message));
  }).catch(() => {
    error('Unable to fetch');
  });
};


export const createSubscription = (coupon, planType,
  customerId, success, error) => {
  let body;
  if (coupon) {
    body = toUrlEncoded({
      customer: customerId,
      coupon,
      'items[0][plan]': planKeys[planType],
    });
  } else {
    body = toUrlEncoded({
      customer: customerId,
      'items[0][plan]': planKeys[planType],
    });
  }

  fetch(`${stripeURL}/subscriptions`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${stripeSecretKey}`,
    },
    body,
  }).then((response) => {
    if (response.ok) response.json().then(data => success(data));
    else response.json().then(err => error(err.error.message));
  }).catch(() => {
    error('Unable to fetch');
  });
};

export const getCoupon = (coupon, success, error) => {
  fetch(`${stripeURL}/coupons/${coupon}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${stripeSecretKey}`,
    },
  }).then((response) => {
    if (response.ok) response.json().then(data => success(data));
    else response.json().then(err => error(err.error.message));
  }).catch(() => {
    error('Unable to fetch');
  });
};

export const chargeMagazineDownload = (customer, success, error) => {
  fetch(`${stripeURL}/charges`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${stripeSecretKey}`,
    },
    body: toUrlEncoded({
      amount: magazinePrice,
      customer: customer.stripeId,
      currency: 'usd',
      description: `Charge for ${customer.email}. Magazine purchase`,
    }),
  }).then((response) => {
    if (response.ok) response.json().then(data => success(data));
    else response.json().then(err => error(err.error.message));
  }).catch(() => {
    error('Unable to fetch');
  });
};
