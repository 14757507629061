import React, { useState } from 'react';
import { Cancel } from '@material-ui/icons';
import { isNil } from 'lodash';

import DropZone from './DropZone';
import CropImage from './crop/CropImage';
import CropCircleImage from './crop/CropCircleImage';
import useStyles from '../../theme/Styles';

const NewPhoto = ({ onPhotoSelected, circle, optionsAspect }) => {
  const classes = useStyles()();
  const [filePath, setFilePath] = useState('');
  const [croppedPhoto, setCroppedPhoto] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOnFileSelected = (file) => {
    setFilePath(URL.createObjectURL(file));
    setIsOpenModal(true);
  };
  const onCloseModal = () => setIsOpenModal(false);
  const onImageCropped = (image) => {
    setCroppedPhoto(image);
    onPhotoSelected(image);
    setIsOpenModal(false);
  };
  return (
    <div className={classes.newPostPhotoContainer}>
      {isNil(croppedPhoto) ? (
        <DropZone onFileSelected={handleOnFileSelected} />
      ) : (
        <div className={
          circle
            ? classes.newPostImgPreviewContainerCircle
            : classes.newPostImgPreviewContainer
        }
        >
          {circle
            ? <img src={URL.createObjectURL(croppedPhoto)} alt="Preview" className={classes.avatarChange} />
            : <img src={URL.createObjectURL(croppedPhoto)} alt="Preview" className={classes.newPostImgPreview} />
          }

          <Cancel
            className={classes.newPostRemoveImgBtn}
            onClick={() => { setCroppedPhoto(null); onPhotoSelected(null); }}
          />
        </div>
      )}
      {circle
        ? (
          <CropCircleImage
            filePath={filePath}
            open={isOpenModal}
            onCloseModal={onCloseModal}
            onImageCropped={onImageCropped}
          />
        )
        : (
          <CropImage
            filePath={filePath}
            open={isOpenModal}
            onCloseModal={onCloseModal}
            onImageCropped={onImageCropped}
            optionsaspect={optionsAspect}
          />
        )
      }
    </div>
  );
};

export default NewPhoto;
