import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, withMobileDialog, CircularProgress, Table, TableBody,
  TableCell, TableRow, TextField } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';
import { initCreateGroup } from '../../../actions/GroupActions';
import { getCardInfo } from '../../../services/stripe';
import NewsLoader from '../../common/NewsLoader';

const GroupPaymentForm = ({ data, t, isLoading,
  errorMessage, succeeded, initCreateGroupAction }) => {
  /* eslint-disable */
    useEffect(() => {
      initCreateGroupAction();
      loadCard();
    }, []);
  /* eslint-enable */

  const { getSteps, handleSubmit, goPreviousStep, plan, user } = data;
  const [card, setCard] = useState(null);
  const classes = useStyles()();
  const [coupon, setCoupon] = useState(null);

  const spinning = isLoading || succeeded;

  const loadCard = () => {
    const success = (cardData) => {
      setCard(cardData);
    };
    const error = () => {
      setCard('error');
    };
    getCardInfo(user.stripeId, success, error);
  };

  return (
    <React.Fragment>
      <div>
        <p className={classes.landingInputFormTitle}>{t('Confirmacion del pago')}</p>
        { (card === null) && <NewsLoader /> }
        { (card && card === 'error')
          && <p>{t('Algo sucedio. Porfavor intentalo despues.')}</p>
        }
        { (card && card !== 'error')
        && (
          <>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><strong>{t('Plan')}</strong></TableCell>
                  <TableCell>{t(plan.name)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>{t('Precio')}</strong></TableCell>
                  <TableCell>{t(`${plan.price}$`)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>{t('Card')}</strong></TableCell>
                  <TableCell>{`${card.brand} XXXX-XXXX-XXXX-${card.last4}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TextField
              id="couponfield"
              style={{ marginTop: '30px' }}
              label={t('¿Tienes un cupón?')}
              className={classes.textField}
              value={coupon}
              onChange={event => setCoupon(event.target.value)}
              margin="normal"
              variant="outlined"
            />
          </>
        )
        }
      </div>
      <div className={classes.landingUserActions}>
        <Button
          className={classes.landingBackButton}
          color="inherit"
          onClick={goPreviousStep}
        >
          <ArrowBackIos />
          {t('Atras')}
        </Button>
        <Button
          className={classes.landingUserButton}
          color="inherit"
          variant="outlined"
          // Not neccesary to check the coupon as the subscription will fail and the customer already exists
          onClick={() => handleSubmit(null, coupon)}
          disabled={spinning || (card === null) || (card === 'error')}
        >
          {spinning && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
          {t('Crear Grupo')}
        </Button>
        {!isEmpty(errorMessage)
          && <div className={classes.landingInputFormError}>{errorMessage}</div>
        }
      </div>
      {getSteps()}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isLoading: state.groups.newGroup.isLoading,
  errorMessage: state.groups.newGroup.errorMessage,
  succeeded: state.groups.newGroup.succeeded,
});


const mapDispatchToProps = {
  initCreateGroupAction: initCreateGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withMobileDialog()(GroupPaymentForm)));
