import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import GroupIdForm from './GroupIdForm';
import SignUpForm from './SignUpForm';
import { loadGroupInfo } from '../../../actions';

const SignUp = ({ groupInfo, loggedIn, location, loadGroupInfoAction }) => {

  useEffect(() => {
    const groupCode = new URLSearchParams(location.search).get('groupCode');
    loadGroupInfoAction(groupCode);
  }, []);

  return isEmpty(groupInfo) || loggedIn ? <GroupIdForm loggedIn={loggedIn} /> : <SignUpForm groupInfo={groupInfo} />;
};

const mapStateToProps = state => ({
  groupInfo: state.groups.current.info,
});

const mapDispatchToProps = {
  loadGroupInfoAction: loadGroupInfo,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
