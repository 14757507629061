import firebase from 'firebase';

export default () => {
  firebase.initializeApp({
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,

    apiKey: 'AIzaSyCiiwQ0oELTIsCgCnPi-lE8waav7IbyEBM',
    authDomain: 'andco-ba988.firebaseapp.com',
    databaseURL: 'https://andco-ba988.firebaseio.com',
    projectId: 'andco-ba988',
    storageBucket: 'andco-ba988.appspot.com',
    messagingSenderId: '353166709217',
    appId: '1:353166709217:web:0e94ae88c4062b6811c2a0',
    measurementId: 'G-F721HX7ERL',
  });
};
