import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deletePost } from '../../../actions';
import EditPostModal from './EditPostModal';

const PostActionButton = ({ t, post, magazineId, deletePostAction, isDeleteLoading, isEditLoading }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <div>
      <IconButton aria-label="Settings" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => { setAnchorEl(null); setOpen(true); }}
          disabled={isEditLoading}
        >
          {t('Editar')}
        </MenuItem>
        <MenuItem
          onClick={() => { handleClose(); deletePostAction(magazineId, post.key); }}
          disabled={isDeleteLoading}
        >
          {t('Eliminar')}
        </MenuItem>
      </Menu>

      <EditPostModal
        open={open}
        post={post}
        onClose={onClose}
        magazineId={magazineId}
      />

    </div>
  );
};

const mapStateToProps = state => ({
  isDeleteLoading: state.groups.deletePost.isLoading,
  isEditLoading: state.groups.editPost.isLoading,
});

const mapDispatchToProps = {
  deletePostAction: deletePost,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PostActionButton));
