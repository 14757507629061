import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { LibraryBooks } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import useStyles from '../../theme/Styles';

const PostEmpty = ({ active, t }) => {
  const classes = useStyles()();
  return (
    <div>
      <Card className={classes.emptyPostContainerCard}>
        <CardContent>
          <LibraryBooks className={classes.emptyPostContainerImageIcon} />
          {active ? (
            <p>
              {t('Aún no hay ninguna publicación.')}
              <br />
              {t('Se el primero en publicar!')}
            </p>
          ) : (
            <p>
              {t('Esta no revista no contiene ninguna publicación')}
            </p>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default withTranslation()(PostEmpty);
