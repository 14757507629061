import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { Stepper, Step, StepLabel,
  Dialog, DialogContent, DialogTitle,
  IconButton, withMobileDialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Elements } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import useStyles from '../../../theme/Styles';
import { createGroup } from '../../../actions';
import planTypes from '../../common/planTypes';
import groupTypes from '../../common/groupTypes';

import GroupNameForm from './GroupNameForm';
import GroupPlanForm from './GroupPlanForm';
import GroupPaymentForm from './GroupPaymentForm';
import PaymentConfirmation from './PaymentConfirmation';

const NewBasicGroup = ({ t, isLoading, fullScreen, createGroupAction, user, onClose, history }) => {
  const classes = useStyles()();
  const [groupType, setNewGroupType] = React.useState('family'); // family, business, event
  const [groupName, setGroupName] = React.useState('');
  const [groupPlan, setGroupPlan] = React.useState(''); // basic, home, pro
  const [geriatricCode, setGeriatricCode] = React.useState('');
  const [eventDate, setEventDate] = React.useState(DateTime.local().plus({ months: 1 }).toSQLDate());
  const [currentStep, setCurrentStep] = React.useState(1);
  const canSendForm = (
    !isEmpty(groupType)
    && !isEmpty(groupName)
    && !isEmpty(groupPlan)
    && !isLoading
  );

  const allSteps = {
    1: (<Step key="step1"><StepLabel>{t('Información grupo')}</StepLabel></Step>),
    2: (<Step key="step2"><StepLabel>{t('Información Usuario')}</StepLabel></Step>),
    3: (<Step key="step3"><StepLabel>{t('Suscripción')}</StepLabel></Step>),
    4: (<Step key="step4"><StepLabel>{t('Pago')}</StepLabel></Step>),
  };

  const setGroupType = (newGroupType) => {
    const { plans } = groupTypes[newGroupType];
    if (plans.length === 1) setGroupPlan(plans[0]);
    setNewGroupType(newGroupType);
  };

  const getSteps = () => {
    const steps = groupTypes[groupType].steps.filter(item => item !== 2);
    return (
      <React.Fragment>
        <Stepper activeStep={currentStep - 1}>
          {steps.map(group => allSteps[group])}
        </Stepper>
      </React.Fragment>
    );
  };
  const handleSubmit = (cardToken = null, coupon = null) => {
    const newEventDate = groupType === 'event' ? eventDate : null;
    if (canSendForm) {
      createGroupAction(
        cardToken,
        coupon,
        groupType,
        groupName,
        groupPlan,
        planTypes[groupPlan].type,
        planTypes[groupPlan].postsLimit,
        planTypes[groupPlan].usersLimit,
        newEventDate,
        user,
        (code) => {
          history.push(`/app/g/${code}`);
          // Something is happening that is blocking the rendering.. Only url is changing
          // Reload is a temporal fix
          window.location.reload();
        },
      );
    }
  };

  const goPreviousStep = () => {
    const groupSteps = groupTypes[groupType].steps.filter(item => item !== 2);
    const stepLocation = groupSteps.indexOf(currentStep);
    const previousStep = groupSteps[stepLocation - 1];
    if (previousStep) setCurrentStep(previousStep);
  };

  const goNextStep = (cardToken = null, coupon = null) => {
    const groupSteps = groupTypes[groupType].steps.filter(item => item !== 2);
    const stepLocation = groupSteps.indexOf(currentStep);
    const nextStep = groupSteps[stepLocation + 1];
    if (nextStep) {
      setCurrentStep(nextStep);
    } else if (currentStep === groupSteps[groupSteps.length - 1]) {
      handleSubmit(cardToken, coupon);
    }
  };

  const handlePlanSubmit = (plan) => {
    setGroupPlan(plan);
    goNextStep();
  };

  const baseData = {
    t,
    isLoading,
    getSteps,
    goPreviousStep,
    goNextStep,
    isLastStep: currentStep === groupTypes[groupType].steps[groupTypes[groupType].steps.length - 1],
  };

  const groupNameData = {
    ...baseData,
    groupType,
    setGroupType,
    groupName,
    setGroupName,
    geriatricCode,
    setGeriatricCode,
    eventDate,
    setEventDate,
  };

  const groupPlanData = {
    ...baseData,
    handlePlanSubmit,
    groupType,
  };

  const groupPaymentData = {
    ...baseData,
    handleSubmit,
  };

  const paymentConfirmationData = {
    ...baseData,
    handleSubmit,
    plan: planTypes[groupPlan],
    user,
  };

  let toRender = '';
  switch (currentStep) {
    case 1:
      toRender = (<GroupNameForm data={groupNameData} />);
      break;
    case 3:
      toRender = (<GroupPlanForm data={groupPlanData} />);
      break;
    case 4: {
      if (user.stripeId) toRender = (<Elements><PaymentConfirmation data={paymentConfirmationData} /></Elements>);
      else toRender = (<Elements><GroupPaymentForm data={groupPaymentData} /></Elements>);
      break;
    }
    default:
      toRender = <GroupNameForm data={groupNameData} />;
      break;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {toRender}
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = {
  createGroupAction: createGroup,
};

const mapStateToProps = state => ({
  // TODO: this is a temporal fix until the create group task is complete
  isLoading: state.groups.newGroup.isLoading,
  user: state.user.info,
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withMobileDialog()(NewBasicGroup)),
));
