import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import PostNewsFeed from '../posts/PostNewsFeed';
import PostEmpty from '../posts/PostEmpty';
import GridViewTitleDivider from './GridViewTitleDivider';
import NewPostContainer from '../posts/new/NewPostContainer';
import NewPostUploading from '../posts/new/NewPostUploading';

const NewsfeedView = ({ magazines, insertedPost, users, currentUid }) => {
  const currentUser = users.find(x => x.uid === currentUid);
  let role = '';
  if (currentUser) role = currentUser.role || '';
  let uploadingPost = true;
  const error = insertedPost.errorMessage !== '';
  if (insertedPost.isLoading || error) {
    uploadingPost = (
      <Grid item md={7} xs={12} key={1}>
        <NewPostUploading post={insertedPost.uploadingPost} error={insertedPost.errorMessage} />
      </Grid>
    );
  }
  return (
    <Grid container>
      {magazines.map(magazine => (
        <div key={magazine.edition} style={{ width: '100%' }}>
          <GridViewTitleDivider magazine={magazine} />
          {(magazine.active && role !== 'viewer' && magazine.posts.length < magazine.limit) && (
            <NewPostContainer groupCode={magazine.groupCode} />
          )}
          <Grid container spacing={4} style={{ marginBottom: 25 }}>
            {magazine.active && uploadingPost}
            {magazine.posts.length > 0
              ? (
                <React.Fragment>
                  {magazine.posts.slice().reverse().map(post => (
                    <Grid item md={7} xs={12} key={post.key}>
                      <PostNewsFeed post={post} groupCode={magazine.groupCode} magazineId={magazine.key} />
                    </Grid>
                  ))}
                </React.Fragment>
              )
              : (
                <Grid item md={7} xs={12} key={1}>
                  <PostEmpty active={magazine.active} />
                </Grid>
              )
            }
          </Grid>
        </div>
      ))}
    </Grid>
  );
};

const mapStateToProps = state => ({
  insertedPost: state.groups.insertPost,
  users: state.groups.current.users,
  currentUid: state.user.info.uid,
});
export default connect(mapStateToProps, null)(NewsfeedView);
