import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, FormControl, TextField, CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { updateGroup, resetUpdateGroupState } from '../../../actions';
import useStyles from '../../../theme/Styles';

const ConfigMagazine = ({ t, groupInfo, isLoading, succeeded, errorMessage, updateGroupAction,
  resetUpdateGroupStateAction }) => {
  const classes = useStyles()();
  const [magazineText, setMagazineText] = useState(groupInfo.magazineText || '');
  const [magazineTitle, setMagazineTitle] = useState(groupInfo.magazineTitle || groupInfo.name);
  const [showMessage, setShowMessage] = useState(true);

  const handleSubmit = () => {
    setShowMessage(true);
    updateGroupAction({ ...groupInfo,
      magazineText,
      magazineTitle,
    }, null);
  };

  const canSendForm = (
    !isEmpty(magazineText) && !isEmpty(magazineTitle)
  );

  if (succeeded && showMessage) {
    setTimeout(() => resetUpdateGroupStateAction(),
      4000);
  }

  return (
    <div className={classes.configCardContainer}>
      <p className={classes.configFormTitle}>
        {t('Magazine')}
      </p>
      <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
        <TextField
          id="name_title_input"
          label={`${t('Titulo de revista')} *`}
          value={magazineTitle}
          onChange={(e) => { setMagazineTitle(e.target.value); }}
          margin="normal"
          className={classes.landingInputField}
        />
      </FormControl>

      <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
        <TextField
          id="name_phrase_input"
          label={`${t('Frase de revista')} *`}
          value={magazineText}
          onChange={(e) => { setMagazineText(e.target.value); }}
          margin="normal"
          className={classes.landingInputField}
        />
      </FormControl>
      <div className={classes.landingUserActions}>
        <Button
          className={classes.configInputButton}
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!canSendForm}
        >
          { isLoading && <CircularProgress size={20} className={classes.configButtonProgress} />}
          {t('Save Changes')}
        </Button>
      </div>
      {!isEmpty(errorMessage)
        && showMessage
        && (
        <div className={classes.configInputFormError}>
          {errorMessage}
        </div>
        )}
      {succeeded
          && showMessage
          && (
          <div className={classes.configInputFormSuccess}>
            {t('Data updated successfully')}
          </div>
          )}
    </div>
  );
};

const mapStateToProps = state => ({
  groupInfo: state.groups.current.info,
  isLoading: state.groups.newGroup.isLoading,
  errorMessage: state.groups.newGroup.errorMessage,
  succeeded: state.groups.newGroup.succeeded,
});

const mapDispatchToProps = {
  updateGroupAction: updateGroup,
  resetUpdateGroupStateAction: resetUpdateGroupState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ConfigMagazine));
