import React, { useState } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button,
  withMobileDialog,
  FormControl,
  TextField,
  CircularProgress } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';

const GroupUserForm = ({ data }) => {
  const { t, isLoading, goPreviousStep, goNextStep, getSteps,
    name, setName,
    lastname, setLastName,
    email, setEmail,
    password, setPassword, isLastStep } = data;
  const classes = useStyles()();
  const [loading, setLoading] = useState(false);
  const [willContinue, setWillContinue] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  if (willContinue) goNextStep();

  const checkIfEmailIsUsed = (emailToCheck) => {
    setLoading(true);
    firebase.auth().fetchSignInMethodsForEmail(emailToCheck).then((result) => {
      if (result.length === 0) { setWillContinue(true); } else { setErrorMessage('The email is already taken'); }
      setLoading(false);
    }).catch(() => {
      setErrorMessage('The email is already taken');
      setLoading(false);
    });
    return false;
  };

  const canSendForm = (
    !isEmpty(name)
      && !isEmpty(lastname)
      && !isEmpty(email)
      && !isEmpty(password)
      && !isLoading
  );

  const validateEmail = (emailToCheck) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(emailToCheck);
  };

  const handleSubmit = () => {
    if (canSendForm) {
      // Need to validate that the account doesn't exist
      if (password.length < 6) setErrorMessage('Password should be at least 6 characters');
      else if (!validateEmail(email)) setErrorMessage('The email address is badly formatted.');
      else checkIfEmailIsUsed(email);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && canSendForm) {
      handleSubmit();
    }
  };


  return (
    <React.Fragment>
      <p className={classes.landingInputFormSubtitle}>{t('Datos del administrador')}</p>
      <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
        <TextField
          id="nameInput"
          label={t('Nombre')}
          value={name}
          onChange={(e) => { setErrorMessage(null); setName(e.target.value); }}
          margin="normal"
          onKeyPress={handleKeyPress}
          className={classes.landingInputField}
        />
        <TextField
          id="lastnameInput"
          label={t('Apellido')}
          value={lastname}
          onChange={(e) => { setErrorMessage(null); setLastName(e.target.value); }}
          margin="normal"
          onKeyPress={handleKeyPress}
          className={classes.landingInputField}
        />
        <TextField
          id="emailInput"
          label={t('Email')}
          value={email}
          type="email"
          onChange={(e) => { setErrorMessage(null); setEmail(e.target.value); }}
          margin="normal"
          onKeyPress={handleKeyPress}
          className={classes.landingInputField}
        />
        <TextField
          id="passwordInput"
          label={t('Contraseña')}
          value={password}
          type="password"
          onChange={(e) => { setErrorMessage(null); setPassword(e.target.value); }}
          margin="normal"
          onKeyPress={handleKeyPress}
          className={classes.landingInputField}
        />
      </FormControl>
      <div className={classes.landingUserActions}>
        <Button
          className={classes.landingBackButton}
          color="inherit"
          onClick={goPreviousStep}
        >
          <ArrowBackIos />
          {t('Atras')}
        </Button>
        <Button
          className={classes.landingUserButton}
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!canSendForm || loading}
        >
          {loading && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
          {isLastStep ? t('Crear Grupo') : t('Continuar')}
        </Button>
        {!isEmpty(errorMessage) && <div className={classes.landingInputFormError}>{t(errorMessage)}</div>}
      </div>
      {getSteps()}
    </React.Fragment>
  );
};


export default connect(null, null)(withTranslation()(withMobileDialog()(GroupUserForm)));
