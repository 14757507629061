import { makeStyles } from '@material-ui/styles';
import Images from './Images';
import Colors from './Colors';

export default () => makeStyles(theme => ({
  content: {
    width: 'auto',
    marginLeft: theme.spacing(1) * 3,
    marginRight: theme.spacing(1) * 3,
    [theme.breakpoints.up(960 + theme.spacing(1) * 6)]: {
      width: 960,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: { padding: 0 },
  },
  grow: { flexGrow: 1 },
  margin: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  marginVertical: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginHorizontal: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  bigMarginTop: {
    marginTop: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  iconLeft: {
    marginRight: theme.spacing(1),
    verticalAlign: 'text-bottom',
  },
  iconRight: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'text-bottom',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  iconCenter: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    verticalAlign: 'text-bottom',
  },
  iconSmall: { fontSize: 20 },
  iconLarge: { fontSize: 120 },
  inlineBlock: { display: 'inline-block' },
  textRight: { textAlign: 'right' },
  blueText: { color: Colors.lightblue },
  // Header
  header: { flexGrow: 1 },
  headerLogo: {
    width: 40,
  },
  headerDisplayName: {
    display: 'inline-block',
    color: '#fff',
    marginLeft: 8,
  },
  // Drawer
  drawerColor: {
    background: '#009695',
    color: '#fff',
  },
  iconColor: {
    color: '#fff',
  },
  nestedMenu: {
    paddingLeft: theme.spacing(4),
  },
  // Navbar Dropdown
  navbarDropdown: { zIndex: 10, marginTop: theme.spacing(2) },

  // Group
  groupHeaderContainer: {
    backgroundColor: '#fff',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  groupHeaderPhoto: {
    width: '55%',
    [theme.breakpoints.down('sm')]: { width: '100%' },
  },
  groupHeaderActions: {
    float: 'right',
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: { marginTop: 0 },
  },
  groupHeaderPhotoEdit: {
    width: '200px',
  },
  groupHeaderInfo: {
    display: 'inline-block',
    marginRight: theme.spacing(4),
    color: theme.palette.grey[800],
    [theme.breakpoints.down('sm')]: { marginRight: theme.spacing(1) },
  },
  // Card
  cardForm: {
    padding: '18px',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
  },
  // Config
  configFormTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    color: theme.palette.grey[700],
  },
  configTabsContainer: {
    height: '57vh',
  },
  configInputButton: {
    marginTop: theme.spacing(6),
    backgroundColor: Colors.lightblue,
    color: 'white',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderRadius: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: Colors.hoveredlightblue,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    '&:disabled': {
      color: theme.palette.grey[300],
      backgroundColor: Colors.disabledlightblue,
      border: 'none',
    },
  },
  configButtonProgress: {
    color: 'white',
    marginRight: theme.spacing(1),
  },
  configPlanChange: {
    backgroundColor: theme.palette.grey[300],
    marginTop: theme.spacing(3),
  },
  membersCongifContainer: {
    overflowY: 'auto',
    maxHeight: '40vh',
  },
  verticalTabsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  verticalTabsFlex: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      overflowX: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  verticalTabsTabIndicator: {
    display: 'none',
  },
  verticalTabsTabSelected: {
    borderBottom: `1px solid ${Colors.lightblue}`,
  },
  verticalTabs: {
    backgroundColor: 'white',
    color: theme.palette.grey[700],
    [theme.breakpoints.up('md')]: {
      width: '200px',
      maxWidth: '200px',
      minWidth: '200px',
    },
  },
  configInfoContainer: {
    width: '100%',
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
    [theme.breakpoints.down('sm')]: {
      height: '48vh',
      overflowY: 'auto',
      padding: 0,
      margin: 0,
    },
  },
  configCardContainer: {
    maxHeight: '50vh',
    overflowY: 'auto',
  },
  configInputFormControl: {
    minWidth: '100%',
    flexDirection: 'row',
  },
  configInputField: {
    marginTop: theme.spacing(1),
    flexDirection: 'row',
  },
  configInputFormError: {
    marginTop: theme.spacing(2),
    color: '#d9534f',
  },
  configInputFormSuccess: {
    marginTop: theme.spacing(2),
    color: 'green',
  },
  avatarChange: {
    width: 110,
    height: 110,
    borderRadius: theme.spacing(10),
    margin: '0 auto',
  },
  // Magazines
  magazineTabsContainer: {
    backgroundColor: '#fff',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    width: '100%',
  },
  magazineTitleContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    marginLeft: -15,
    [theme.breakpoints.down('sm')]: { marginLeft: 0 },
  },
  magazineTitle: { display: 'inline-block' },
  magazineSubtitle: {
    color: theme.palette.grey[700],
    display: 'inline-block',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
  },

  // New post
  newPostContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  newPostContainerImageIcon: {
    fontSize: 50,
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(2),
  },
  newPostBodyContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.grey[200],
  },
  newPostAvatarContainer: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
  },
  newPostInputContainer: {
    flex: '1 1 auto',
  },
  newPostInput: {
    width: '100%',
    fontSize: 18,
    resize: 'none',
    border: 'none',
    background: 'none',
    outlineWidth: 0,
  },
  newPostFakeInput: {
    width: '100%',
    fontSize: 18,
    resize: 'none',
    border: 'none',
    borderRight: '2px dashed',
    borderColor: theme.palette.grey[400],
    background: 'none',
    outlineWidth: 0,
  },
  newPostPhotoContainer: {
    position: 'relative',
    textAlign: 'center',
  },
  newPostImgPreviewContainerCircle: {
    maxHeight: 130,
    display: 'block',
    position: 'relative',
    width: 130,
    cursor: 'pointer',
    marginBottom: 20,
    color: Colors.hoveredlightblue,
  },
  newPostImgPreviewContainer: {
    maxHeight: 120,
    display: 'inline-block',
    position: 'relative',
  },
  newPostImgPreview: {
    maxHeight: 120,
  },
  newPostRemoveImgBtn: {
    position: 'absolute',
    top: -16,
    right: -16,
    color: 'red',
    cursor: 'pointer',
  },
  newPostUploadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  newPostUploadingContainer: {
    position: 'relative',
  },
  newPostUploadingLoader: {
    marginTop: '46%',
    marginLeft: '46%',
  },
  newPostUploadingRetryError: {
    marginTop: '38%',
    marginRight: '15%',
    marginLeft: '15%',
    textAlign: 'center',
  },
  newPostUploadingInput: {
    fontSize: 30,
    resize: 'none',
    border: 'none',
    color: theme.palette.grey[400],
    outlineWidth: 0,
  },

  // empty Post
  emptyPostContainerCard: {
    backgroundColor: theme.palette.grey[200],
    fontSize: 20,
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
  emptyPostContainerImageIcon: {
    fontSize: 120,
    color: theme.palette.grey[400],
  },

  // dropzone
  dropzoneContainer: {
    borderWidth: 2,
    borderStyle: 'dashed',
    textAlign: 'center',
    borderRadius: 8,
    padding: theme.spacing(2),
    borderColor: theme.palette.grey[400],
    height: 120,
  },
  dropzoneActive: {
    borderColor: theme.palette.primary.main,
  },
  dropzoneContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  dropzoneIcon: {
    marginBottom: theme.spacing(1),
  },
  dropzoneText: {
    display: 'inline-block',
  },
  dropzoneButton: {
    marginLeft: theme.spacing(1),
  },

  // Crop
  cropContainer: {
    position: 'relative',
    height: 360,
  },
  cropActions: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    background: theme.palette.background.default,
  },
  cropOrientationBtns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  cropSliderContainer: {
    display: 'flex',
  },
  cropSlider: {
    alignSelf: 'center',
  },

  // PostCard
  postCardImage: {
    height: 0,
    paddingTop: '75%', // 4:3
    position: 'relative',
    cursor: 'pointer',
  },
  postCardBody: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    color: '#fff',
    background: 'linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 70%, rgba(0,0,0,0) 100%)',
    padding: '8px 16px',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  // PostModal
  postModalPhotoContainer: {
    backgroundColor: '#000',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  postModalPhoto: {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  // PostPublishInfo
  postAuthorContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  postAuthorProfilePic: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
  },
  postAuthorNameContainer: {
    flex: '1 1 auto',
  },
  postAuthorName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  // Like footer
  likeProfilePicSet: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    float: 'right',
  },
  likeProfilePic: {
    float: 'right',
    height: 30,
    width: 30,
    marginLeft: -10,
  },
  // Landing Page
  landingPageImage: {
    backgroundImage: `url(${Images.landingBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    maxHeight: '100vh',
    minHeight: '800px',
  },
  landingPageLogo: {
    marginTop: theme.spacing(35),
  },
  landingPageLogoText: {
    color: `${theme.palette.grey[300]}`,
    textAlign: 'center',
  },
  landingPageLogoImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
  },
  landingPageSideBar: {
    backgroundColor: `${theme.palette.grey[100]}`,
  },
  landingPageSideBarGrid: {
    backgroundColor: 'white',
    [theme.breakpoints.up('lg')]: {
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(12),
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
  },
  landingPageSideBarTabs: {
    backgroundColor: 'white',
    color: theme.palette.grey[700],
  },
  landingPageSideBarTabIndicator: {
    backgroundColor: Colors.lightblue,
  },
  landingInputFormControl: {
    minWidth: '100%',
  },
  landingInputFormControlDialog: {
    marginTop: theme.spacing(1),
    width: '50vh',
  },
  ladingInputFormContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  containerSteper: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  landingInputFormTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    color: theme.palette.grey[700],
  },
  landingInputFormSubtitle: {
    fontSize: 25,
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  landingInputField: {
    marginTop: theme.spacing(3),
  },
  landingInputFieldLabel: {
    fontSize: '0.8rem',
    marginTop: -10,
  },
  landingInputButton: {
    marginTop: theme.spacing(6),
    backgroundColor: Colors.lightblue,
    color: 'white',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderRadius: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: Colors.hoveredlightblue,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    '&:disabled': {
      color: theme.palette.grey[300],
      backgroundColor: Colors.disabledlightblue,
      border: 'none',
    },
  },
  landingPlanButton: {
    width: '25vh',
    backgroundColor: Colors.lightblue,
    color: 'white',
    '&:hover': {
      backgroundColor: Colors.hoveredlightblue,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
  },
  landingUserButton: {
    marginTop: theme.spacing(6),
    marginLeft: '30vh',
    backgroundColor: Colors.lightblue,
    color: 'white',
    '&:hover': {
      backgroundColor: Colors.hoveredlightblue,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    '&:disabled': {
      color: theme.palette.grey[300],
      backgroundColor: Colors.disabledlightblue,
      border: 'none',
    },
  },
  landingBackButton: {
    marginTop: theme.spacing(6),
    color: Colors.lightblue,
  },
  landingUserActions: { justifyContent: 'flex-start' },
  landingPlanCard: {
    width: '35vh',
    textAlign: 'center',
  },
  landingPriceList: {
    listStyleType: 'none',
    fontSize: 20,
    textAlign: 'center',
    paddingLeft: 0,
  },
  landingPlanTitle: {
    marginTop: 0,
    textAlign: 'center',
    color: theme.palette.grey[700],
    fontSize: '40px',
    fontWeight: 600,
  },
  landingPriceTag: {
    fontSize: '30px',
  },
  landingPriceMonth: {
    fontSize: '20px',
    fontWeight: 300,
    fontStyle: 'italic',
  },
  // Login Landing Page
  landingButtonProgress: {
    color: 'white',
    marginRight: theme.spacing(1),
  },
  landingInputFormError: {
    marginTop: theme.spacing(2),
    color: '#d9534f',
  },
  landingInputFormSuccess: {
    marginTop: theme.spacing(2),
    color: '#6cc32a',
  },
  textLoginRecovery: {
    float: 'right',
    textDecoration: 'none',
    fontSize: 16,
    marginTop: theme.spacing(1),
    color: Colors.lightblue,
  },
  // Sign Up Landing Page
  signUpGroupName: {
    fontSize: 20,
    color: theme.palette.grey[700],
  },
  signUpChangeGroup: {
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
    fontSize: 16,
    color: Colors.lightblue,
  },
  ladingInputFormModal: {
    width: '20%',
    display: 'block',
  },
  // Account is being created
  accountBeingCreatedContainer: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  accountBeingCreatedSpinner: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  accountBeingCreatedText: {
    fontSize: 20,
    color: theme.palette.grey[700],
    textAlign: 'center',
  },
  // Group created
  groupTypeRadioButton: {
    margin: theme.spacing(0),
  },
  // Dialog
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  // Members Modal
  membersModalUSerContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  membersModalDisplayName: {
    height: '50px',
    paddingTop: '7%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  // Invite members
  inviteMembersContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  inviteMembersInputContainer: {
    flex: '1 1 auto',
  },
  inviteMembersInput: {
    width: '100%',
    fontSize: 18,
    resize: 'none',
    border: 'none',
    background: 'none',
    outlineWidth: 0,
    transition: 'none !important',
    boxShadow: 'none !important',
  },
  inviteMembersInputUnderline: {
    display: 'none',
  },
  // administration
  verticalTabsAdmin: {
    color: theme.palette.grey[700],
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(2),
      width: '200px',
      maxWidth: '200px',
      minWidth: '200px',
    },
  },
  verticalTabsAdminContainer: {
    overflow: 'hidden',
    width: '100%',
    header: { flexGrow: 1 },
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  verticalTabsAdminFlex: {
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  },
  verticalTabsAdminTabIndicator: {
    display: 'none',
  },
  verticalTabsAdminTabSelected: {
    borderBottom: `1px solid ${Colors.lightblue}`,
  },
  adminInfoContainer: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  titleColor: {
    color: theme.palette.grey[700],
  },
  adminCardDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  adminCardActiveBadge: {
    backgroundColor: `${Colors.green} !important`,
    color: 'white !important',
    marginLeft: theme.spacing(3),
  },
  adminCardPastBadge: {
    backgroundColor: `${Colors.lightblue} !important`,
    color: 'white !important',
    marginLeft: theme.spacing(3),
  },
  // Buy magazine
  magazineBuySuccessIcon: {
    color: Colors.green,
    width: 100,
    height: 100,
    marginLeft: theme.spacing(16),
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
}));
