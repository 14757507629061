import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardContent } from '@material-ui/core';

const ErrorCard = ({ t }) => (
  <Card>
    <CardContent>
      {t('Something happened loading the info. Please try again later.')}
    </CardContent>
  </Card>
);

export default withTranslation()(ErrorCard);
