import firebase from 'firebase';
import { isNil } from 'lodash';
import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,

} from './types';

export const getUser = userId => (
  (dispatch) => {
    dispatch({ type: GET_USER_START });
    firebase.database().ref(`users/${userId}/info`).once('value')
      .then((snapshot) => {
        const user = snapshot.val();
        if (isNil(userId)) {
          const errorMessage = 'Id de usuario incorrecto. Por favor intente nuevamente.';
          dispatch({ type: GET_USER_FAILURE, payload: errorMessage });
        } else {
          user.uid = userId;
          dispatch({ type: GET_USER_SUCCESS, payload: { user } });
        }
      });
  }
);
