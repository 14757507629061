import React, { useState } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { ImportContacts, Group, GroupAdd, PersonAdd, Add } from '@material-ui/icons';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import NewGroup from './create_group/CreateGroupBasic';
import GroupProfilePic from './GroupProfilePic';
import MembersModal from './MembersModal';
import InviteMembersModals from './InviteMembersModals';
import JoinGroupModal from './JoinGroupModal';
import useStyles from '../../theme/Styles';
import groupTypes from '../common/groupTypes';

const MakePlural = (text, quantity) => (quantity === 1 ? text : `${text}s`);

const GroupHeader = ({ group, t, magazinesList }) => {
  const classes = useStyles()();
  const [open, setOpen] = useState(false);
  const [openJoinModal, setOpenJoinModal] = useState(false);
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [inviteMembersModalOpen, setInviteMembersModalOpen] = useState(false);
  const openInviteFromMembers = () => {
    setMembersModalOpen(false);
    setInviteMembersModalOpen(true);
  };
  const codeText = `${t('Código de grupo')}: ${group.info.groupCode}`;
  const typeGroupName = 'name' in group.info ? groupTypes[group.info.type].name : t('Grupo');
  return (
    <div className={classes.groupHeaderContainer}>
      <Grid container className={classes.content} spacing={2}>
        <Grid item sm={4} className={classNames(classes.textRight, classes.hideOnMobile)}>
          <GroupProfilePic groupInfo={group.info} />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant="h4">
            {group.info.name}
            <div className={classes.groupHeaderActions}>
              <Chip
                label={t(typeGroupName)}
                color="secondary"
                className={classNames(classes.marginHorizontal, classes.hideOnMobile)}
              />
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                onClick={() => setInviteMembersModalOpen(true)}
              >
                <PersonAdd className={clsx(classes.iconLeft, classes.iconSmall)} />
                <span className={classes.hideOnMobile}>{t('Enviar invitación')}</span>
              </Button>
              <div style={{ textAlign: 'right' }} className={classes.hideOnMobile}>
                <Chip label={codeText} variant="outlined" />
              </div>
            </div>
          </Typography>

          <div className={classes.marginBottom}>
            <Typography variant="body1" className={classes.groupHeaderInfo}>
              <ImportContacts className={clsx(classes.iconLeft, classes.iconSmall)} />
              {`${magazinesList.length} ${t(MakePlural('Revista', magazinesList.length))}`}
            </Typography>
            <Button className={classes.groupHeaderInfo} onClick={() => setMembersModalOpen(true)}>
              <Typography variant="body1">
                <Group className={clsx(classes.iconLeft, classes.iconSmall)} />
                {`${group.users.length} ${t(MakePlural('Miembro', group.users.length))}`}
              </Typography>
            </Button>
          </div>
          <div>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => setOpenJoinModal(true)}
              style={{ margin: 3 }}
            >
              <Add className={clsx(classes.iconLeft, classes.iconSmall)} />
              {t('Unirme a un grupo')}
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => setOpen(true)}
              style={{ margin: 3 }}
            >
              <GroupAdd className={clsx(classes.iconLeft, classes.iconSmall)} />
              {t('Nuevo Grupo')}
            </Button>
          </div>
          { open && <NewGroup onClose={() => setOpen(false)} />}
          { openJoinModal && <JoinGroupModal open={openJoinModal} onClose={() => setOpenJoinModal(false)} t={t} />}
        </Grid>
      </Grid>

      <MembersModal
        open={membersModalOpen}
        onClose={() => setMembersModalOpen(false)}
        users={group.users}
        openInviteMembersModal={openInviteFromMembers}
      />
      <InviteMembersModals
        open={inviteMembersModalOpen}
        onClose={() => setInviteMembersModalOpen(false)}
      />
    </div>
  );
};

export default withTranslation()(GroupHeader);
