import React from 'react';
import { DateTime } from 'luxon';
import { withTranslation } from 'react-i18next';

const DEFAULT = { month: 'short', day: '2-digit' };
const SHORT_WITH_YEAR = { year: '2-digit', month: 'short', day: '2-digit' };
const FULL_DATE = DateTime.DATETIME_MED;

/**
 * @format: short (default), shortWithYear, fullDate
 */
const DateFormatted = ({ date, format, i18n, className, style }) => {
  const getFormat = () => {
    switch (format) {
      case 'shortWithYear':
        return SHORT_WITH_YEAR;
      case 'fullDate':
        return FULL_DATE;
      default:
        return DEFAULT;
    }
  };
  const lang = i18n.language;
  const dateStr = DateTime.fromISO(date).setLocale(lang).toLocaleString(getFormat());

  return (
    <span
      className={className}
      style={{ textTransform: 'capitalize', ...style }}
    >
      {dateStr}
    </span>
  );
};

export default withTranslation()(DateFormatted);
