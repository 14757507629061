import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ProfilePic from '../../users/ProfilePic';
import { editPost } from '../../../actions';
import useStyles from '../../../theme/Styles';

const NewPostModal = ({ groupCode, magazineId, post, editPostAction, isEditLoading,
  onClose, match, open, t, ...other }) => {
  const classes = useStyles()();
  const [body, setBody] = useState(post.body);
  const handleOnClose = () => { onClose(); setBody(post.body); };
  const [submitEditInput, setSubmitIEditnput] = useState('');

  useEffect(() => {
    if (submitEditInput) submitEditInput.focus();
  });

  const handleOnSubmit = () => {
    editPostAction(magazineId, post.key, body);
    handleOnClose();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isEmpty(body)) {
      handleOnSubmit();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      aria-labelledby="new-post-dialog"
      onClose={handleOnClose}
      {...other}
    >
      <DialogTitle>
        {t('Editar Publicación')}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Card className={classes.newPostContainer}>
              <div className={classes.newPostAvatarContainer}>
                <ProfilePic />
              </div>
              <div className={classes.newPostInputContainer}>
                <textarea
                  className={classes.newPostInput}
                  placeholder={t('Escribe tu mensaje')}
                  rows={4}
                  ref={event => setSubmitIEditnput(event)}
                  onChange={e => setBody(e.target.value)}
                  onKeyPress={handleKeyPress}
                  disabled={isEditLoading}
                >
                  {body}
                </textarea>
              </div>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>
          {t('Cancelar')}
        </Button>
        <Button
          onClick={handleOnSubmit}
          variant="contained"
          color="primary"
          autoFocus
          disabled={isEmpty(body) || isEditLoading}
        >
          {t('Editar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  currentMagazine: state.magazines.activeMagazine,
  isEditLoading: state.groups.editPost.isLoading,
});

const mapDispatchToProps = {
  editPostAction: editPost,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewPostModal));
