import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import useStyles from '../../../theme/Styles';
import { loadPurchasesSended } from '../../../actions';
import NewsLoader from '../../common/NewsLoader';
import ErrorCard from '../ErrorCard';
import PurchaseCard from './PurchaseCard';

const PurchaseSent = ({ t, loadPurchasesSendedAction, succeeded, isLoading, error, purchases }) => {
  const classes = useStyles()();

  /* eslint-disable */
  useEffect(() => {
    if (!succeeded) loadPurchasesSendedAction();
  }, []);
  /* eslint-enable */

  let purchaseGrid = [];
  if (purchases) {
    purchaseGrid = purchases.map(purchase => (
      <Grid item xs={12} sm={6} lg={4} key={purchase.key}>
        <PurchaseCard purchase={purchase} />
      </Grid>
    ));
  }

  return (
    <div>
      { isLoading && <NewsLoader /> }
      { (!isLoading && error) && <ErrorCard />}
      { (!isLoading && !error)
          && (
          <span>
            <p className={classes.configFormTitle}>
              {t('Enviados')}
            </p>
            <Grid container spacing={2}>
              {purchaseGrid}
            </Grid>
          </span>
          )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.purchases.listPurchasesSended.isLoading,
  error: state.purchases.listPurchasesSended.error,
  succeeded: state.purchases.listPurchasesSended.succeeded,
  purchases: state.purchases.listPurchasesSended.data,
});

const mapDispatchToProps = {
  loadPurchasesSendedAction: loadPurchasesSended,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchaseSent));
