import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { ImportContacts } from '@material-ui/icons';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import PaymentConfirmation from './PaymentConfirmation';
import { completeMagazinePurchase } from '../../../actions/MagazineActions';
import useStylesBase from '../../../theme/Styles';
import { loadNewPurchaseData } from '../../../actions/PurchaseActions';

const ButtonComponent = ({
  magazineSelected,
  buyingMagazineWithNewCard,
  completeMagazinePurchaseAction,
  loadNewPurchaseDataAction,
  user,
  groupInfo,
  t,
}) => {
  const classesBase = useStylesBase()();
  const [open, setOpen] = useState(buyingMagazineWithNewCard);
  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="secondary"
        className={classesBase.marginHorizontal}
        onClick={() => { setOpen(true); loadNewPurchaseDataAction({ magazine: magazineSelected, user, groupInfo }); }}
      >
        <ImportContacts className={clsx(classesBase.iconLeft, classesBase.iconSmall)} style={{ marginRight: 5 }} />
        {t('Comprar')}
      </Button>
      <PaymentConfirmation
        open={open}
        onClose={() => { setOpen(false); completeMagazinePurchaseAction(); }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  groupInfo: state.groups.current.info,
  buyingMagazineWithNewCard: state.magazines.buyingMagazineWithNewCard,
});

const mapDispatchToProps = {
  completeMagazinePurchaseAction: completeMagazinePurchase,
  loadNewPurchaseDataAction: loadNewPurchaseData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ButtonComponent));
