export const stripeURL = 'https://api.stripe.com/v1';
export const stripeSecretKey = 'sk_test_pGgyMRXRAL1oZF2LLII49Q3q00Bbqwd5QT';
export const stripePublishableKey = 'pk_test_4rxQLqH44hvvH26oed03cg6O00Rf9P4TKd';
export const planKeys = {
  familyBimonthly: 'plan_FRSih0PrMD57l7',
  familyMonthly: 'plan_FRSjx3aTKeuAnS',
  familyBimonthlyAnual: 'plan_FRSjx3aTKeuAnS',
  familyMonthlyAnual: 'plan_FRSjx3aTKeuAnS',
  businessMonthly: 'plan_FRSkp0NdE3mlDf',
  businessYearly: 'plan_FRSlz3QQ2gPZum',
  geriatricMonthly: 'plan_FRSmK8hJ8wPLen',
  geriatricYearly: 'plan_FRSnL9vMb6iDNv',
  event: 'plan_FRSnuCoHeexPAe',
};

export const magazinePrice = 1290; // MUST BE IN CENTS


// export const stripeURL = 'https://api.stripe.com/v1';
// export const stripeSecretKey = 'sk_live_HsvXXLuneMBDAOgOm6O5It0a00cSPtLkjU';
// export const stripePublishableKey = 'pk_live_vHcaRQJ0OFBYfhZdX6S3pP3l';
// export const planKeys = {
//   familyBimonthly: 'plan_Fk7zHFaRw2sipl', //
//   familyMonthly: 'plan_Fk80RyG5WPTrvC', //
//   familyBimonthlyAnual: 'plan_FlLssl3L3geVtN', //
//   familyMonthlyAnual: 'plan_FlLrDHlSTWNBfj', //
//   businessMonthly: 'plan_Fk8BXN4lv23EAN', //
//   businessYearly: 'plan_FlLuzBQBiU7fAh', //
//   geriatricMonthly: 'plan_Fk8F0Dfo4q6kH3', //
//   geriatricYearly: 'plan_FlLltdFGsrCl5e', //
//   event: 'plan_FRSnuCoHeexPAe',
// };

// export const magazinePrice = 1290; // MUST BE IN CENTS
