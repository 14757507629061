import React from 'react';
import { Dialog, DialogContent, Grid, DialogActions, Button, Link } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PdfPreview from './PdfPreview';

const MagazinePreview = ({ t, magazine, open, onClose }) =>
{
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="post-dialog"
    >
      <DialogContent>
        <Grid container>
          <PdfPreview file={magazine.pdfURL} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Link
          style={{ textDecoration: 'none' }}
          component={Button}
          href={magazine.pdfURL}
          target="_blank"
        >
          {t('Descargar')}
        </Link>
        <Button onClick={onClose}>
          {t('Cerrar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(MagazinePreview);
