import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardContent, Typography, IconButton, CircularProgress, Button } from '@material-ui/core';
import { FavoriteBorder, Refresh } from '@material-ui/icons';
import { isEmpty } from 'lodash';

import ProfilePic from '../../users/ProfilePic';
import DateFormatted from '../../common/DateFormatted';
import useStyles from '../../../theme/Styles';
import { insertPost } from '../../../actions';

const NewPostUploading = ({ post, error, insertPostAction }) => {
  const handleOnSubmit = () => {
    insertPostAction(post.body, post.photo, post.groupCode, post.magazineId);
  };
  const classes = useStyles()();

  return (
    <div className={classes.newPostUploadingContainer}>
      <Card>
        <CardHeader
          avatar={<ProfilePic user={post.user} />}
          title={post.user.displayName}
          titleTypographyProps={{ noWrap: true }}
          subheader={<DateFormatted date={post.createdAt} />}
          subheaderTypographyProps={{ noWrap: true }}
        />
        <CardContent>
          {!isEmpty(post.body) && (
            <Typography variant="body1" className={classes.marginBottom}>
              {post.body}
            </Typography>
          )}
          <div>
            <img
              src={post.tempPhotoURL}
              style={{ width: '100%', cursor: 'pointer' }}
            />
          </div>
          <IconButton>
            <FavoriteBorder />
          </IconButton>
        </CardContent>
      </Card>
      <div className={classes.newPostUploadingOverlay}>
        {error !== ''
          ? (
            <div className={classes.newPostUploadingRetryError}>
              <p className={classes.newPostUploadingInput}>{error}</p>
              <Button
                onClick={handleOnSubmit}
                variant="contained"
                color="primary"
              >
                <Refresh />
              </Button>
            </div>
          )
          : (
            <CircularProgress
              className={classes.newPostUploadingLoader}
            />
          )}

      </div>
    </div>
  );
};

const mapDispatchToProps = {
  insertPostAction: insertPost,
};
export default connect(null, mapDispatchToProps)(NewPostUploading);
