import React, { useState, useEffect } from 'react';
import { Dialog, Button, DialogActions, DialogTitle, DialogContent, Card, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ChipInput from 'material-ui-chip-input';
import { isEmpty } from 'lodash';
import { sendEmailsForInvitation } from '../../services/backend';
import useStyles from '../../theme/Styles';

const InviteMembersModal = ({ onClose, open, t, user, groupInfo, ...other }) => {
  const classes = useStyles()();
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState(false);

  const emailIsValid = email => /\S+@\S+\.\S+/.test(email);

  const handleAddEmail = (email) => {
    setEmails(emails.concat([email]));
    setMessage('');
  };

  const handleDeleteEmail = (index) => {
    setEmails(emails.filter((e, index2) => index !== index2));
    setMessage('');
  };

  const validateEmail = email => emailIsValid(email);

  const formatSendEmails = () => {
    if (isEmpty(emails)) {
      setTypeMessage(false);
      setMessage(t('Por favor ingresar correos electrónicos'));
      return;
    }
    setIsLoading(true);
    const success = () => {
      setIsLoading(false);
      setTypeMessage(true);
      setMessage(t('La invitación fue enviada correctamente'));
    };
    const error = (res) => {
      setIsLoading(false);
      setTypeMessage(false);
      setMessage(t('No se pudo enviar la invitación, por favor intente mas tarde'));
      console.log(res);
    };
    sendEmailsForInvitation(user, groupInfo, emails, success, error);
  };

  useEffect(() => {
    setEmails([]);
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      aria-labelledby="post-dialog"
      onClose={onClose}
      {...other}
    >
      <DialogTitle>
        {t('Enviar invitación')}
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: 15 }}>
          {`${t('Para invitar a otras personas a este grupo, enviales el siguiente codigo para que lo utilicen al momento de crear una cuenta')}:`}
          <strong style={{ fontSize: 18 }}>{` ${groupInfo.groupCode}`}</strong>
          <br />
          <br />
          {`${t('O enviales una invitacion por correo con el siguiente formulario')}:`}
        </div>
        <Card className={classes.inviteMembersContainer}>
          <div className={classes.inviteMembersInputContainer}>
            <ChipInput
              value={emails}
              onAdd={email => handleAddEmail(email)}
              onDelete={(email, index) => handleDeleteEmail(index)}
              className={classes.inviteMembersInput}
              disableUnderline
              placeholder={t('Escribe los correos electronicos seguidos por un espacio')}
              newChipKeyCodes={[13, 32, 188, 186]}
              onBeforeAdd={email => validateEmail(email)}
              blurBehavior="add"
            />
          </div>
        </Card>
        {!isEmpty(message)
        && <div className={typeMessage ? classes.configInputFormSuccess : classes.configInputFormError}>{message}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('Cerrar')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus
          onClick={formatSendEmails}
          disabled={isLoading}
        >
          {t('Enviar')}
          {isLoading && <CircularProgress color="secondary" size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  groupInfo: state.groups.current.info,
});

export default connect(mapStateToProps)(withTranslation()(InviteMembersModal));
