import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Avatar, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import Edit from '@material-ui/icons/Edit';
import useStyles from '../../../theme/Styles';
import NewPhoto from '../../common/NewPhoto';


const AvatarChange = ({ user, currentPhoto, selectImageToUpload, t }) => {
  const classes = useStyles()();
  const { displayName, color } = user;
  const shortName = `${displayName[0]}${displayName.split(' ').length > 1 ? displayName.split(' ')[1][0] : ''}`;
  const [changeImage, setChangeImage] = useState(false);

  const handlePhotoUpload = (p) => {
    if (p) selectImageToUpload(URL.createObjectURL(p), p);
    else selectImageToUpload(null, null);
  };

  return (
    <React.Fragment>
      { !changeImage && !isEmpty(currentPhoto) && (
        <div className={classes.newPostImgPreviewContainerCircle} onClick={() => setChangeImage(true)}>
          <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', position: 'absolute', marginLeft: 120 }}>
            <Edit />
            {t('Editar foto de perfil')}
          </Typography>
          <Avatar
            alt={displayName}
            title={displayName}
            src={currentPhoto}
            className={classes.avatarChange}
          />
        </div>
      )
      }
      { !changeImage && isEmpty(currentPhoto) && (
        <div className={classes.newPostImgPreviewContainerCircle} onClick={() => setChangeImage(true)}>
          <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', position: 'absolute', marginLeft: 120 }}>
            <Edit />
            {t('Editar foto de perfil')}
          </Typography>
          <Avatar
            title={displayName}
            style={{ backgroundColor: color, fontSize: 55 }}
            className={classes.avatarChange}
          >
            {shortName}
          </Avatar>
        </div>
      )
      }
      {changeImage && (
      <NewPhoto
        onPhotoSelected={p => handlePhotoUpload(p)}
        circle
      />
      ) }
    </React.Fragment>
  );
};

export default withTranslation()(AvatarChange);
