import React, { useState, useRef } from 'react';
import { Grid, Card } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { AddAPhoto } from '@material-ui/icons';

import ProfilePic from '../../users/ProfilePic';
import NewPostModal from './NewPostModal';
import useStyles from '../../../theme/Styles';

const NewPostContainer = ({ groupCode, t }) => {
  const classes = useStyles()();
  const [open, setOpen] = useState(false);
  const textAreaInput = useRef(null);
  const onClose = () => setOpen(false);
  const onPostInserted = () => setOpen(false);

  const handleOnClick = () => {
    textAreaInput.current.blur();
    setOpen(true);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item md={7} xs={12}>
          <Card className={classes.newPostContainer}>
            <div className={classes.newPostAvatarContainer}>
              <ProfilePic />
            </div>
            <div className={classes.newPostInputContainer}>
              <textarea
                className={classes.newPostFakeInput}
                placeholder={t('Escribe tu mensaje')}
                rows={2}
                ref={textAreaInput}
                onFocus={handleOnClick}
              />
            </div>
            <AddAPhoto
              className={classes.newPostContainerImageIcon}
              onClick={handleOnClick}
            />
          </Card>
        </Grid>
      </Grid>

      <NewPostModal
        open={open}
        onClose={onClose}
        onPostInserted={onPostInserted}
        groupCode={groupCode}
      />

    </div>
  );
};

export default withTranslation()(NewPostContainer);
