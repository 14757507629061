import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, CircularProgress,
  FormControl, TextField, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withTranslation } from 'react-i18next';
import { Cancel } from '@material-ui/icons';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';
import { updateGroup, updateUserInfo } from '../../../actions';
import NewPhoto from '../../common/NewPhoto';
import GroupProfilePic from '../GroupProfilePic';
import { loadLocations } from '../../../services/firebase';


const FirstTimeModal = ({ open, group, other, updateGroupAction, isLoading, t,
  updateUserInfoAction, languajeDefault, user }) => {
  const groupType = group.info.type;
  const defaultMagazineTitle = groupType === 'family' ? `${t('Familia')} ${group.info.name}` : group.info.name;
  const defaultMagazineText = groupType === 'family'
    ? t('Para nuestra querida abuelita') : `${t('Revista del grupo ')} ${group.info.name}`;
  const classes = useStyles()();
  const [photo, setPhoto] = useState(null);
  const [saved, setSaved] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [magazineText, setMagazineText] = useState(defaultMagazineText);
  const [magazineTitle, setMagazineTitle] = useState(defaultMagazineTitle);
  const [language, setLanguage] = useState(languajeDefault);
  const [page, setPage] = useState(1);
  const [country, setCountry] = useState('');
  const [inputCountry, setInputCountry] = useState('');
  const [stateField, setStateField] = useState('');
  const [inputStateField, setInputStateField] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [locations, setLocations] = useState({});

  useEffect(() => {
    let mountedFirebase = true;
    loadLocations().then((snapshot) => { if (mountedFirebase) setLocations(snapshot.val()); });
    return () => { mountedFirebase = false; };
  }, []);

  const handleUpdateGroup = () => {
    if (page === 1) setPage(2);
    else {
      setSaved(true);
      updateGroupAction({ ...group.info,
        magazineText,
        language,
        deliveryAddress: {
          country,
          state: stateField,
          city,
          zipCode,
          address,
          address2,
        },
        magazineTitle }, photo || null);
      updateUserInfoAction({ ...user, language }, null, group.info.groupCode);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="post-dialog"
      onClose={() => {}}
      disableBackdropClick
      disableEscapeKeyDown
      {...other}
    >

      <DialogContent>

        {page === 1
          ? (
            <>
              <Typography
                variant="h6"
                className={classes.titleColor}
                style={{ textAlign: 'left' }}
              >
                {group.name}
              </Typography>

              <div className={classes.marginTop}>
                <Typography
                  variant="h6"
                  className={classes.titleColor}
                >
                  {t('Informacion del grupo')}
                </Typography>
                <Typography variant="body1" className={classes.titleColor}>
                  {t('Puedes cambiarla en un futuro en')}
          :
                </Typography>
                <Typography variant="body1" className={classes.blueText}>
                  {t('Configuracion > Grupo')}
                </Typography>
              </div>

              <div className={classes.bigMarginTop}>
                {
            !changeImage && (
              <div className={classes.marginBottom} style={{ textAlign: 'center' }}>
                <div className={classes.newPostImgPreviewContainer}>
                  <GroupProfilePic
                    groupInfo={group.info}
                    editing
                  />
                  <Cancel
                    className={classes.newPostRemoveImgBtn}
                    onClick={() => setChangeImage(true)}
                  />
                </div>

              </div>
            )
          }
                {changeImage && <NewPhoto onPhotoSelected={p => setPhoto(p)} /> }
              </div>

              <FormControl component="fieldset" className={classes.configInputField} style={{ width: '100%' }}>
                <InputLabel htmlFor="age-customized-select">{t('Lenguaje por defecto grupo')}</InputLabel>
                <Select
                  value={language}
                  onChange={(e) => { setLanguage(e.target.value); }}
                >
                  <MenuItem value="es">{t('Español')}</MenuItem>
                  <MenuItem value="en">{t('Ingles')}</MenuItem>
                </Select>
              </FormControl>
            </>
          )
          : (
            <>
              <Typography
                variant="h6"
                className={classes.titleColor}
                style={{ marginTop: '10px' }}
              >
                {t('Textos de la revista')}
              </Typography>
              <Typography variant="body1" className={classes.titleColor}>
                {t('Puedes cambiarla en un futuro en')}
            :
              </Typography>
              <Typography variant="body1" className={classes.blueText}>
                {t('Configuracion > Revista')}
              </Typography>
              <FormControl className={classes.configInputField} style={{ width: '100%' }}>
                <TextField
                  id="nameTitle"
                  label={t('Titulo de la revista (portada)')}
                  value={magazineTitle}
                  onChange={(e) => { setMagazineTitle(e.target.value); }}
                  margin="normal"
                  style={{ width: '100%' }}
                />
              </FormControl>

              <FormControl className={classes.configInputField} style={{ width: '100%' }}>
                <TextField
                  id="nameInput"
                  label={t('Frase de la revista (portada)')}
                  value={magazineText}
                  onChange={(e) => { setMagazineText(e.target.value); }}
                  margin="normal"
                  style={{ width: '100%' }}
                />
              </FormControl>
              {(groupType === 'family' || groupType === 'event') && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.titleColor} style={{ marginTop: 20 }}>
                      {t('Dirección de envío')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="nameInput"
                      label={t('Direccion de entrega')}
                      value={address}
                      onChange={(e) => { setAddress(e.target.value); }}
                      margin="normal"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="second_address_input"
                      label={t('Direccion de entrega 2')}
                      value={address2}
                      onChange={e => setAddress2(e.target.value)}
                      margin="normal"
                      className={classes.landingInputField}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="city_input"
                      label={t('Ciudad')}
                      value={city}
                      onChange={e => setCity(e.target.value)}
                      margin="normal"
                      className={classes.landingInputField}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={stateField}
                      onChange={(e, newValue) => setStateField(newValue)}
                      inputValue={inputStateField}
                      onInputChange={(event, newInputValue) => setInputStateField(newInputValue)}
                      id="autocomplete_state"
                      options={!isEmpty(locations) && !isEmpty(country) && country in locations
                        ? Object.keys(locations[country].states).map(newStates => newStates) : []}
                      renderInput={params => <TextField {...params} label={t('Estado')} margin="normal" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="zip_code_input"
                      label={t('Codigo Zip')}
                      value={zipCode}
                      onChange={e => setZipCode(e.target.value)}
                      margin="normal"
                      className={classes.landingInputField}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={country}
                      onChange={(e, newValue) => { setCountry(newValue); setStateField(''); }}
                      inputValue={inputCountry}
                      onInputChange={(event, newInputValue) => { setInputCountry(newInputValue); }}
                      id="autocomplete_country"
                      options={Object.keys(locations).map(countryName => countryName)}
                      renderInput={params => <TextField {...params} label={t('Pais')} margin="normal" />}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )
      }
      </DialogContent>
      <DialogActions>
        { (!saved || isLoading)
          && (
          <Button
            onClick={handleUpdateGroup}
            size="small"
            className={classes.configInputButton}
            color="inherit"
            variant="outlined"
            disabled={isLoading}
          >
            { isLoading && <CircularProgress size={20} className={classes.configButtonProgress} />}
            {t('Continuar')}
          </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  updateGroupAction: updateGroup,
  updateUserInfoAction: updateUserInfo,
};

const mapStateToProps = state => ({
  isLoading: state.groups.newGroup.isLoading,
  languajeDefault: state.user.config.lang,
  user: state.user.info,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FirstTimeModal));
