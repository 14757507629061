import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { StripeProvider } from 'react-stripe-elements';
import AppRouter from './router/AppRouter';
import configureStore from './config/StoreConfig';
import configureFirebase from './config/FirebaseConfig';
import configureTheme from './config/ThemeConfig';
import { stripePublishableKey } from './config/StripeConfig';

const store = configureStore();
configureFirebase();
const theme = configureTheme();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <StripeProvider apiKey={stripePublishableKey}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppRouter />
            </ThemeProvider>
          </MuiThemeProvider>
        </StripeProvider>
      </Provider>
    );
  }
}

export default App;
