import firebase from 'firebase';
import { DateTime } from 'luxon';
import i18n from 'i18next';
import getS3Client from '../config/S3Client';
import { sendNotificationNewPost } from '../services/backend';
import {
  MAGAZINE_INSERT_START,
  MAGAZINE_INSERT_SUCCEESS,
  MAGAZINE_INSERT_FAILURE,
  LOAD_GROUP_MAGAZINES_START,
  LOAD_GROUP_MAGAZINES_FAIL,
  LOAD_GROUP_MAGAZINES_SUCCESS,
  POST_INSERT_START,
  POST_INSERT_SUCCEESS,
  POST_INSERT_FAILURE,
  POST_DELETE_START,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAILURE,
  POST_EDIT_START,
  POST_EDIT_SUCCESS,
  POST_EDIT_FAILURE,
  LOAD_ACTIVE_MAGAZINES_START,
  LOAD_ACTIVE_MAGAZINES_FAIL,
  LOAD_ACTIVE_MAGAZINES_SUCCESS,
  LOAD_PAST_MAGAZINES_START,
  LOAD_PAST_MAGAZINES_FAIL,
  LOAD_PAST_MAGAZINES_SUCCESS,
  SET_BUYING_MAGAZINE_WITH_NEW_CARD,
  RESET_NEW_DATA_PURCHASE,
} from './types';


const calculate = (subscriptionType = 'LIFETIME') => {
  switch (subscriptionType.toUpperCase()) {
    case 'MONTHLY':
      return DateTime.local().plus({ months: 1 }).toISO();
    case 'BIMONTHLY':
      return DateTime.local().plus({ weeks: 2 }).toISO();
    default:
      return null;
  }
};

export const completeMagazinePurchase = () => (
  (dispatch) => {
    dispatch({ type: SET_BUYING_MAGAZINE_WITH_NEW_CARD, payload: { flag: false } });
    dispatch({ type: RESET_NEW_DATA_PURCHASE });
  }
);

export const insertMagazine = (groupCode, limit, subscriptionType, edition) => (
  async (dispatch) => {
    const newMagazine = {
      active: true,
      groupCode,
      limit,
      edition,
      startDate: DateTime.local().toISO(),
      endDate: calculate(subscriptionType),
      posts: [],
    };
    dispatch({
      type: MAGAZINE_INSERT_START,
      payload: { magazine: newMagazine },
    });

    try {
      firebase.database().ref('/magazines').push(newMagazine);
      dispatch({
        type: MAGAZINE_INSERT_SUCCEESS,
        payload: { magazine: newMagazine },
      });
    } catch (e) {
      dispatch({
        type: MAGAZINE_INSERT_FAILURE,
        payload: { message: i18n.t('No se pudo crear la revista. Por favor intente nuevamente.') },
      });
    }
  }
);

export const objectToList = (magazine) => {
  const postObjects = magazine.posts;
  const posts = [];
  if (postObjects) Object.keys(postObjects).forEach((key) => { posts.push({ ...postObjects[key], key }); });
  return { ...magazine, posts };
};

export const loadMagazines = groupCode => (
  (dispatch) => {
    try {
      dispatch({ type: LOAD_GROUP_MAGAZINES_START });
      firebase.database().ref('/magazines').orderByChild('groupCode').equalTo(groupCode)
        .on('value', (snapshot) => {
          const magazines = [];
          snapshot.forEach((snap) => {
            magazines.push({ ...objectToList(snap.val()), key: snap.key });
          });
          magazines.reverse();
          dispatch({ type: LOAD_GROUP_MAGAZINES_SUCCESS, payload: { magazines, groupCode } });
        });
    } catch (e) {
      dispatch({
        type: LOAD_GROUP_MAGAZINES_FAIL,
        payload: { message: i18n.t('No se pudo cargar las revistas por grupo. Por favor intente nuevamente.') },
      });
    }
  }
);

export const loadActiveMagazines = () => (
  (dispatch) => {
    try {
      dispatch({ type: LOAD_ACTIVE_MAGAZINES_START });
      firebase.database().ref('/magazines').orderByChild('active').equalTo(true)
        .on('value', (snapshot) => {
          const magazines = [];
          snapshot.forEach((snap) => {
            magazines.push({ ...objectToList(snap.val()), key: snap.key });
          });
          magazines.reverse();
          dispatch({ type: LOAD_ACTIVE_MAGAZINES_SUCCESS, payload: { magazines } });
        });
    } catch (e) {
      dispatch({ type: LOAD_ACTIVE_MAGAZINES_FAIL });
    }
  }
);

export const loadPastMagazines = () => (
  (dispatch) => {
    try {
      dispatch({ type: LOAD_PAST_MAGAZINES_START });
      firebase.database().ref('/magazines').orderByChild('active').equalTo(false)
        .on('value', (snapshot) => {
          const magazines = [];
          snapshot.forEach((snap) => {
            magazines.push({ ...objectToList(snap.val()), key: snap.key });
          });
          magazines.reverse();
          dispatch({ type: LOAD_PAST_MAGAZINES_SUCCESS, payload: { magazines } });
        });
    } catch (e) {
      dispatch({ type: LOAD_PAST_MAGAZINES_FAIL });
    }
  }
);

export const insertPost = (body, photo, groupCode, magazineId, photoStyle = null) => (
  // TODO: Validate we have a photo
  async (dispatch, getState) => {
    const tempPhotoURL = window.URL.createObjectURL(photo);
    const tempPost = {
      body,
      tempPhotoURL,
      photo,
      groupCode,
      magazineId,
      user: getState().user.info,
      createdAt: DateTime.local().toISO(),
    };
    dispatch({
      type: POST_INSERT_START,
      payload: { post: tempPost },
    });
    try {
      const S3Client = getS3Client('postPhotos');
      const uploadData = await S3Client.uploadFile(photo);
      const photoURL = uploadData.location;
      const post = {
        body,
        photoURL,
        photoStyle,
        user: getState().user.info,
        createdAt: DateTime.local().toISO(),
      };

      firebase.database().ref(`/magazines/${magazineId}/posts`).push(post)
        .then(() => {
          sendNotificationNewPost(post, groupCode);
          dispatch({ type: POST_INSERT_SUCCEESS });
        });
    } catch {
      dispatch({
        type: POST_INSERT_FAILURE,
        payload: { message: i18n.t('No se pudo subir tu post. Por favor intente nuevamente.') },
      });
    }
  }
);


export const deletePost = (magazineId, key) => (
  async (dispatch) => {
    dispatch({ type: POST_DELETE_START });
    try {
      await firebase.database().ref(`/magazines/${magazineId}/posts/${key}`).remove();
      dispatch({ type: POST_DELETE_SUCCESS });
    } catch {
      dispatch({ type: POST_DELETE_FAILURE });
    }
  }
);

export const editPost = (magazineId, key, body) => (
  async (dispatch) => {
    const newData = { body };
    dispatch({ type: POST_EDIT_START });
    try {
      await firebase.database().ref(`/magazines/${magazineId}/posts/${key}`).update(newData);
      dispatch({ type: POST_EDIT_SUCCESS });
    } catch {
      dispatch({ type: POST_EDIT_FAILURE });
    }
  }
);
