import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { FavoriteBorder } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PostCard from '../posts/PostCard';
import useStyles from '../../theme/Styles';

const GridView = ({ magazines, userId, t }) => {
  const classes = useStyles()();

  const likedMagazines = [];
  magazines.forEach((magazine) => {
    const posts = magazine.posts.filter(
      post => post.likes && Object.keys(post.likes).filter(
        user => post.likes[user].uid === userId,
      ).length > 0,
    );
    if (posts.length > 0) {
      likedMagazines.push({
        ...magazine,
        posts,
      });
    }
  });

  const postIsLiked = (post) => {
    const { likes } = post;
    let result = false;
    if (likes) result = (Object.keys(likes).filter(x => likes[x].uid === userId).length > 0);
    return result;
  };

  let likedPosts = [];
  magazines.forEach((magazine) => {
    likedPosts = likedPosts.concat(magazine.posts.filter(x => postIsLiked(x)));
  });

  return (
    <Grid container spacing={2} style={{ marginTop: 50 }}>
      {likedMagazines.length > 0
        ? likedMagazines.map(magazine => magazine.posts.map(post => (
          <Grid item md={4} xs={6} key={post.key}>
            <PostCard post={post} groupCode={magazines[0].groupCode} magazineId={magazine.key} />
          </Grid>
        )))
        : (
          <Grid item md={12} xs={12}>
            <Card className={classes.emptyPostContainerCard}>
              <CardContent>
                <FavoriteBorder className={classes.emptyPostContainerImageIcon} />
                <p>
                  {t('No le diste Me Gusta a ninguna publicacion')}
                </p>
              </CardContent>
            </Card>
          </Grid>
        )
      }
    </Grid>
  );
};

const mapStateToProps = state => ({
  userId: state.user.info.uid,
});

export default connect(mapStateToProps, null)(withTranslation()(GridView));
