import React from 'react';

import { connect } from 'react-redux';
import { Avatar } from '@material-ui/core';
import useStyles from '../../../theme/Styles';
import PostLikeButton from './PostLikeButton';

const PostLikeFooter = ({ groupCode, post, groupUsers, magazineId }) => {
  const classes = useStyles()();
  const { likes } = post;
  let likesSet;
  if (likes) {
    likesSet = Object.keys(likes).slice(-6).map((index) => {
      const userId = likes[index].uid;
      const user = groupUsers.filter(y => y.uid === userId)[0];
      const { displayName, photoURL, color } = user;
      const shortName = `${displayName[0]}${displayName.split(' ').length > 1 ? displayName.split(' ')[1][0] : ''}`;
      return (
        <span key={index}>
          {user.photoURL ? (
            <Avatar
              alt={displayName}
              title={displayName}
              src={photoURL}
              className={classes.likeProfilePic}
            />
          ) : (
            <Avatar
              title={displayName}
              style={{ backgroundColor: color }}
              className={classes.likeProfilePic}
            >
              {shortName}
            </Avatar>
          )
          }
        </span>
      );
    });
  }
  return (
    <div>
      <PostLikeButton post={post} groupCode={groupCode} magazineId={magazineId} />
      <div className={classes.likeProfilePicSet}>
        {likesSet}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  groupUsers: state.groups.current.users,
});

export default connect(mapStateToProps, null)(PostLikeFooter);
