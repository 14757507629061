import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch, Route, Link, withRouter } from 'react-router-dom';
import { Tabs, Tab, Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';

import useStyles from '../../theme/Styles';
import ConfigProfile from './config_profile';
import ConfigGroupEdit from './ConfigGroupEdit';
import ConfigEditUsers from './config_users';
import ConfigCard from './config_card';
import ConfigMagazine from './config_magazine';
import ShippingAddressForm from './config_shipping_address/ShippingAddressForm';

const ConfigPage = ({ t, location, groupCode, users, currentUid }) => {
  const classes = useStyles()();
  const currentUser = users.find(x => currentUid === x.uid);
  let role = '';
  if (currentUser) role = currentUser.role || '';

  let newPathName = `/app/g/${groupCode}/configuration/edit_info`;
  if (location.pathname.includes(`/app/g/${groupCode}/configuration/edit_group`)) {
    newPathName = `/app/g/${groupCode}/configuration/edit_group`;
  }
  if (location.pathname.includes(`/app/g/${groupCode}/configuration/edit_users`)) {
    newPathName = `/app/g/${groupCode}/configuration/edit_users`;
  }
  if (location.pathname.includes(`/app/g/${groupCode}/configuration/config_card`)) {
    newPathName = `/app/g/${groupCode}/configuration/config_card`;
  }

  if (location.pathname.includes(`/app/g/${groupCode}/configuration/magazine`)) {
    newPathName = `/app/g/${groupCode}/configuration/magazine`;
  }
  if (location.pathname.includes(`/app/g/${groupCode}/configuration/shipping_address`)) {
    newPathName = `/app/g/${groupCode}/configuration/shipping_address`;
  }

  return (
    <Card className={classes.configTabsContainer} spacing={2} style={{ marginTop: 50 }}>
      <CardContent>
        <div className={classes.verticalTabsContainer}>
          <Tabs
            value={newPathName}
            classes={{
              indicator: classes.verticalTabsTabIndicator,
              flexContainer: classes.verticalTabsFlex,
            }}
            className={classes.verticalTabs}
          >
            <Tab
              label={t('Información Personal')}
              classes={{
                selected: classes.verticalTabsTabSelected,
              }}
              component={Link}
              to={`/app/g/${groupCode}/configuration/edit_info`}
              value={`/app/g/${groupCode}/configuration/edit_info`}
            />
            <Tab
              label={t('Pagos')}
              classes={{
                selected: classes.verticalTabsTabSelected,
              }}
              component={Link}
              to={`/app/g/${groupCode}/configuration/config_card`}
              value={`/app/g/${groupCode}/configuration/config_card`}
            />
            {
              (role === 'payer' || role === 'admin' || !role)
            && (
            <Tab
              label={t('Direccion de envio')}
              classes={{
                selected: classes.verticalTabsTabSelected,
              }}
              component={Link}
              to={`/app/g/${groupCode}/configuration/shipping_address`}
              value={`/app/g/${groupCode}/configuration/shipping_address`}
            />
            )}
            {
              (role === 'payer' || role === 'admin' || !role)
            && (
              <Tab
                label={t('Editar Grupo')}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                component={Link}
                to={`/app/g/${groupCode}/configuration/edit_group`}
                value={`/app/g/${groupCode}/configuration/edit_group`}
              />
            )}
            {
              (role === 'payer' || role === 'admin' || !role)
            && (
              <Tab
                label={t('Revista')}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                component={Link}
                to={`/app/g/${groupCode}/configuration/magazine`}
                value={`/app/g/${groupCode}/configuration/magazine`}
              />
            )}
            {
              (role === 'payer' || role === 'admin' || !role)
            && (
              <Tab
                label={t('Usuarios Del Grupo')}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                component={Link}
                to={`/app/g/${groupCode}/configuration/edit_users`}
                value={`/app/g/${groupCode}/configuration/edit_users`}
              />
            )}
          </Tabs>
          <div className={classes.configInfoContainer}>
            <TabContainer>
              <Switch>
                <Route
                  path={`/app/g/${groupCode}/configuration/edit_info`}
                  component={() => <ConfigProfile />}
                />
                <Route
                  path={`/app/g/${groupCode}/configuration/config_card`}
                  component={() => <Elements><ConfigCard /></Elements>}
                />
                {
                  (role === 'payer' || role === 'admin' || !role)
                  && (
                    <Route
                      path={`/app/g/${groupCode}/configuration/edit_group`}
                      component={() => <ConfigGroupEdit currentUserRole={role} />}
                    />
                  )
                }
                {
                  (role === 'payer' || role === 'admin' || !role)
                  && (
                    <Route
                      path={`/app/g/${groupCode}/configuration/magazine`}
                      component={() => <ConfigMagazine />}
                    />
                  )
                }
                {
                  (role === 'payer' || role === 'admin' || !role)
                  && (
                    <Route
                      path={`/app/g/${groupCode}/configuration/edit_users`}
                      component={() => <ConfigEditUsers />}
                    />
                  )
                }
                {
                  (role === 'payer' || role === 'admin' || !role)
                  && (
                    <Route
                      path={`/app/g/${groupCode}/configuration/shipping_address`}
                      component={() => <ShippingAddressForm />}
                    />
                  )
                }
                <Redirect
                  exact
                  from={`/app/g/${groupCode}/configuration`}
                  to={`/app/g/${groupCode}/configuration/edit_info`}
                />
                <Redirect
                  exact
                  from={`/app/g/${groupCode}/configuration/*`}
                  to={`/app/g/${groupCode}/configuration/edit_info`}
                />
              </Switch>
            </TabContainer>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => ({
  users: state.groups.current.users,
  currentUid: state.user.info.uid,
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(withTranslation()(ConfigPage)));

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">
      { children }
    </Typography>
  );
}
