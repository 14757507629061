import React from 'react';
import { Grid, Dialog, Typography, IconButton, Link } from '@material-ui/core';
import SaveAlt from '@material-ui/icons/SaveAlt';

import PostAuthor from './PostAuthor';
import PostLikeFooter from './like/PostLikeFooter';
import useStyles from '../../theme/Styles';

const download = (url, fileName) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};

export default ({ post, onClose, open, groupCode, magazineId, ...other }) => {
  const classes = useStyles()();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      aria-labelledby="post-dialog"
      onClose={onClose}
      {...other}
    >
      <Grid container>
        <Grid
          item
          md={8}
          className={classes.postModalPhotoContainer}
        >
          <img src={post.photoURL} className={classes.postModalPhoto} />

          <Link href={post.photoURL} target="_blank" style={{ color: 'white', position: 'absolute', bottom: '20px' }}>
            <SaveAlt />
          </Link>
        </Grid>
        <Grid item md={4}>
          <PostAuthor groupCode={groupCode} post={post} />
          <PostLikeFooter post={post} groupCode={groupCode} magazineId={magazineId} />
          <Typography variant="body1" className={classes.margin}>
            {post.body}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};
