import { combineReducers } from 'redux';
import UserReducers from './UserReducers';
import GroupReducers from './GroupReducers';
import MagazineReducers from './MagazineReducers';
import ProfileReducers from './ProfileReducers';
import DesignReducers from './DesignReducers';
import PurchaseReducers from './PurchaseReducers';

export default combineReducers({
  user: UserReducers,
  groups: GroupReducers,
  magazines: MagazineReducers,
  userProfile: ProfileReducers,
  design: DesignReducers,
  purchases: PurchaseReducers,
});
