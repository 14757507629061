import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { TextField, FormControl, Button, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import useStyles from '../../theme/Styles';
import { recoveryPassword } from '../../actions';

const RecoveryPassword = ({ errorCode, errorMessage, isLoginLoading, recoveryPasswordAction, t, successMessage }) => {
  const [email, setEmail] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const classes = useStyles()();

  const handleEmailChange = (e) => {
    setShowError(false);
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    setShowError(true);
    if (!isEmpty(email)) {
      recoveryPasswordAction(email);
    }
    e.preventDefault();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isEmpty(email)) {
      setShowError(true);
      recoveryPasswordAction(email);
    }
  };

  return (
    <div className={classes.ladingInputFormContainer}>
      <p className={classes.landingInputFormTitle}>{t('Recupera tu cuenta')}</p>
      <FormControl className={classes.landingInputFormControl}>
        <TextField
          id="emailInput"
          label={t('Email')}
          value={email}
          onChange={handleEmailChange}
          margin="normal"
          className={classes.landingInputField}
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      <Link to="/auth/login" className={classes.textLoginRecovery}>{t('Iniciar Sesión')}</Link>
      <Button
        className={classes.landingInputButton}
        color="inherit"
        variant="outlined"
        disabled={isLoginLoading || isEmpty(email)}
        onClick={handleSubmit}
      >
        { isLoginLoading && <CircularProgress size={20} className={classes.landingButtonProgress} />}
        {t('Recuperar contrasena')}
      </Button>
      { showError
          && (
            (!isEmpty(errorCode) && <div className={classes.landingInputFormError}>{errorMessage}</div>)
            || (!isEmpty(successMessage) && <div className={classes.landingInputFormSuccess}>{successMessage}</div>)
          )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  errorCode: state.user.errorCode,
  errorMessage: state.user.errorMessage,
  isLoginLoading: state.user.isLoginLoading,
  successMessage: state.user.successMessage,
});

const mapDispatchToProps = {
  recoveryPasswordAction: recoveryPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecoveryPassword));
