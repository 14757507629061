import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import useStyles from '../../theme/Styles';
import Login from '../users/Login';
import { Images } from '../../theme';

const MobileLandingPage = ({ loggedIn }) => {
  const classes = useStyles()();
  return (
    <React.Fragment>
      {loggedIn === true && <Redirect to="/" />}
      <Grid container>
        <Grid item sm={12} xs={12}>
          <div>
            <img
              src={Images.logo}
              alt="Logo"
              className={classes.landingPageLogoImage}
              style={{ marginTop: 20, width: 90, height: 90 }}
            />
          </div>
          <Login />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
});

export default connect(mapStateToProps)(withTranslation()(MobileLandingPage));
