import React from 'react';
import { Grid, Divider, Typography, Button } from '@material-ui/core';
import { LabelImportant, CloudDownload } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { Interval, DateTime } from 'luxon';
import BuyButton from './buy_magazine/Button';
import DateFormatted from '../common/DateFormatted';
import useStyles from '../../theme/Styles';

const GridViewTitleContainer = ({ magazine, t }) => {
  const classes = useStyles()();
  const intervalDate = Interval.fromDateTimes(DateTime.local(), DateTime.fromISO(magazine.endDate)).count('days') - 1;
  return (
    <Grid container className={`${classes.magazineTitleContainer} sticky-header`}>
      <Grid item md={6} sm={6}>
        <Typography variant="h4" className={classes.magazineTitle} style={{ paddingTop: 10, paddingBottom: 10 }}>
          {`${t('Revista')} `}
          <DateFormatted date={magazine.endDate} format="shortWithYear" />
        </Typography>
      </Grid>
      <Grid item md={6} sm={6} style={{ textAlign: 'right', alignSelf: 'center', marginBottom: 10 }}>
        {magazine.active ? (
          <div>
            <Typography variant="subtitle1" className={classes.magazineSubtitle}>
              {`${magazine.posts.length} ${t('de')} ${magazine.limit} ${t('Publicaciones')}`}
            </Typography>
            <Typography variant="h4" className={clsx(classes.magazineSubtitle, classes.marginHorizontal)}>
              <LabelImportant style={{ fontSize: 12 }} />
            </Typography>
            <Typography variant="subtitle1" className={classes.magazineSubtitle}>
              {t('Faltan {{dias}} días', { dias: intervalDate })}
            </Typography>
          </div>
        ) : (
          <Grid container style={{ textAlign: 'right' }}>
            <Grid item sm={4} md={3}>
              <Typography variant="subtitle1" className={classes.magazineSubtitle}>
                {`${magazine.posts.length} ${t('Publicaciones')}`}
              </Typography>
            </Grid>
            <Grid item sm={8} md={9}>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.marginHorizontal}
                target="_blank"
                href={magazine.pdfURL}
                disabled={isNil(magazine.pdfURL)}
                style={{ marginRight: '5px' }}
              >
                <CloudDownload className={clsx(classes.iconLeft, classes.iconSmall)} />
                {t('Descargar')}
              </Button>
              <BuyButton magazineSelected={magazine} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Divider variant="fullWidth" />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(GridViewTitleContainer);
