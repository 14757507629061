import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Table, TableBody, TableRow, TableCell, Button, CircularProgress,
  FormControl, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { getCardInfo, chargeMagazineDownload } from '../../../services/stripe';
import useStyles from '../../../theme/Styles';
import NewsLoader from '../../common/NewsLoader';
import { magazinePrice } from '../../../config/StripeConfig';
import { insertPurchase } from '../../../actions';
import { loadLocations } from '../../../services/firebase';

const RegisteredCard = ({ user, onSuccess, t, insertPurchaseAction, purchase }) => {
  /* eslint-disable */
    useEffect(() => {
      loadCard();

      let mountedFirebase = true;
      loadLocations().then((snapshot) => { if (mountedFirebase) setLocations(snapshot.val()); });
      return () => { mountedFirebase = false; };
    }, []);
  /* eslint-enable */
  const classes = useStyles()();
  const [card, setCard] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [inputCountry, setInputCountry] = useState('');
  const [stateField, setStateField] = useState('');
  const [inputStateField, setInputStateField] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [locations, setLocations] = useState({});

  const loadCard = () => {
    const success = (cardData) => {
      setCard(cardData);
    };
    const error = () => {
      setCard('error');
    };
    getCardInfo(user.stripeId, success, error);
  };

  const buyMagazine = () => {
    if (!isEmpty(country) && !isEmpty(city) && !isEmpty(address)) {
      setErrorMessage('');
      setLoading(true);
      const success = (data) => {
        const dataPurchase = {
          ...purchase,
          stripePurchaseId: data.id,
          createdAt: DateTime.local().toISO(),
          deliveryAddress: {
            country,
            state: stateField,
            city,
            zipCode,
            address,
            address2,
          },
          sent: false,
        };
        insertPurchaseAction(dataPurchase);
        setLoading(false);
        onSuccess();
      };
      const error = (em) => {
        setErrorMessage(em);
        setLoading(false);
      };
      chargeMagazineDownload(user, success, error);
    } else {
      setErrorMessage(t('Faltan completar campos'));
    }
  };

  return (
    <>
      <div>
        <p className={classes.landingInputFormTitle}>{t('Confirmacion del pago')}</p>
        { (card === null) && <NewsLoader /> }
        { (card && card === 'error')
          && <p>{t('Algo sucedio. Porfavor intentalo despues.')}</p>
        }
        { (card && card !== 'error')
          && (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><strong>{t('Precio de revista')}</strong></TableCell>
                <TableCell>{`${magazinePrice / 100} $`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>{t('Card')}</strong></TableCell>
                <TableCell>{`${card.brand} XXXX-XXXX-XXXX-${card.last4}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          )
        }
        <Typography variant="h6" className={classes.titleColor} style={{ marginTop: 20 }}>
          {t('Dirección de envío')}
        </Typography>
        <FormControl className={classes.landingInputFormControlDialog} style={{ width: '100%'}}>
          <TextField
            id="first_address_input"
            label={`${t('Direccion')} 1 *`}
            value={address}
            onChange={(e) => { setAddress(e.target.value); }}
            margin="normal"
            className={classes.landingInputField}
          />
        </FormControl>
        <FormControl className={classes.landingInputFormControlDialog} style={{ width: '100%'}}>
          <TextField
            id="second_address_input"
            label={`${t('Direccion')} 2`}
            value={address2}
            onChange={(e) => { setAddress2(e.target.value); }}
            margin="normal"
            className={classes.landingInputField}
          />
        </FormControl>
        <FormControl style={{ width: '45%' }}>
          <TextField
            id="city_input"
            label={`${t('Ciudad')} *`}
            value={city}
            onChange={(e) => { setCity(e.target.value); }}
            margin="normal"
            className={classes.landingInputField}
          />
        </FormControl>
        <FormControl style={{ width: '45%', marginLeft: 10 }}>
          <Autocomplete
            value={stateField}
            onChange={(e, newValue) => setStateField(newValue)}
            inputValue={inputStateField}
            onInputChange={(event, newInputValue) => setInputStateField(newInputValue)}
            id="autocomplete_state"
            options={!isEmpty(locations) && !isEmpty(country) && country in locations
              ? Object.keys(locations[country].states).map(newStates => newStates) : []}
            renderInput={params => <TextField {...params} label={`${t('Estado')} *`} margin="normal" />}
          />
        </FormControl>
        <FormControl style={{ width: '45%', marginLeft: 10 }}>
          <TextField
            id="zip_code_input"
            label={t('Codigo Zip')}
            value={zipCode}
            onChange={(e) => { setZipCode(e.target.value); }}
            margin="normal"
            className={classes.landingInputField}
          />
        </FormControl>
        <FormControl style={{ width: '45%' }}>
          <Autocomplete
            value={country}
            onChange={(e, newValue) => { setCountry(newValue); setStateField(''); }}
            inputValue={inputCountry}
            onInputChange={(event, newInputValue) => { setInputCountry(newInputValue); }}
            id="autocomplete_country"
            options={Object.keys(locations).map(countryName => countryName)}
            renderInput={params => <TextField {...params} label={`${t('Pais')} *`} margin="normal" />}
          />
        </FormControl>
      </div>
      <Button
        className={classes.landingUserButton}
        color="inherit"
        variant="outlined"
        onClick={buyMagazine}
        disabled={loading}
      >
        {loading && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
        {t('Comprar')}
      </Button>
      {!isEmpty(errorMessage)
        && <div className={classes.landingInputFormError}>{errorMessage}</div>
      }
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  purchase: state.purchases.newPurchase,
});

const mapDispatchToProps = {
  insertPurchaseAction: insertPurchase,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisteredCard));
