import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, FormControl, TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { updateGroup, resetUpdateGroupState } from '../../../actions';
import { loadLocations } from '../../../services/firebase';
import useStyles from '../../../theme/Styles';

const ShippingAddressForm = ({ t, groupInfo, isLoading, succeeded, errorMessage, updateGroupAction,
  resetUpdateGroupStateAction }) => {

  const classes = useStyles()();

  const { deliveryAddress } = groupInfo;

  const [country, setCountry] = useState(deliveryAddress && deliveryAddress.country ? deliveryAddress.country : '');
  const [inputCountry, setInputCountry] = useState('');
  const [stateField, setStateField] = useState(deliveryAddress && deliveryAddress.state ? deliveryAddress.state : '');
  const [inputStateField, setInputStateField] = useState('');
  const [city, setCity] = useState(deliveryAddress && deliveryAddress.city ? deliveryAddress.city : '');
  const [zipCode, setZipCode] = useState(deliveryAddress && deliveryAddress.zipCode ? deliveryAddress.zipCode : '');
  const [address, setAddress] = useState(deliveryAddress && deliveryAddress.address ? deliveryAddress.address : '');
  const [address2, setAddress2] = useState(deliveryAddress && deliveryAddress.address2 ? deliveryAddress.address2 : '');
  const [locations, setLocations] = useState({});
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    let mountedFirebase = true;
    loadLocations().then((snapshot) => { if (mountedFirebase) setLocations(snapshot.val()); });
    return () => { mountedFirebase = false; };
  }, []);

  const handleSubmit = () => {
    setShowMessage(true);
    updateGroupAction({ ...groupInfo,
      deliveryAddress: {
        address,
        address2,
        city,
        country,
        zipCode,
        state: stateField,
      } }, null);
  };

  const canSendForm = (
    !isEmpty(country)
    && !isEmpty(stateField)
    && !isEmpty(city)
    && !isEmpty(address)
    && !isLoading
  );

  if (succeeded && showMessage) {
    setTimeout(() => resetUpdateGroupStateAction(),
      4000);
  }

  return (
    <div className={classes.configCardContainer}>
      <p className={classes.configFormTitle}>
        {t('Direccion de envio')}
      </p>
      <FormControl className={classes.landingInputFormControlDialog}>
        <TextField
          id="first_address_input"
          label={`${t('Direccion')} 1 *`}
          value={address}
          onChange={(e) => { setAddress(e.target.value); setShowMessage(false); }}
          margin="normal"
          className={classes.landingInputField}
        />
      </FormControl>

      <FormControl className={classes.landingInputFormControlDialog}>
        <TextField
          id="second_address_input"
          label={`${t('Direccion')} 2`}
          value={address2}
          onChange={(e) => { setAddress2(e.target.value); setShowMessage(false); }}
          margin="normal"
          className={classes.landingInputField}
        />
      </FormControl>
      <FormControl style={{ width: '45%' }}>
        <TextField
          id="city_input"
          label={`${t('Ciudad')} *`}
          value={city}
          onChange={(e) => { setCity(e.target.value); setShowMessage(false); }}
          margin="normal"
          className={classes.landingInputField}
        />
      </FormControl>
      <FormControl style={{ width: '45%', marginLeft: 5 }}>
        <Autocomplete
          value={stateField}
          onChange={(e, newValue) => setStateField(newValue)}
          inputValue={inputStateField}
          onInputChange={(event, newInputValue) => setInputStateField(newInputValue)}
          id="autocomplete_state"
          options={!isEmpty(locations) && !isEmpty(country) && country in locations
            ? Object.keys(locations[country].states).map(newStates => newStates) : []}
          renderInput={params => <TextField {...params} label={`${t('Estado')} *`} margin="normal" />}
        />
      </FormControl>
      <FormControl style={{ width: '45%', marginLeft: 5 }}>
        <TextField
          id="zip_code_input"
          label={t('Codigo Zip')}
          value={zipCode}
          onChange={(e) => { setZipCode(e.target.value); setShowMessage(false); }}
          margin="normal"
          className={classes.landingInputField}
        />
      </FormControl>
      <FormControl style={{ width: '45%' }}>
        <Autocomplete
          value={country}
          onChange={(e, newValue) => { setCountry(newValue); setStateField(''); }}
          inputValue={inputCountry}
          onInputChange={(event, newInputValue) => { setInputCountry(newInputValue); }}
          id="autocomplete_country"
          options={Object.keys(locations).map(countryName => countryName)}
          renderInput={params => <TextField {...params} label={`${t('Pais')} *`} margin="normal" />}
        />
      </FormControl>
      <div className={classes.landingUserActions}>
        <Button
          className={classes.configInputButton}
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!canSendForm}
        >
          { isLoading && <CircularProgress size={20} className={classes.configButtonProgress} />}
          {t('Guardar Cambios')}
        </Button>
      </div>
      {!isEmpty(errorMessage)
        && showMessage
        && (
        <div className={classes.configInputFormError}>
          {errorMessage}
        </div>
        )}
      {succeeded
          && showMessage
          && (
          <div className={classes.configInputFormSuccess}>
            {t('Data updated successfully')}
          </div>
          )}
    </div>
  );
};

const mapStateToProps = state => ({
  groupInfo: state.groups.current.info,
  isLoading: state.groups.newGroup.isLoading,
  errorMessage: state.groups.newGroup.errorMessage,
  succeeded: state.groups.newGroup.succeeded,
});

const mapDispatchToProps = {
  updateGroupAction: updateGroup,
  resetUpdateGroupStateAction: resetUpdateGroupState,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShippingAddressForm));
