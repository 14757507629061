import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Card } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import ProfilePic from '../../users/ProfilePic';
import NewPostPhoto from './NewPostPhoto';
import { insertPost } from '../../../actions';
import useStyles from '../../../theme/Styles';

const NewPostModal = ({ groupCode, currentMagazine, insertPostAction,
  onClose, onPostInserted, match, open, t, ...other }) => {
  const classes = useStyles()();
  const [body, setBody] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoStyle, setPhotoStyle] = useState(null);
  const [submitInput, setSubmitInput] = useState('');
  const handleOnSubmit = () => {
    // TODO: Validate we have a photo
    insertPostAction(body, photo, groupCode, currentMagazine.key, photoStyle);
    onPostInserted();
  };
  const handleOnClose = () => onClose();

  useEffect(() => {
    if (submitInput) submitInput.focus();
  });

  useEffect(() => {
    setBody('');
  }, [open]);

  const handlePhotoSelected = (p, ps) => {
    setPhoto(p);
    setPhotoStyle(ps);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      aria-labelledby="new-post-dialog"
      onClose={handleOnClose}
      {...other}
    >
      <DialogTitle>
        {t('Nueva Publicación')}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Card className={classes.newPostContainer}>
              <div className={classes.newPostAvatarContainer}>
                <ProfilePic />
              </div>
              <div className={classes.newPostInputContainer}>
                <textarea
                  className={classes.newPostInput}
                  placeholder={t('Escribe tu mensaje')}
                  rows={4}
                  ref={event => setSubmitInput(event)}
                  onChange={e => setBody(e.target.value)}
                >
                  {body}
                </textarea>
              </div>
            </Card>
          </Grid>

          <Grid item md={12} xs={12}>
            <NewPostPhoto onPhotoSelected={(p, ps) => handlePhotoSelected(p, ps)} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>
          {t('Cancelar')}
        </Button>
        <Button
          onClick={handleOnSubmit}
          variant="contained"
          color="primary"
          autoFocus
          disabled={isNil(photo)}
        >
          {t('Publicar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  currentMagazine: state.magazines.activeMagazine,
});

const mapDispatchToProps = {
  insertPostAction: insertPost,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewPostModal));
