import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import firebase from 'firebase';
import { Button } from '@material-ui/core';
import NewsLoader from '../../common/NewsLoader';
import ErrorCard from '../ErrorCard';
import FrontCoverTable from './FrontCoverTable';
import { loadDesign } from '../../../actions';
import NewPhoto from '../../common/NewPhoto';
import getS3Client from '../../../config/S3Client';

import useStyles from '../../../theme/Styles';

const FrontCover = ({ t, loadDesignAction, frontCoverImages, succeeded, error, isLoading }) => {
  const classes = useStyles()();

  const [photo, setPhoto] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [showMessage, setShowMessage] = useState(null);

  const handleUpdate = async () => {
    setLoading(true);
    let photoURL = '';
    try {
      if (photo) {
        const S3Client = getS3Client('profilePics');
        const data = await S3Client.uploadFile(photo);
        photoURL = data.location;
      }
      await firebase.database().ref('/design/frontCoverImages').push({
        photoURL,
      }).then(() => {
        setShowMessage('succeeded');
        setLoading(false);
      });
    } catch {
      setShowMessage('error');
      setLoading(false);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (!succeeded) loadDesignAction();
  }, [succeeded]);
  /* eslint-disable */

  if (showMessage) {
    setTimeout(() => {
      setShowMessage('');
    },
    4000);
  }

  return (
    <>
    { isLoading && <NewsLoader /> }
    { (!isLoading && error) && <ErrorCard />}
    { (!isLoading && !error) &&
    <div>
      <p className={classes.configFormTitle}>{t('Nueva Imagen')}</p>
      <NewPhoto onPhotoSelected={p => setPhoto(p)} />
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleUpdate()}
        style={{ marginTop: '20px' }}
        disabled={loading || !photo}
      >
        {t('Agregar imagen')}
      </Button>
      {showMessage === 'succeeded'
        && (
          <div className={classes.configInputFormSuccess}>
            {t('La imagen fue agregada.')}
          </div>
        )
      }

      {showMessage === 'error'
        && (
          <div className={classes.configInputFormError}>
            {t('Algo sucedio. Porfavor intentelo luego.')}
          </div>
        )
      }
      <FrontCoverTable frontCoverImages={frontCoverImages} />
    </div>
  }
  </>
  );
};


const mapStateToProps = state => ({
  isLoading: state.design.isLoading,
  error: state.design.error,
  succeeded: state.design.succeeded,
  frontCoverImages: state.design.frontCoverImages,
});

const mapDispatchToProps = {
  loadDesignAction: loadDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FrontCover));
