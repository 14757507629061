const planTypes = {
  familyBimonthly: {
    name: 'Bi mensual',
    description: '1 revista cada 2 semanas',
    type: 'BIMONTHLY',
    postsLimit: 50,
    price: '14.90',
    usersLimit: 100,
  },
  familyBimonthlyAnual: {
    name: 'Bi mensual',
    description: '1 revista cada 2 semanas',
    type: 'BIMONTHLY',
    postsLimit: 50,
    price: '12.42',
    usersLimit: 100,
    discount: true,
  },
  familyMonthly: {
    name: 'Mensual',
    description: '1 revista cada mes',
    type: 'MONTHLY',
    postsLimit: 50,
    price: '9.90',
    usersLimit: 100,
  },
  familyMonthlyAnual: {
    name: 'Mensual',
    description: '1 revista cada mes',
    type: 'MONTHLY',
    postsLimit: 50,
    price: '8.25',
    usersLimit: 100,
    discount: true,
  },
  businessMonthly: {
    name: 'Mensual',
    description: '1 revista digital cada 2 semanas',
    type: 'BIMONTHLY',
    postsLimit: 50,
    price: '35.00',
    usersLimit: 100,
  },
  businessYearly: {
    name: 'Anual',
    description: '1 revista digital cada 2 semanas',
    type: 'BIMONTHLY',
    postsLimit: 50,
    price: '29.17',
    usersLimit: 100,
  },
  geriatricMonthly: {
    description: '1 revista digital cada 2 semanas por familia',
    name: 'Mensual',
    type: 'BIMONTHLY',
    postsLimit: 50,
    price: '49.00',
    usersLimit: 100,
  },
  geriatricYearly: {
    description: '1 revista digital cada 2 semanas por familia',
    name: 'Anual',
    type: 'BIMONTHLY',
    postsLimit: 50,
    price: '40.83',
    usersLimit: 100,
  },
  event: {
    name: 'Evento',
    description: '1 revista con hasta 200 fotos',
    type: 'LIFETIME',
    postsLimit: 200,
    price: '49',
    usersLimit: 0,
  },

};

export default planTypes;
