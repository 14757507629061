import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button, Table, TableBody,
  TableCell, TableRow, CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';
import { getCardInfo } from '../../../services/stripe';
import NewsLoader from '../../common/NewsLoader';
import { updateCard } from '../../../actions';

const ConfigCard = ({ t, user, stripe, groupCode, updateCardAction, isLoading, errorMessage }) => {
  const [hasCard, setHasCard] = useState(null);
  const [card, setCard] = useState(null);
  const [cardError, setCardError] = useState('');
  const [loadingCard, setLoadingCard] = useState(false);

  /* eslint-disable */
    useEffect(() => {
      if (user.stripeId) {
        setHasCard(true);
        loadCard();
      }
      else {
        setHasCard(false)
      }
    }, []);
  /* eslint-enable */

  const handleChange = () => {
    setCardError('');
  };

  const loadCard = () => {
    const success = (cardData) => {
      setCard(cardData);
    };
    const error = () => {
      setCard('error');
    };
    getCardInfo(user.stripeId, success, error);
  };

  const registerCard = () => {
    setLoadingCard(true);
    stripe.createSource({
      type: 'card',
    }).then((response) => {
      if (!isEmpty(response.error)) { setCardError(response.error.message); } else {
        updateCardAction(user, response.source.id, groupCode);
      }
      setLoadingCard(false);
    });
  };
  const spinning = isLoading || loadingCard;
  const errorShowed = errorMessage || cardError;

  const classes = useStyles()();
  return (
    <div>
      <p className={classes.configFormTitle}>
        {t('Configurar tarjeta')}
      </p>
      { (card === null && hasCard) && <NewsLoader /> }
      { (card && card === 'error' && hasCard)
        && <p>{t('Algo sucedio. Porfavor intentalo despues.')}</p>
      }
      { (card && card !== 'error' && hasCard)
      && (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><strong>{t('Tarjeta actual')}</strong></TableCell>
                <TableCell>{`${card.brand} XXXX-XXXX-XXXX-${card.last4}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )
      }
      {!hasCard
        && <p>{t('No tienes una tarjeta registrada aun.')}</p>
      }
      {(!hasCard || (card && card !== 'error' && hasCard))
      && (
        <span>
          <p>{t('Registrar una nueva tarjeta')}</p>
          <div className={classes.cardForm}>
            <CardElement
              hidePostalCode
              onChange={handleChange}
            />
          </div>
          <Button
            className={classes.configInputButton}
            color="inherit"
            variant="outlined"
            onClick={registerCard}
            disabled={spinning}
          >
            {spinning && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
            {t('Registrar tarjeta')}
          </Button>
          {!isEmpty(errorShowed)
            && (
            <div className={classes.configInputFormError}>
              {errorShowed}
            </div>
            )}
        </span>
      )
    }
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  groupCode: state.groups.current.info.groupCode,
  isLoading: state.user.updateInfo.isLoading,
  errorMessage: state.user.updateInfo.errorMessage,
});

const mapDispatchToProps = {
  updateCardAction: updateCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(injectStripe(ConfigCard)));
