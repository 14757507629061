import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import firebase from 'firebase';
import { FormControl, InputLabel, Input, Button } from '@material-ui/core';
import NewsLoader from '../../common/NewsLoader';
import ErrorCard from '../ErrorCard';
import CuriositiesTable from './CuriositiesTable';
import { loadDesign } from '../../../actions';

import useStyles from '../../../theme/Styles';

const Curiosities = ({ t, loadDesignAction, curiosities, succeeded, error, isLoading }) => {
  const classes = useStyles()();

  const [curiosityTextEN, setCuriosityTextEN] = useState('');
  const [curiosityTextSP, setCuriosityTextSP] = useState('');
  const [curiosityTextFR, setCuriosityTextFR] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [showMessage, setShowMessage] = useState('');
  const handleUpdate = () => {
    setLoading(true);
    firebase.database().ref('/design/curiosities').push({
      en: curiosityTextEN,
      fr: curiosityTextFR,
      sp: curiosityTextSP,
    })
      .then(() => {
        setCuriosityTextEN('');
        setCuriosityTextSP('');
        setCuriosityTextFR('');
        setShowMessage('succeeded');
        setLoading(false);
      })
      .catch(() => {
        setShowMessage('error');
        setLoading(false);
      });
  };

  /* eslint-disable */
  useEffect(() => {
    if (!succeeded) loadDesignAction();
  }, [succeeded]);
  /* eslint-disable */

  if (showMessage) {
    setTimeout(() => {
      setShowMessage('');
    },
    4000);
  }

  return (
    <>
    { isLoading && <NewsLoader /> }
    { (!isLoading && error) && <ErrorCard />}
    { (!isLoading && !error) &&
    <div>
      <p className={classes.configFormTitle}>{t('Nueva curiosidad')}</p>

      <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="my-input">{t('Curiosidad Español')}</InputLabel>
        <Input
          id="curiosityTextSP"
          value={curiosityTextSP}
          onChange={(e) => {
            setCuriosityTextSP(e.target.value);
          }}
        />
      </FormControl>

      <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="my-input">{t('Curiosidad Ingles')}</InputLabel>
        <Input
          id="curiosityTextEN"
          value={curiosityTextEN}
          onChange={(e) => {
            setCuriosityTextEN(e.target.value);
          }}
        />
      </FormControl>

      <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="my-input">{t('Curiosidad Frances')}</InputLabel>
        <Input
          id="curiosityTextFR"
          value={curiosityTextFR}
          onChange={(e) => {
            setCuriosityTextFR(e.target.value);
          }}
        />
      </FormControl>

      <Button
        color="primary"
        variant="contained"
        onClick={() => handleUpdate()}
        style={{ marginTop: '20px' }}
        disabled={
          loading || curiosityTextEN === ""
          || curiosityTextFR === "" || curiosityTextSP === ""
        }
      >
        {t('Crear curiosidad')}
      </Button>
      {showMessage === 'succeeded'
        && (
          <div className={classes.configInputFormSuccess}>
            {t('La curiosidad a sido creada.')}
          </div>
        )
      }

      {showMessage === 'error'
        && (
          <div className={classes.configInputFormError}>
            {t('Algo sucedio. Porfavor intentelo luego.')}
          </div>
        )
      }
      <CuriositiesTable curiosities={curiosities} />
    </div>
  }
  </>
  );
};


const mapStateToProps = state => ({
  isLoading: state.design.isLoading,
  error: state.design.error,
  succeeded: state.design.succeeded,
  curiosities: state.design.curiosities,
});

const mapDispatchToProps = {
  loadDesignAction: loadDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Curiosities));
