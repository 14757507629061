import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import GroupHeader from './GroupHeader';
import GroupTabs from './GroupTabs';
import { loadGroupInfo, loadGroupUsers, loadMagazines, resetRedirectState } from '../../actions';
import FirstLoginModal from '../users/sign_up/FirstLoginModal';
import FirstTimeModal from './create_group/FirstTimeModal';

const GroupPage = ({ loadGroupInfoAction, loadGroupUsersAction, firstLogin,
  loadMagazinesAction, group, magazines, user, match, resetRedirectAction }) => {
  const { groupCode } = match.params;
  useEffect(() => {
    loadGroupInfoAction(groupCode);
    loadGroupUsersAction(groupCode);
    loadMagazinesAction(groupCode);
    resetRedirectAction();
  }, []);

  return (
    <div>
      {(group.info.firstTime && !firstLogin) && (
        <FirstTimeModal open group={group} />
      )}
      <FirstLoginModal
        open={firstLogin}
        user={user}
        groupCode={groupCode}
      />
      <GroupHeader group={group} magazinesList={magazines.actualGroupMagazines} />
      <GroupTabs groupCode={groupCode} magazines={magazines} />
    </div>
  );
};

const mapStateToProps = state => ({
  group: state.groups.current,
  magazines: state.magazines,
  firstLogin: state.user.firstLogin,
  user: state.user.info,
});

const mapDispatchToProps = {
  loadGroupInfoAction: loadGroupInfo,
  loadGroupUsersAction: loadGroupUsers,
  loadMagazinesAction: loadMagazines,
  resetRedirectAction: resetRedirectState,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);
