export const sendEmailsForInvitation = (userInfo, groupInfo, emails, success, error) => {
  // use http://backend.andcomag.com:8080/magazines/paaqu/sendInvitationMail for test production
  fetch(`http://backend.andcomag.com:8080/magazines/${groupInfo.groupCode}/sendInvitationMail`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      users: [{ email: emails }],
      from: userInfo.displayName,
      groupName: groupInfo.name,
      language: userInfo.language,
    }),
  })
    .then(res => (res.ok ? success(res) : error(res)))
    .catch(err => error(err));
};

export const generatePdfMagazine = (magazineKey, success, error) => {
  fetch(`http://backend.andcomag.com:8080/magazines/${magazineKey}/generate`, {
    method: 'post',
  })
    .then(res => res.json())
    .then(data => (data.status === 'OK' ? success() : error()))
    .catch(err => error(err));
};

export const sendNotificationNewPost = (postData, groupCode) => {
  fetch(`http://backend.andcomag.com:8080/magazines/${groupCode}/sendNotificationMail`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ post: postData }),
  });
};
