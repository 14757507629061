import firebase from 'firebase';
import { DateTime } from 'luxon';
import { isNil, map } from 'lodash';
import i18n from 'i18next';
import { createCustomer, updateCustomerCard } from '../services/stripe';
import getS3Client from '../config/S3Client';

import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_SIGNUP_START,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILURE,
  USER_LOGOUT_START,
  USER_LOGOUT_SUCCESS,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_RECOVERY_PASSWORD_START,
  USER_RECOVERY_PASSWORD_SUCCESS,
  USER_RECOVERY_PASSWORD_FAILURE,
  USER_UPDATE_INFO_RESET,
  USER_UPDATE_INFO_START,
  USER_UPDATE_INFO_SUCCEESS,
  USER_UPDATE_INFO_FAILURE,
  USER_DELETE_START,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_JOIN_NEW_GROUP_START,
  USER_JOIN_NEW_GROUP_SUCCESS,
  USER_JOIN_NEW_GROUP_FAILURE,
  CHANGE_LANGUAGE,
  SET_BUYING_MAGAZINE_WITH_NEW_CARD,
  USER_REDIRECT_AFTER_LOGIN,
} from './types';

const USER_COLORS = ['#ff4c4c', '#6a737b', '#008374', '#d20962', '#b84592', '#7f181b', '#1c79c0', '#323b43', '#706357'];

export const login = (email, password, redirect) => (
  (dispatch) => {
    dispatch({ type: USER_LOGIN_START });
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((response) => {
        dispatch({ type: USER_LOGIN_SUCCESS, payload: { user: response.user } });
        dispatch({ type: USER_REDIRECT_AFTER_LOGIN, payload: { place: redirect } });
      })
      .catch((error) => {
        const { code, message } = error;
        dispatch({ type: USER_LOGIN_FAILURE, payload: { code, message } });
      });
  }
);

export const updateCard = (user, cardToken, groupCode) => (
  (dispatch) => {
    dispatch({ type: USER_UPDATE_INFO_START });
    const customer = {
      description: `account - ${user.email}`,
      email: user.email,
      name: `${user.name} ${user.lastname}`,
      source: cardToken,
    };

    const errorHandler = (message) => {
      dispatch({
        type: USER_UPDATE_INFO_FAILURE,
        payload: { message },
      });
    };

    const createCustomerSuccess = (stripeCustomer) => {
      firebase.database().ref(`/users/${user.uid}/info`).update(
        {
          stripeId: stripeCustomer.id,
        },
      );
      firebase.database().ref(`/groups/${groupCode}/users/${user.uid}`).update(
        {
          stripeId: stripeCustomer,
        },
      );
      dispatch({ type: SET_BUYING_MAGAZINE_WITH_NEW_CARD, payload: { flag: true } });
      dispatch({ type: USER_UPDATE_INFO_SUCCEESS });
    };

    if (user.stripeId) {
      updateCustomerCard(user.stripeId, cardToken, createCustomerSuccess, errorHandler);
    } else {
      createCustomer(customer, createCustomerSuccess, errorHandler); // Handle errors
    }
  }
);

export const signUp = (groupCode, name, lastname, email, password,
  role, callback, stripeId = null, stripeSubscription = null, birthday = null, language = null) => (
  (dispatch) => {
    dispatch({ type: USER_SIGNUP_START });

    firebase.database().ref(`/groups/${groupCode}`).once('value')
      .then((groupSnapshot) => {
        const group = groupSnapshot.val();
        if (isNil(group)) {
          dispatch({
            type: USER_SIGNUP_FAILURE,
            payload: {
              code: 'invalid_group_code',
              message: 'El código de grupo o familia es incorrecto. Por favor intente nuevamente.',
            },
          });
          return;
        }
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then((response) => {
            const { user } = response;
            user.updateProfile({ displayName: `${name} ${lastname}` });

            const userData = {
              uid: user.uid,
              name,
              lastname,
              displayName: `${name} ${lastname}`,
              birthday,
              language,
              email,
              stripeId,
              stripeSubscriptions: { [groupCode]: stripeSubscription },
              color: USER_COLORS[Math.floor(Math.random() * USER_COLORS.length)],
            };

            firebase.database().ref(`/users/${user.uid}/info`).set({ ...userData })
              .then(() => {
                firebase.database().ref(`/users/${user.uid}/groups/${groupCode}`).set({
                  name: group.info.name, type: group.info.type, createdAt: DateTime.local().toISO(),
                });
                firebase.database().ref(`/groups/${groupCode}/users/${user.uid}`).set({ ...userData, role });

                if (!isNil(callback)) {
                  callback();
                }
              });
            dispatch({ type: USER_SIGNUP_SUCCESS });
          })
          .catch((error) => {
            const { code, message } = error;
            dispatch({ type: USER_SIGNUP_FAILURE, payload: { code, message } });
          });
      })
      .catch(() => {
        dispatch({
          type: USER_SIGNUP_FAILURE,
          payload: {
            code: 'failed_to_retrieve_group',
            message: 'No se pudo registrar la cuenta. Por favor verifica el estado de tu conexión a internet',
          },
        });
      });
  }
);

export const logout = () => (
  (dispatch) => {
    dispatch({ type: USER_LOGOUT_START });
    firebase.auth().signOut()
      .then(response => dispatch({ type: USER_LOGOUT_SUCCESS, payload: { ...response } }));
  }
);

export const userLoggedIn = user => (
  (dispatch) => {
    firebase.database().ref(`/users/${user.uid}`).on('value', (snapshot) => {
      const lang = snapshot.val().info.language;
      const groups = [];
      map(snapshot.val().groups, (group, key) => groups.push({ ...group, code: key }));
      dispatch({ type: USER_LOGGED_IN, payload: { user: { ...snapshot.val().info }, groups, lang } });
    });
  }
);

export const userLoggedOut = () => (
  (dispatch) => {
    dispatch({ type: USER_LOGGED_OUT });
  }
);

export const resetUpdateUserState = () => (
  (dispatch) => {
    dispatch({ type: USER_UPDATE_INFO_RESET });
  }
);

export const updateUserInfo = (user, photoFile, groupCode) => (
  async (dispatch) => {
    dispatch({ type: USER_UPDATE_INFO_START });
    const userData = {
      ...user,
      displayName: `${user.name} ${user.lastname}`,
      updatedAt: DateTime.local().toISO(),
    };
    try {
      if (!isNil(photoFile)) {
        const S3Client = getS3Client('profilePics');
        const data = await S3Client.uploadFile(photoFile);
        userData.photoURL = data.location;
      }

      await firebase.database().ref(`/users/${user.uid}/info`).update(userData);
      await firebase.database().ref(`/groups/${groupCode}/users/${user.uid}`).update(userData);
      dispatch({ type: USER_UPDATE_INFO_SUCCEESS });
    } catch (e) {
      dispatch({
        type: USER_UPDATE_INFO_FAILURE,
        payload: { message: i18n.t('No se pude guardar los cambios. Por favor intente nuevamente.') },
      });
    }
  }
);

export const changeRole = (user, groupCode, newRole) => (
  async (dispatch) => {
    dispatch({ type: USER_UPDATE_INFO_START });
    const userData = {
      ...user,
      role: newRole,
    };
    try {
      await firebase.database().ref(`/groups/${groupCode}/users/${user.uid}`).update(userData);
      dispatch({ type: USER_UPDATE_INFO_SUCCEESS });
    } catch (e) {
      dispatch({
        type: USER_UPDATE_INFO_FAILURE,
        payload: { message: i18n.t('No se pude guardar los cambios. Por favor intente nuevamente.') },
      });
    }
  }
);

export const removeFromGroup = (user, groupCode) => (
  async (dispatch) => {
    dispatch({ type: USER_DELETE_START });
    try {
      await firebase.database().ref(`/groups/${groupCode}/users/${user.uid}`).remove();
      await firebase.database().ref(`/users/${user.uid}/groups/${groupCode}`).remove();
      dispatch({ type: USER_DELETE_SUCCESS });
    } catch (e) {
      dispatch({ type: USER_DELETE_FAILURE });
    }
  }
);

export const changeLanguage = (userId, lang) => (
  (dispatch) => {
    firebase.database().ref(`/users/${userId}/info`).update({ language: lang });
    dispatch({ type: CHANGE_LANGUAGE });
  }
);

export const recoveryPassword = email => (
  (dispatch) => {
    dispatch({ type: USER_RECOVERY_PASSWORD_START });
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        const messageText = i18n.t('Hemos enviado un correo con las instrucciones para recuperar tu contrasena');
        dispatch({ type: USER_RECOVERY_PASSWORD_SUCCESS, payload: { message: messageText } });
      })
      .catch((error) => {
        const { code, message } = error;
        dispatch({ type: USER_RECOVERY_PASSWORD_FAILURE, payload: { code, message } });
      });
  }
);

export const joinNewGroup = (user, groupCode, joinGoupSuccess) => (
  (dispatch) => {
    dispatch({ type: USER_JOIN_NEW_GROUP_START });
    try {
      firebase.database().ref(`/groups/${groupCode}`).once('value')
        .then((groupSnapshot) => {
          const group = groupSnapshot.val();
          if (isNil(group)) {
            dispatch({
              type: USER_JOIN_NEW_GROUP_FAILURE,
              payload: {
                message: 'El código de grupo o familia es incorrecto. Por favor intente nuevamente.',
              },
            });
            return;
          }
          firebase.database().ref(`/users/${user.uid}/groups/${groupCode}`).set({
            name: group.info.name, type: group.info.type, createdAt: DateTime.local().toISO(),
          });
          firebase.database().ref(`/groups/${groupCode}/users/${user.uid}`).set(user);
          joinGoupSuccess();
          dispatch({ type: USER_JOIN_NEW_GROUP_SUCCESS });
        });
    } catch {
      dispatch({
        type: USER_JOIN_NEW_GROUP_FAILURE,
        payload: {
          message: 'No disponible',
        },
      });
    }
  }
);
export const resetRedirectState = () => (
  (dispatch) => {
    dispatch({ type: USER_REDIRECT_AFTER_LOGIN, payload: { place: null } });
  }
);
