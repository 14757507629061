import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { Stepper, Step, StepLabel, Dialog, DialogContent, Slide, withMobileDialog } from '@material-ui/core';
import { Elements } from 'react-stripe-elements';
import useStyles from '../../../theme/Styles';
import { createGroup } from '../../../actions';
import planTypes from '../../common/planTypes';
import groupTypes from '../../common/groupTypes';

import GroupNameForm from './GroupNameForm';
import GroupPlanForm from './GroupPlanForm';
import GroupUserForm from './GroupUserForm';
import GroupPaymentForm from './GroupPaymentForm';

const NewGroup = ({ t, isLoading, fullScreen, createGroupAction }) => {
  const classes = useStyles()();
  const [groupType, setNewGroupType] = React.useState('family'); // family, school, geriatric, event
  const [groupName, setGroupName] = React.useState('');
  const [groupPlan, setGroupPlan] = React.useState(''); // bimonthly, monthly, event
  const [name, setName] = React.useState('');
  const [lastname, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [geriatricCode, setGeriatricCode] = React.useState('');
  const [eventDate, setEventDate] = React.useState(DateTime.local().plus({ months: 1 }).toSQLDate());
  const [currentStep, setCurrentStep] = React.useState(1);
  const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);
  const canSendForm = (
    !isEmpty(groupType)
    && !isEmpty(groupName)
    && !isEmpty(groupPlan)
    && !isEmpty(name)
    && !isEmpty(lastname)
    && !isEmpty(email)
    && !isEmpty(password)
    && !isLoading
  );

  const setGroupType = (newGroupType) => {
    const { plans } = groupTypes[newGroupType];
    if (plans.length === 1) setGroupPlan(plans[0]);
    setNewGroupType(newGroupType);
  };

  const allSteps = {
    1: (<Step key="step1"><StepLabel>{t('Información grupo')}</StepLabel></Step>),
    2: (<Step key="step2"><StepLabel>{t('Información Usuario')}</StepLabel></Step>),
    3: (<Step key="step3"><StepLabel>{t('Suscripción')}</StepLabel></Step>),
    4: (<Step key="step4"><StepLabel>{t('Pago')}</StepLabel></Step>),
  };

  const getSteps = () => (
    <React.Fragment>
      <Stepper activeStep={currentStep - 1} alternativeLabel nonLinear className={classes.containerSteper}>
        {groupTypes[groupType].steps.map(group => allSteps[group])}
      </Stepper>
    </React.Fragment>
  );
  const handleSubmit = (cardToken = null, coupon = null) => {
    if (canSendForm) {
      const redirectAfterSuccess = code => code;// code => history.push(`/app/g/${code}`);
      const newEventDate = groupType === 'event' ? eventDate : null;
      createGroupAction(
        cardToken,
        coupon,
        groupType,
        groupName,
        groupPlan,
        planTypes[groupPlan].type,
        planTypes[groupPlan].postsLimit,
        planTypes[groupPlan].usersLimit,
        newEventDate,
        { name, lastname, email, password },
        redirectAfterSuccess,
      );
    }
  };

  const handlePlanSubmit = (plan) => {
    setGroupPlan(plan);
    goNextStep();
  };

  const goPreviousStep = () => {
    const groupSteps = groupTypes[groupType].steps;
    const stepLocation = groupSteps.indexOf(currentStep);
    const previousStep = groupSteps[stepLocation - 1];
    if (previousStep) setCurrentStep(previousStep);
  };

  const goNextStep = (cardToken = null, coupon = null) => {
    const groupSteps = groupTypes[groupType].steps;
    const stepLocation = groupSteps.indexOf(currentStep);
    const nextStep = groupSteps[stepLocation + 1];
    if (nextStep) {
      setCurrentStep(nextStep);
    } else if (currentStep === groupSteps[groupSteps.length - 1]) {
      handleSubmit(cardToken, coupon);
    }
  };

  const baseData = {
    t,
    isLoading,
    getSteps,
    goPreviousStep,
    goNextStep,
    isLastStep: currentStep === groupTypes[groupType].steps[groupTypes[groupType].steps.length - 1],
  };

  const groupNameData = {
    ...baseData,
    groupType,
    setGroupType,
    groupName,
    setGroupName,
    geriatricCode,
    setGeriatricCode,
    eventDate,
    setEventDate,
  };

  const groupPlanData = {
    ...baseData,
    handlePlanSubmit,
    groupType,
  };

  const groupUserData = {
    ...baseData,
    name,
    setName,
    lastname,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
  };

  const groupPaymentData = {
    ...baseData,
    handleSubmit,
  };

  let toRender = '';
  switch (currentStep) {
    case 1:
      toRender = <GroupNameForm data={groupNameData} />;
      break;
    case 2:
      toRender = (
        <Dialog
          fullScreen={fullScreen}
          open
          aria-labelledby="responsive-dialog-title"
          maxWidth="lg"
        >
          <DialogContent>
            <GroupUserForm data={groupUserData} />
          </DialogContent>
        </Dialog>
      );
      break;
    case 3:
      toRender = (
        <Dialog
          fullScreen={fullScreen}
          open
          aria-labelledby="responsive-dialog-title"
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogContent>
            <GroupPlanForm data={groupPlanData} />
          </DialogContent>
        </Dialog>
      );
      break;
    case 4:
      toRender = (
        <Dialog
          fullScreen={fullScreen}
          open
          aria-labelledby="responsive-dialog-title"
          maxWidth="lg"
        >
          <DialogContent>
            <Elements>
              <GroupPaymentForm data={groupPaymentData} />
            </Elements>
          </DialogContent>
        </Dialog>
      );
      break;
    default:
      toRender = <GroupNameForm data={groupNameData} />;
      break;
  }

  return (
    <div className={classes.ladingInputFormContainer}>
      {toRender}
    </div>
  );
};

const mapDispatchToProps = {
  createGroupAction: createGroup,
};

const mapStateToProps = state => ({
  // TODO: this is a temporal fix until the create group task is complete
  isLoading: state.groups.newGroup.isLoading,
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withMobileDialog()(NewGroup)));
