import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { CloudDownload, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'lodash';
import BuyButton from './buy_magazine/Button';
import DateFormatted from '../common/DateFormatted';
import useStylesBase from '../../theme/Styles';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    margin: 'auto 0',
  },
  header: {
    // display: 'flex',
    // alignItems: 'center',
    // height: 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // backgroundColor: theme.palette.background.default,
  },
  img: {
    minHeight: 240,
    width: '100%',
    overflow: 'hidden',
    display: 'block',
    cursor: 'pointer',
  },
  body: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 8,
    marginBottom: 16,
  },
  titleP: {
    display: 'inline-block',
    cursor: 'pointer',
  },
}));

const MagazineSlider = ({ magazine, onOpen, onChangeMagazine, t }) => {
  const classes = useStyles();
  const classesBase = useStylesBase()();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(isEmpty(magazine.posts) ? -1 : 0);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  return (
    <Card className={classes.root}>
      <Paper square elevation={0} className={classes.header} style={{ paddingTop: 16 }}>
        <Typography variant="h6" className={classes.titleP} onClick={() => { onOpen(); onChangeMagazine(magazine); }}>
          {`${t('Revista')} `}
          <DateFormatted date={magazine.endDate} format="shortWithYear" />
        </Typography>
      </Paper>
      <div style={{ height: 240, overflow: 'hidden' }}>
        {!isEmpty(magazine.posts) && (
          <img
            className={classes.img}
            src={magazine.posts[activeStep].photoURL}
            alt={magazine.posts[activeStep].body}
            onClick={() => { onOpen(); onChangeMagazine(magazine); }}
          />
        )}
      </div>
      <MobileStepper
        steps={magazine.posts.length}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={(
          <Button size="small" onClick={handleNext} disabled={activeStep === magazine.posts.length - 1}>
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        )}
        backButton={(
          <Button size="small" onClick={handleBack} disabled={activeStep === 0 || activeStep === -1}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>
        )}
      />
      <div style={{ backgroundColor: '#fafafa' }}>
        { (!magazine.active) && (
          <div style={{ marginBottom: 16 }}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              className={classesBase.marginHorizontal}
              target="_blank"
              href={magazine.pdfURL}
              disabled={isNil(magazine.pdfURL)}
              style={{ marginRight: '10px' }}
            >
              <CloudDownload className={clsx(classesBase.iconLeft, classesBase.iconSmall)} />
              {t('Descargar')}
            </Button>
            <BuyButton />
          </div>
        )}
      </div>
    </Card>
  );
};

export default withTranslation()(MagazineSlider);
