import React from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import useStyles from '../../theme/Styles';

import MagazineSlider from './MagazineSlider';
import MagazinePreview from './MagazinePreview';

const MagazineView = ({ magazines, t }) => {
  const classes = useStyles()();
  const [open, setOpen] = React.useState(false);
  const [currentMagazine, setCurrentMagazine] = React.useState({ magazine: { pdfURL: '' } });

  const magazinesToShow = magazines.filter(magazine => !magazine.active);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const onChangeMagazine = magazine => setCurrentMagazine(magazine);

  return (
    <Grid container spacing={2} style={{ marginTop: 50 }}>
      {
        isEmpty(magazinesToShow)
          ? (
            <Grid item md={12} xs={12}>
              <Card className={classes.emptyPostContainerCard}>
                <CardContent>
                  <p>
                    {t('No hay revistas con publicaciones para mostrar.')}
                  </p>
                </CardContent>
              </Card>
            </Grid>
          )
          : (
            magazinesToShow.map(magazine => (
              <Grid item md={4} spacing={2}>
                <MagazineSlider magazine={magazine} onOpen={onOpen} onChangeMagazine={onChangeMagazine} />
              </Grid>
            ))
          )
      }
      <MagazinePreview open={open} onClose={onClose} magazine={currentMagazine} />
    </Grid>
  );
};

export default withTranslation()(MagazineView);
