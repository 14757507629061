import React, { useState } from 'react';
import { Button, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Grow } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import ProfilePic from '../../users/ProfilePic';
import { logout } from '../../../actions';
import useStyles from '../../../theme/Styles';

const UserMenu = ({ user, t, history, currentCode, logoutAction }) => {
  const classes = useStyles()();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleToggle = () => setOpen(!open);

  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleViewProfileClick = () => {
    history.push(`/app/g/${currentCode}/profile/${user.uid}`);
    setOpen(false);
  };

  const handleEditProfileClick = () => {
    history.push(`/app/g/${currentCode}/configuration/edit_info`);
    setOpen(false);
  };

  const handleCloseSessionClick = () => {
    logoutAction();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span className={classes.hideOnMobile}>
        <ProfilePic containerStyle={{ marginLeft: 15 }} />
      </span>
      <Button
        color="inherit"
        onClick={handleToggle}
        buttonRef={(node) => {
          setAnchorEl(node);
        }}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        {user.name}
        <ArrowDropDown className={classes.iconRight} />
      </Button>
      <Popper open={open} anchorEl={anchorEl} transition disablePortal className={classes.navbarDropdown}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'top' : 'bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleViewProfileClick}>{t('Ver Perfil')}</MenuItem>
                  <MenuItem onClick={handleEditProfileClick}>{t('Editar Perfil')}</MenuItem>
                  <MenuItem onClick={handleCloseSessionClick}>{t('Cerrar Sesion')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  currentCode: state.groups.current.info.groupCode,
});

const mapDispatchToProps = {
  logoutAction: logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserMenu)));
