import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch, Route, Link, withRouter } from 'react-router-dom';
import { Tabs, Tab, Typography } from '@material-ui/core';
import useStyles from '../../../theme/Styles';
import PurchaseToSend from '../admin_purchases/PurchaseToSend';
import PurchaseSent from '../admin_purchases/PurchaseSent';
import MagazineCurrent from './MagazineCurrent';
import MagazinePast from './MagazinePast';

const AdminMagazines = ({ t, location }) => {
  const classes = useStyles()();

  let newPathName = '/app/administration/magazines/to_send';
  if (location.pathname.includes('/app/administration/magazines/sended')) {
    newPathName = '/app/administration/magazines/sended';
  }
  if (location.pathname.includes('/app/administration/magazines/current')) {
    newPathName = '/app/administration/magazines/current';
  }
  if (location.pathname.includes('/app/administration/magazines/past')) {
    newPathName = '/app/administration/magazines/past';
  }

  return (
    <div className={classes.verticalTabsAdminContainer}>
      <Tabs
        value={newPathName}
        classes={{
          indicator: classes.verticalTabsAdminTabIndicator,
          flexContainer: classes.verticalTabsAdminFlex,
        }}
        className={classes.verticalTabsAdmin}
      >
        <Tab
          label={t('Para Enviar')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazines/to_send"
          value="/app/administration/magazines/to_send"
        />
        <Tab
          label={t('Enviados')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazines/sended"
          value="/app/administration/magazines/sended"
        />
        <Tab
          label={t('Activos')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazines/current"
          value="/app/administration/magazines/current"
        />
        <Tab
          label={t('Pasados')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazines/past"
          value="/app/administration/magazines/past"
        />
      </Tabs>
      <div className={classes.adminInfoContainer}>
        <TabContainer>
          <Switch>
            <Route
              path="/app/administration/magazines/to_send"
              component={() => <PurchaseToSend />}
            />
            <Route
              path="/app/administration/magazines/sended"
              component={() => <PurchaseSent />}
            />
            <Route
              path="/app/administration/magazines/current"
              component={() => <MagazineCurrent />}
            />
            <Route
              path="/app/administration/magazines/past"
              component={() => <MagazinePast />}
            />
            <Redirect
              exact
              from="/app/administration/magazines"
              to="/app/administration/magazines/to_send"
            />
            <Redirect
              exact
              from="/app/administration/magazines/*"
              to="/app/administration/magazines/to_send"
            />
          </Switch>
        </TabContainer>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(AdminMagazines));

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">
      { children }
    </Typography>
  );
}
