import firebase from 'firebase';

import {
  LOAD_DESIGN_START,
  LOAD_DESIGN_FAIL,
  LOAD_DESIGN_SUCCESS,
} from './types';


export const loadDesign = () => (
  (dispatch) => {
    try {
      dispatch({ type: LOAD_DESIGN_START });
      firebase.database().ref('/design').on('value', (snapshot) => {
        dispatch({ type: LOAD_DESIGN_SUCCESS, payload: { design: snapshot.val() } });
      });
    } catch (e) {
      dispatch({ type: LOAD_DESIGN_FAIL });
    }
  }
);

export const someMetohod = () => {};
