import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { openPostModal, closePostModal } from '../../actions';
import GridViewTitleDivider from './GridViewTitleDivider';
import PostModal from '../posts/PostModal';


const AlbumView = ({ magazines, postModalOpenedKey, openPostModalAction, closePostModalAction }) => (
  <Grid container>
    {magazines.map(magazine => (
      <Grid container>
        <Grid item xs={12}>
          <GridViewTitleDivider magazine={magazine} />
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4 }}>
            <Masonry>
              {magazine.posts.slice().reverse().map(post => (
                <>
                  <div
                    onClick={() => openPostModalAction(post.key)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      key={post.key}
                      src={post.photoURL}
                      style={{ width: '100%', display: 'block', padding: 2 }}
                    />
                  </div>

                  {postModalOpenedKey === post.key
                    && (
                    <PostModal
                      post={post}
                      groupCode={magazine.groupCode}
                      magazineId={magazine.key}
                      open={postModalOpenedKey === post.key}
                      onClose={closePostModalAction}
                    />
                    )}
                </>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </Grid>
      </Grid>
    ))}
  </Grid>
);

const mapStateToProps = state => ({
  postModalOpenedKey: state.groups.postModalOpened,
});

const mapDispatchToProps = {
  openPostModalAction: openPostModal,
  closePostModalAction: closePostModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlbumView);
