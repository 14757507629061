import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TextField, FormControl, Button, CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';
import { loadGroupInfo } from '../../../actions';

const GroupIdForm = ({ loadGroupInfoAction, loadError, isLoadingInfo, t }) => {
  const classes = useStyles()();
  const [groupId, setGroupId] = React.useState('');
  const [showError, setShowError] = React.useState(false);

  const handleGroupIdChange = (e) => {
    setShowError(false);
    setGroupId(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isEmpty(groupId)) {
      setShowError(true);
      loadGroupInfoAction(groupId);
    }
  };

  return (
    <div className={classes.ladingInputFormContainer}>
      <p className={classes.landingInputFormTitle}>
        {t('Unirme a un grupo')}
      </p>
      <FormControl className={classes.landingInputFormControl}>
        <TextField
          id="groupIdInput"
          label={t('Codigo Del Grupo')}
          value={groupId}
          onChange={(handleGroupIdChange)}
          margin="normal"
          className={classes.landingInputField}
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      <Button
        className={classes.landingInputButton}
        color="inherit"
        variant="outlined"
        onClick={() => {
          setShowError(true);
          loadGroupInfoAction(groupId);
        }}
        disabled={isEmpty(groupId) || isLoadingInfo}
      >
        {isLoadingInfo && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
        {t('Unirme al grupo')}
      </Button>
      {loadError && showError && <div className={classes.landingInputFormError}>{t('El codigo es incorrecto')}</div>}
    </div>
  );
};

const mapDispatchToProps = {
  loadGroupInfoAction: loadGroupInfo,
};

const mapStateToProps = state => ({
  loadError: state.groups.current.loadError,
  isLoadingInfo: state.groups.current.isLoadingInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupIdForm));
