import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, DialogActions, Button,
  FormControl, TextField, CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useStyles from '../../theme/Styles';
import { joinNewGroup, loadGroupInfo, loadGroupUsers, loadMagazines } from '../../actions';

const JoinGroupModal = ({ open, onClose, t, history, userInfo, joinNewGroupAction, joinNewGroupInfo,
  loadGroupInfoAction, loadGroupUsersAction, loadMagazinesAction }) => {
  const classes = useStyles()();
  const [groupId, setGroupId] = React.useState('');
  const [showError, setShowError] = React.useState(false);

  const handleGroupIdChange = (e) => {
    setShowError(false);
    setGroupId(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isEmpty(groupId)) {
      sentJoinNewGroup();
    }
  };

  const sentJoinNewGroup = () => {
    setShowError(true);
    const userData = {
      uid: userInfo.uid,
      name: userInfo.name,
      lastname: userInfo.lastname,
      displayName: userInfo.displayName,
      email: userInfo.email,
      stripeId: userInfo.stripeId || null,
      stripeSubscriptions: userInfo.stripeSubscriptions || null,
      color: userInfo.color,
      role: 'publisher',
    };
    const joinGroupSuccess = () => {
      loadGroupInfoAction(groupId);
      loadGroupUsersAction(groupId);
      loadMagazinesAction(groupId);
      history.push(`/app/g/${groupId}`);
      onClose();
    };
    joinNewGroupAction(userData, groupId, joinGroupSuccess);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="post-dialog"
    >
      <DialogContent>
        <div className={classes.ladingInputFormContainer}>
          <p className={classes.landingInputFormTitle}>
            {t('Unirme a un grupo')}
          </p>
          <FormControl className={classes.landingInputFormControl}>
            <TextField
              id="groupIdInput"
              label={t('Codigo Del Grupo')}
              value={groupId}
              onChange={(handleGroupIdChange)}
              margin="normal"
              className={classes.landingInputField}
              onKeyPress={handleKeyPress}
            />
          </FormControl>
          <Button
            className={classes.landingInputButton}
            color="inherit"
            variant="outlined"
            onClick={() => {
              sentJoinNewGroup();
            }}
            disabled={isEmpty(groupId) || joinNewGroupInfo.isLoading}
          >
            {joinNewGroupInfo.isLoading && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
            {t('Unirme al grupo')}
          </Button>
          {showError
          && joinNewGroupInfo.errorMessage
          && <div className={classes.landingInputFormError}>{joinNewGroupInfo.errorMessage}</div>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('Cerrar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  joinNewGroupAction: joinNewGroup,
  loadGroupInfoAction: loadGroupInfo,
  loadGroupUsersAction: loadGroupUsers,
  loadMagazinesAction: loadMagazines,
};

const mapStateToProps = state => ({
  joinNewGroupInfo: state.user.joinNewGroup,
  userInfo: state.user.info,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JoinGroupModal)));
