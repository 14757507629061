import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { TextField, FormControl, Button, CircularProgress } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import useStyles from '../../theme/Styles';

import { login } from '../../actions';

const Login = ({ errorCode, errorMessage, isLoginLoading, loginAction, t, location }) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const classes = useStyles()();
  const redirectUrl = new URLSearchParams(location.search).get('redirect_to');
  const handleEmailChange = (e) => {
    setShowError(false);
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setShowError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    setShowError(true);
    if (!isEmpty(email) && !isEmpty(password)) {
      loginAction(email, password, redirectUrl);
    }
    e.preventDefault();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isEmpty(email) && !isEmpty(password)) {
      setShowError(true);
      loginAction(email, password, redirectUrl);
    }
  };

  return (
    <div className={classes.ladingInputFormContainer}>
      <p className={classes.landingInputFormTitle}>{t('Iniciar Sesión')}</p>
      <FormControl className={classes.landingInputFormControl}>
        <TextField
          id="emailInput"
          label={t('Email')}
          value={email}
          onChange={handleEmailChange}
          margin="normal"
          className={classes.landingInputField}
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      <FormControl className={classes.landingInputFormControl}>
        <TextField
          id="passwordInput"
          type="password"
          label={t('Contraseña')}
          value={password}
          onChange={handlePasswordChange}
          margin="normal"
          className={classes.landingInputField}
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      <Link to="/auth/recovery_password" className={classes.textLoginRecovery}>{t('Olvidaste tu contraseña?')}</Link>
      <Button
        className={classes.landingInputButton}
        color="inherit"
        variant="outlined"
        disabled={isLoginLoading || isEmpty(email) || isEmpty(password)}
        onClick={handleSubmit}
      >
        { isLoginLoading && <CircularProgress size={20} className={classes.landingButtonProgress} />}
        {t('Iniciar sesión')}
      </Button>
      {!isEmpty(errorCode) && showError && <div className={classes.landingInputFormError}>{errorMessage}</div>}
    </div>
  );
};

const mapStateToProps = state => ({
  errorCode: state.user.errorCode,
  errorMessage: state.user.errorMessage,
  isLoginLoading: state.user.isLoginLoading,
});

const mapDispatchToProps = {
  loginAction: login,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login)));
