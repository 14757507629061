import {
  USER_LOGGED_IN,
  LOAD_GROUP_INFO_START,
  LOAD_GROUP_INFO_SUCCESS,
  LOAD_GROUP_INFO_FAILURE,
  LOAD_GROUP_USERS_START,
  LOAD_GROUP_USERS_SUCCESS,
  POST_INSERT_START,
  POST_INSERT_SUCCEESS,
  POST_INSERT_FAILURE,
  POST_DELETE_START,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAILURE,
  POST_EDIT_START,
  POST_EDIT_SUCCESS,
  POST_EDIT_FAILURE,
  CLEAR_GROUP_INFO_START,
  GROUP_CREATE_START,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAILURE,
  GROUP_UPDATE_RESET,
  GROUP_UPDATE_START,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAILURE,
  GROUP_CREATE_INIT,
  LOAD_ALL_GROUPS_START,
  LOAD_ALL_GROUPS_SUCCESS,
  LOAD_ALL_GROUPS_FAIL,
  POST_OPEN_MODAL,
  CLOSE_POST_MODAL,
} from '../actions/types';

const INITIAL_STATE = {
  userGroups: [],
  postModalOpened: false,
  current: {
    info: {},
    users: [],
    loadError: false,
    isLoadingInfo: false,
    isLoadingUsers: false,
  },
  newGroup: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
  insertPost: {
    isLoading: false,
    uploadingPost: { body: '' },
    errorMessage: '',
    succeeded: false,
  },
  deletePost: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
  editPost: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
  allGroups: {
    isLoading: false,
    error: false,
    succeeded: false,
    data: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case USER_LOGGED_IN:
      return { ...state, userGroups: payload.groups };
    case LOAD_GROUP_INFO_START:
      return { ...state, current: { ...state.current, info: {}, isLoadingInfo: true, loadError: false } };
    case LOAD_GROUP_INFO_SUCCESS:
      return { ...state,
        current: { ...state.current, info: { ...payload.groupInfo }, isLoadingInfo: false } };
    case LOAD_GROUP_INFO_FAILURE:
      return { ...state, current: { ...state.current, isLoadingInfo: false, loadError: true } };
    case LOAD_GROUP_USERS_START:
      return { ...state, current: { ...state.current, users: [], isLoadingUsers: true } };
    case LOAD_GROUP_USERS_SUCCESS:
      return { ...state, current: { ...state.current, users: payload.users, isLoadingUsers: false } };
    case CLEAR_GROUP_INFO_START:
      return { ...state, current: { ...state.current, info: [] } };
    case POST_INSERT_START:
      return { ...state,
        insertPost: { ...state.insertPost,
          isLoading: true,
          errorMessage: '',
          succeeded: false,
          uploadingPost: payload.post } };
    case POST_INSERT_SUCCEESS:
      return { ...state,
        insertPost: { ...state.insertPost,
          isLoading: false,
          errorMessage: '',
          succeeded: true,
          uploadingPost: {} } };
    case POST_INSERT_FAILURE:
      return { ...state,
        insertPost: { ...state.insertPost,
          isLoading: false,
          errorMessage: payload.message,
          succeeded: false } };
    case POST_DELETE_START:
      return { ...state,
        deletePost: { ...state.deletePost,
          isLoading: true,
          errorMessage: '',
          succeeded: false } };
    case POST_DELETE_SUCCESS:
      return { ...state,
        deletePost: { ...state.deletePost,
          isLoading: false,
          errorMessage: '',
          succeeded: true,
          uploadingPost: {} } };
    case POST_DELETE_FAILURE:
      return { ...state,
        deletePost: { ...state.deletePost,
          isLoading: false,
          errorMessage: payload.message,
          succeeded: false } };
    case POST_EDIT_START:
      return { ...state,
        editPost: { ...state.editPost,
          isLoading: true,
          errorMessage: '',
          succeeded: false } };
    case POST_EDIT_SUCCESS:
      return { ...state,
        editPost: { ...state.editPost,
          isLoading: false,
          errorMessage: '',
          succeeded: true,
          uploadingPost: {} } };
    case POST_EDIT_FAILURE:
      return { ...state,
        editPost: { ...state.editPost,
          isLoading: false,
          errorMessage: payload.message,
          succeeded: false } };
    case GROUP_CREATE_INIT:
      return { ...state,
        newGroup: { isLoading: false,
          errorMessage: '',
          succeeded: false } };
    case GROUP_CREATE_START:
      return { ...state,
        newGroup: { isLoading: true,
          errorMessage: '',
          succeeded: false } };
    case GROUP_CREATE_SUCCESS:
      return { ...state,
        newGroup: { isLoading: false,
          errorMessage: '',
          succeeded: true } };
    case GROUP_CREATE_FAILURE:
      return { ...state,
        newGroup: { isLoading: false,
          errorMessage: payload.message,
          succeeded: false } };
    case GROUP_UPDATE_RESET:
      return { ...state,
        newGroup: { isLoading: false,
          errorMessage: '',
          succeeded: false } };
    case GROUP_UPDATE_START:
      return { ...state,
        newGroup: { isLoading: true,
          errorMessage: '',
          succeeded: false } };
    case GROUP_UPDATE_SUCCESS:
      return { ...state,
        newGroup: { isLoading: false,
          errorMessage: '',
          succeeded: true },
        current: { ...state.current, info: { ...payload.info } } };
    case GROUP_UPDATE_FAILURE:
      return { ...state,
        newGroup: { isLoading: false,
          errorMessage: payload.message,
          succeeded: false } };
    case LOAD_ALL_GROUPS_START:
      return { ...state, allGroups: { ...state.allGroups, isLoading: true } };
    case LOAD_ALL_GROUPS_SUCCESS:
      return { ...state,
        allGroups: { ...state.allGroups,
          isLoading: false,
          succeeded: true,
          data: payload.groups } };
    case LOAD_ALL_GROUPS_FAIL:
      return { ...state, allGroups: { ...state.allGroups, isLoading: false, error: true } };
    case POST_OPEN_MODAL:
      return { ...state, postModalOpened: payload.key };
    case CLOSE_POST_MODAL:
      return { ...state, postModalOpened: false };
    default:
      return state;
  }
};
