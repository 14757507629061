import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import useStyles from '../../theme/Styles';

const DropZone = ({ onFileSelected, t }) => {
  const classes = useStyles()();
  const onDrop = (files) => { onFileSelected(files[0]); };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={clsx(classes.dropzoneContainer, isDragActive && classes.dropzoneActive)}
    >
      <input {...getInputProps()} />
      <div className={classes.dropzoneContent}>
        {isDragActive ? (
          <Typography variant="h5">
            {t('Suelta tu foto aquí')}
          </Typography>
        ) : (
          <div>
            <CloudUpload color="disabled" fontSize="large" className={classes.dropzoneIcon} />
            <div>
              <Typography variant="subtitle1" className={classes.dropzoneText}>
                {t('Arrastra aquí tu foto o')}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.dropzoneButton}
              >
                {t('Selecciona un archivo')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(DropZone);
