import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid, Slider } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { PhotoSizeSelectLarge, Panorama, Photo, PhotoAlbum, ZoomIn, ZoomOut } from '@material-ui/icons';
import Cropper from 'react-easy-crop';
import { withTranslation } from 'react-i18next';
import getCroppedImg from './helpers';
import useStyles from '../../../theme/Styles';

const aspectRatio = {
  landscape: 4 / 3,
  portrait: 3 / 4,
  square: 1,
};

const CropImage = ({ filePath, open, onCloseModal, onImageCropped, t, optionsaspect, ...other }) => {
  const classes = useStyles()();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [orientation, setOrientation] = useState(aspectRatio.landscape);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const optionsAspectShow = optionsaspect ? optionsaspect === 'true' : true;
  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsArg) => {
    setCroppedAreaPixels(croppedAreaPixelsArg);
  }, []);

  const getCroppedImage = async () => {
    const croppedImage = await getCroppedImg(filePath, croppedAreaPixels);
    let photoStyle;
    if (orientation === aspectRatio.landscape) photoStyle = 'landscape';
    if (orientation === aspectRatio.portrait) photoStyle = 'portrait';
    if (orientation === aspectRatio.square) photoStyle = 'square';
    onImageCropped(croppedImage, photoStyle);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      aria-labelledby="new-post-dialog"
      {...other}
    >
      <DialogTitle>
        <PhotoSizeSelectLarge className={classes.iconLeft} />
        {t('Recortar imagen')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.cropContainer}>
          <Cropper
            image={filePath}
            crop={crop}
            zoom={zoom}
            aspect={orientation}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <Grid container className={classes.cropActions}>
          <Grid item md={8} xs={12} className={classes.cropSliderContainer}>
            <IconButton
              onClick={() => setZoom(zoom - 0.25)}
              aria-label="Zoom Out"
            >
              <ZoomOut />
            </IconButton>
            <Slider
              className={classes.cropSlider}
              value={zoom}
              aria-labelledby="label"
              min={1}
              max={3}
              onChange={(e, value) => setZoom(value)}
            />
            <IconButton
              onClick={() => setZoom(zoom + 0.25)}
              aria-label="Zoom In"
            >
              <ZoomIn />
            </IconButton>
          </Grid>
          {optionsAspectShow && (
          <Grid item md={4} xs={12}>
            <div className={classes.cropOrientationBtns}>
              <ToggleButtonGroup value={orientation} exclusive onChange={(e, o) => setOrientation(o)}>
                <ToggleButton value={aspectRatio.landscape}>
                  <Panorama />
                </ToggleButton>
                <ToggleButton value={aspectRatio.portrait}>
                  <PhotoAlbum />
                </ToggleButton>
                <ToggleButton value={aspectRatio.square}>
                  <Photo />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>
          {t('Cancelar')}
        </Button>
        <Button onClick={getCroppedImage} variant="contained" color="primary" autoFocus>
          {t('Seleccionar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(CropImage);
