import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch, Route, Link, withRouter } from 'react-router-dom';
import { Tabs, Tab, Typography } from '@material-ui/core';
import AdminMagazines from './admin_magazines';
import AdminGroups from './admin_groups';
import AdminUsers from './AdminUsers';
import MagazineDesign from './admin_magazine_design';
import useStyles from '../../theme/Styles';

const AdminPage = ({ t, location }) => {
  const classes = useStyles()();

  let newPathName = '/app/administration/magazines';
  if (location.pathname.includes('/app/administration/groups')) {
    newPathName = '/app/administration/groups';
  }
  if (location.pathname.includes('/app/administration/users')) {
    newPathName = '/app/administration/users';
  }

  if (location.pathname.includes('/app/administration/magazine-design')) {
    newPathName = '/app/administration/magazine-design';
  }

  if (location.pathname.includes('/app/administration/groups')) {
    newPathName = '/app/administration/groups';
  }

  return (
    <div className={classes.verticalTabsAdminContainer}>
      <Tabs
        value={newPathName}
        classes={{
          indicator: classes.verticalTabsAdminTabIndicator,
          flexContainer: classes.verticalTabsAdminFlex,
        }}
        className={classes.verticalTabsAdmin}
      >
        <Tab
          label={t('Revistas')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazines"
          value="/app/administration/magazines"
        />
        <Tab
          label={t('Grupos')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/groups"
          value="/app/administration/groups"
        />
        {/* <Tab
          label={t('Usuarios')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/users"
          value="/app/administration/users"
        /> */}
        <Tab
          label={t('Diseno revista')}
          classes={{
            selected: classes.verticalTabsAdminTabSelected,
          }}
          component={Link}
          to="/app/administration/magazine-design"
          value="/app/administration/magazine-design"
        />
      </Tabs>
      <div className={classes.adminInfoContainer}>
        <TabContainer>
          <Switch>
            <Route
              path="/app/administration/magazines"
              component={() => <AdminMagazines />}
            />
            <Route
              path="/app/administration/groups"
              component={() => <AdminGroups />}
            />
            )
            <Route
              path="/app/administration/users"
              component={() => <AdminUsers />}
            />
            <Route
              path="/app/administration/magazine-design"
              component={() => <MagazineDesign />}
            />
            )
            <Redirect
              exact
              from="/app/administration"
              to="/app/administration/magazines"
            />
            <Redirect
              exact
              from="/app/administration/*"
              to="/app/administration/magazines"
            />
          </Switch>
        </TabContainer>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(AdminPage));

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">
      { children }
    </Typography>
  );
}
