import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography,
  CircularProgress, FormControl, InputLabel, TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AvatarChange from '../../config/config_profile/AvatarChange';
import useStyles from '../../../theme/Styles';
import { updateUserInfo } from '../../../actions';

const FirstLoginModal = ({ open, user, other, t,
  updateUserInfoAction, groupCode, updatedInfo, group }) => {
  const classes = useStyles()();
  const groupType = group.type;
  const { photoURL } = user;
  const [saved, setSaved] = React.useState(false);
  const [photoURLToUpload, setPhotoURLToUpload] = React.useState(isEmpty(photoURL) ? '' : photoURL);
  const [photoFileToUpload, setPhotoFileToUpload] = React.useState(null);
  const [birthday, setBirthday] = React.useState(user.birthday);
  const [showMessage, setShowMessage] = React.useState(false);

  const selectImageToUpload = (url, photoFile) => {
    setPhotoURLToUpload(url);
    setPhotoFileToUpload(photoFile);
  };

  const handleUpdateUser = () => {
    if (isEmpty(birthday) && group.type === 'family') {
      setShowMessage(true);
      return;
    }
    setSaved(true);
    if (photoURLToUpload) {
      const updatedUser = { ...user, photoURL: photoURLToUpload, birthday };
      updateUserInfoAction(updatedUser, photoFileToUpload, groupCode);
    } else {
      updateUserInfoAction({ ...user, birthday }, null, groupCode);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="post-dialog"
      onClose={() => {}}
      disableBackdropClick
      disableEscapeKeyDown
      {...other}
    >
      <DialogContent>
        <Typography
          variant="h6"
          className={classes.titleColor}
          style={{ textAlign: 'left' }}
        >
          {`${t('Bienvenido')} ${user.displayName}!`}
        </Typography>

        <div className={classes.marginTop}>
          <Typography
            variant="h6"
            className={classes.titleColor}
          >
            {t('Cambia tu foto de perfil')}
          </Typography>
          <Typography variant="body1" className={classes.titleColor}>
            {t('Puedes cambiarla en un futuro en')}
          :
          </Typography>
          <Typography variant="body1" className={classes.blueText}>
            {t('Configuracion > Perfil')}
          </Typography>
        </div>

        <div className={classes.bigMarginTop}>
          <AvatarChange
            user={user}
            photoURLToUpload={photoURLToUpload}
            selectImageToUpload={selectImageToUpload}
          />
        </div>

        { groupType === 'family'
          && (
          <FormControl className={classes.configInputFormControl}>
            <InputLabel htmlFor="birthday-input" className={classes.landingInputFieldLabel}>
              {birthday ? '' : t('Cumpleaños')}
            </InputLabel>
            <TextField
              id="birthday-input"
              label={birthday ? t('Cumpleaños') : ''}
              value={birthday}
              type="date"
              onChange={(e) => { setBirthday(e.target.value); setShowMessage(false); }}
              margin="normal"
              className={classes.landingInputField}
            />
          </FormControl>
          )}
        {showMessage
        && (
        <div className={classes.configInputFormError}>
          {t('Por favor ingresa tu cumpleaños')}
        </div>
        )}
      </DialogContent>
      <DialogActions>
        { (!saved || updatedInfo.isLoading)
          && (
          <Button
            onClick={handleUpdateUser}
            size="small"
            className={classes.configInputButton}
            color="inherit"
            variant="outlined"
            disabled={updatedInfo.isLoading}
          >
            { updatedInfo.isLoading && <CircularProgress size={20} className={classes.configButtonProgress} />}
            {t('Continuar')}
          </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  updateUserInfoAction: updateUserInfo,
};

const mapStateToProps = state => ({
  updatedInfo: state.user.updateInfo,
  group: state.groups.current.info,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FirstLoginModal));
