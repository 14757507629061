import {
  LOAD_DESIGN_START,
  LOAD_DESIGN_FAIL,
  LOAD_DESIGN_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
  curiosities: {},
  frontCoverImages: {},
  isLoading: false,
  error: false,
  succeeded: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case LOAD_DESIGN_START:
      return { ...state, isLoading: true };
    case LOAD_DESIGN_FAIL:
      return { ...state, isLoading: false, error: true };
    case LOAD_DESIGN_SUCCESS:
      return { ...state,
        isLoading: false,
        succeeded: true,
        curiosities: payload.design ? payload.design.curiosities : {},
        frontCoverImages: payload.design ? payload.design.frontCoverImages : {} };
    default:
      return state;
  }
};
