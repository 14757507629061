import React, { useState } from 'react';
import { Popper, Paper, ClickAwayListener, MenuList, MenuItem, Grow, Button } from '@material-ui/core';
import { ArrowDropDown, Language } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../actions';
import useStyles from '../../../theme/Styles';

const LanguageMenu = ({ t, changeLangAction, selectedLng, userId }) => {
  const classes = useStyles()();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleToggle = () => setOpen(!open);
  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handlechangeLangClick = (e, lng) => {
    setOpen(false);
    changeLangAction(userId, lng);
    e.preventDefault();
  };

  const renderMenuItem = (lang, text) => {
    const style = lang === selectedLng ? { fontWeight: 'bold' } : {};
    const check = lang === selectedLng ? '&check;&nbsp' : '';
    return (
      <MenuItem onClick={e => handlechangeLangClick(e, lang)} style={style}>
        <span dangerouslySetInnerHTML={{ __html: check }} />
        {text}
      </MenuItem>
    );
  };

  return (
    <React.Fragment>
      <Button
        aria-label="Language"
        color="inherit"
        onClick={handleToggle}
        buttonRef={(node) => {
          setAnchorEl(node);
        }}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        <Language
          className={classes.iconSmall}
        />
        {t(selectedLng.toUpperCase())}
        <ArrowDropDown />
      </Button>
      <Popper open={open} anchorEl={anchorEl} transition disablePortal className={classes.navbarDropdown}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'top' : 'bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {renderMenuItem('es', t('Español'))}
                  {renderMenuItem('en', t('Ingles'))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
const mapStateToProps = state => ({
  selectedLng: state.user.config.lang,
  userId: state.user.info.uid,
});
const mapDispatchToProps = {
  changeLangAction: changeLanguage,
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LanguageMenu));
