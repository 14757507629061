import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Drawer, List, ListItem, ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore, Language, Group, ExitToApp } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import { withRouter } from 'react-router';
import { logout, changeLanguage, loadGroupInfo, loadGroupUsers, loadMagazines, logoutAction } from '../../../actions';
import ProfilePic from '../../users/ProfilePic';
import useStyles from '../../../theme/Styles';

const DrawerMenu = ({ user, selectedLng, history, changeLangAction, userGroups, currentCode,
  loadGroupInfoAction, loadGroupUsersAction, loadMagazinesAction, logoutAction, t }) => {
  const classes = useStyles()();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showMenuUser, setShowMenuUser] = useState(false);
  const [showMenuLanguage, setShowMenuLanguage] = useState(false);
  const [showGroupList, setShowGroupList] = useState(false);

  const handleSelectOption = () => {
    setShowDrawer(false);
    setShowMenuUser(false);
    setShowMenuLanguage(false);
    setShowGroupList(false);
  };

  const openGroup = (codeGroup) => {
    handleSelectOption();
    history.push(`/app/g/${codeGroup}`);
    loadGroupInfoAction(codeGroup);
    loadGroupUsersAction(codeGroup);
    loadMagazinesAction(codeGroup);
  };

  return (
    <React.Fragment>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setShowDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={showDrawer} onClose={() => setShowDrawer(false)} classes={{ paper: classes.drawerColor }}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={() => setShowMenuUser(!showMenuUser)}>
            <ListItemIcon>
              <ProfilePic />
            </ListItemIcon>
            <ListItemText primary={user.name} />
            {showMenuUser ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
        <Collapse in={showMenuUser} timeout="auto" unmountOnExit>
          <List>
            <ListItem button onClick={() => { handleSelectOption(); }} className={classes.nestedMenu}>
              <ListItemText primary={t('Ver Perfil')} />
            </ListItem>
            <ListItem button onClick={() => { handleSelectOption(); }} className={classes.nestedMenu}>
              <ListItemText primary={t('Editar Perfil')} />
            </ListItem>
          </List>
        </Collapse>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={() => setShowMenuLanguage(!showMenuLanguage)}>
            <ListItemIcon>
              <Language className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={t('Lenguaje')} />
            {showMenuLanguage ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
        <Collapse in={showMenuLanguage} timeout="auto" unmountOnExit>
          <List>
            <ListItem
              button
              className={classes.nestedMenu}
              onClick={() => {
                changeLangAction(user.uid, 'es');
                handleSelectOption();
              }}
            >
              <ListItemText primary={t('Espanol')} />
              <ListItemIcon>
                {selectedLng === 'es' && <DoneIcon style={{ color: 'aqua' }} />}
              </ListItemIcon>
            </ListItem>
            <ListItem
              button
              className={classes.nestedMenu}
              onClick={() => {
                changeLangAction(user.uid, 'en');
                handleSelectOption();
              }}
            >
              <ListItemText primary={t('Ingles')} />
              <ListItemIcon>
                {selectedLng === 'en' && <DoneIcon style={{ color: 'aqua' }} />}
              </ListItemIcon>
            </ListItem>
          </List>
        </Collapse>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={() => setShowGroupList(!showGroupList)}>
            <ListItemIcon>
              <Group className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={t('Grupos')} />
            {showGroupList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
        <Collapse in={showGroupList} timeout="auto" unmountOnExit>
          <List>
            {userGroups.map(group => (
              <ListItem
                button
                className={classes.nestedMenu}
                onClick={
                  group.code !== currentCode ? () => openGroup(group.code) : () => handleSelectOption()
                  }
              >
                <ListItemText primary={group.name} />
                {group.code === currentCode && (
                  <ListItemIcon>
                    <DoneIcon style={{ color: 'aqua' }} />
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
          </List>
        </Collapse>
        <List>
          <ListItem button onClick={() => { logoutAction(); handleSelectOption(); }}>
            <ListItemIcon>
              <ExitToApp className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={t('Cerrar Sesion')} />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  currentCode: state.groups.current.info.groupCode,
  selectedLng: state.user.config.lang,
  userGroups: state.groups.userGroups,
});

const mapDispatchToProps = {
  logoutAction: logout,
  changeLangAction: changeLanguage,
  loadGroupInfoAction: loadGroupInfo,
  loadGroupUsersAction: loadGroupUsers,
  loadMagazinesAction: loadMagazines,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DrawerMenu)));
