import React, { useState } from 'react';
import { Cancel } from '@material-ui/icons';
import { isNil } from 'lodash';

import DropZone from '../../common/DropZone';
import CropImage from '../../common/crop/CropImage';
import useStyles from '../../../theme/Styles';

const NewPostPhoto = ({ onPhotoSelected }) => {
  const classes = useStyles()();
  const [filePath, setFilePath] = useState('');
  const [croppedPhoto, setCroppedPhoto] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOnFileSelected = (file) => {
    setFilePath(URL.createObjectURL(file));
    setIsOpenModal(true);
  };
  const onCloseModal = () => setIsOpenModal(false);
  const onImageCropped = (image, photoStyle) => {
    setCroppedPhoto(image);
    onPhotoSelected(image, photoStyle);
    setIsOpenModal(false);
  };

  return (
    <div className={classes.newPostPhotoContainer}>
      {isNil(croppedPhoto) ? (
        <DropZone onFileSelected={handleOnFileSelected} />
      ) : (
        <div className={classes.newPostImgPreviewContainer}>
          <img src={URL.createObjectURL(croppedPhoto)} alt="Preview" className={classes.newPostImgPreview} />
          <Cancel
            className={classes.newPostRemoveImgBtn}
            onClick={() => { setCroppedPhoto(null); onPhotoSelected(null); }}
          />
        </div>
      )}
      <CropImage
        filePath={filePath}
        open={isOpenModal}
        onCloseModal={onCloseModal}
        onImageCropped={onImageCropped}
      />
    </div>
  );
};

export default NewPostPhoto;
