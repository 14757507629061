import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { RadioGroup, Radio,
  FormControl, FormControlLabel, TextField,
  Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';

const GroupNameForm = ({ data }) => {
  const { t, isLoading,
    groupType, setGroupType,
    groupName, setGroupName,
    geriatricCode, setGeriatricCode,
    eventDate, setEventDate,
    getSteps, goNextStep } = data;
  const classes = useStyles()();

  const canSendForm = (
    !isEmpty(groupType)
      && !isEmpty(groupName)
      && !(groupType === 'geriatric' && isEmpty(geriatricCode))
      && !(groupType === 'event' && isEmpty(eventDate))
      && !isLoading
  );
  const handleSubmit = () => {
    if (canSendForm) {
      goNextStep();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && canSendForm) {
      goNextStep();
    }
  };

  return (
    <React.Fragment>
      <p className={classes.landingInputFormTitle}>{t('Datos del grupo')}</p>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Gender"
          name="groupType"
          className={classes.groupTypeRadioButton}
          value={groupType}
          onChange={(e) => { setGroupType(e.target.value); }}
        >
          <FormControlLabel
            value="family"
            control={<Radio color="primary" />}
            label={t('Familia')}
          />
          <FormControlLabel
            value="business"
            control={<Radio color="primary" />}
            label={t('Empresa')}
          />
          <FormControlLabel
            value="geriatric"
            control={<Radio color="primary" />}
            label={t('Asilo')}
          />
          <FormControlLabel
            value="event"
            control={<Radio color="primary" />}
            label={t('Evento')}
          />
        </RadioGroup>
        { groupType === 'geriatric'
        && (
        <TextField
          id="geriatricCodeInput"
          label={t('Codigo de Geriatrico')}
          value={geriatricCode}
          onChange={(e) => { setGeriatricCode(e.target.value); }}
          margin="normal"
          onKeyPress={handleKeyPress}
          className={classes.landingInputField}
        />
        )}
        { groupType === 'event'
        && (
        <TextField
          id="nameInput"
          label={eventDate ? t('Fecha de Evento') : ''}
          value={eventDate}
          type="date"
          onChange={(e) => { setEventDate(e.target.value); }}
          margin="normal"
          onKeyPress={handleKeyPress}
          className={classes.landingInputField}
        />
        )}
        <TextField
          id="nameInput"
          label={t('Nombre Grupo')}
          value={groupName}
          onChange={(e) => { setGroupName(e.target.value); }}
          margin="normal"
          onKeyPress={handleKeyPress}
          className={classes.landingInputField}
        />

        <Button
          className={classes.landingInputButton}
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!canSendForm}
        >
          {t('Continuar')}
        </Button>
      </FormControl>
      {getSteps()}
    </React.Fragment>
  );
};


export default connect(null, null)(withTranslation()(GroupNameForm));
