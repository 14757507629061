import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import ProfilePic from '../users/ProfilePic';
import DateFormatted from '../common/DateFormatted';
import useStyles from '../../theme/Styles';

export default ({ post, groupCode }) => {
  const classes = useStyles()();
  return (
    <div className={classes.postAuthorContainer}>
      <div className={classes.postAuthorProfilePic}>
        <ProfilePic user={post.user} />
      </div>
      <div className={classes.postAuthorNameContainer}>
        <Typography variant="body2" className={classes.postAuthorName}>
          <Link
            component={Link}
            to={`/app/g/${groupCode}/profile/${post.user.uid}`}
            style={{ color: 'black' }}
          >
            {post.user.displayName}
          </Link>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <DateFormatted date={post.createdAt} />
        </Typography>
      </div>
    </div>
  );
};
