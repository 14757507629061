import React, { useState } from 'react';
import { Grid, Typography, Avatar, Select, MenuItem, Button } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { changeRole, removeFromGroup } from '../../../actions';

import useStyles from '../../../theme/Styles';

const UserRow = ({
  user,
  groupCode,
  updatedInfo,
  deleteInfo,
  changeRoleAction,
  currentUser,
  removeFromGroupAction,
}) => {
  const classes = useStyles()();
  const [role, setRole] = useState(user.role || 'publisher');

  const UserProfilePic = ({ shortName, displayName, color, photoURL }) => (
    <React.Fragment>
      {!isEmpty(photoURL) ? (
        <Avatar alt={displayName} src={photoURL} style={{ width: '6vh', height: '6vh' }} />
      ) : (
        <Avatar
          style={{ backgroundColor: color, width: '6vh', height: '6vh' }}
        >
          {shortName}
        </Avatar>
      )}
    </React.Fragment>
  );

  const handleChange = (e) => {
    const newRole = e.target.value;
    changeRoleAction(user, groupCode, newRole);
    setRole(newRole);
  };

  const { displayName, color, photoURL } = user;
  const shortName = `${displayName[0]}${displayName.split(' ').length > 1 ? displayName.split(' ')[1][0] : ''}`;
  return (
    <Grid container style={{ width: '100%', marginBottom: '10px' }} key={displayName}>
      <Grid item xs={2}>
        <UserProfilePic
          displayName={displayName}
          color={color}
          photoURL={photoURL}
          shortName={shortName}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" className={classes.membersModalDisplayName}>
          {user.displayName}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {user.role === 'payer'
          ? <p>Payer</p>
          : (
            <Select
              disableUnderline
              value={role}
              style={{ textAlign: 'left', paddingTop: '1.5vh' }}
              onChange={e => handleChange(e)}
              disabled={updatedInfo.isLoading || user.uid === currentUser.uid}
            >
              <MenuItem value="viewer">Viewer</MenuItem>
              <MenuItem value="publisher">Publisher</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          )
        }
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={() => removeFromGroupAction(user, groupCode)}
          style={{ paddingTop: '1.8vh' }}
          disabled={deleteInfo.isLoading || user.uid === currentUser.uid || user.role === 'payer'}
        >
          <DeleteForever
            style={
              (deleteInfo.isLoading || user.uid === currentUser.uid || user.role === 'payer')
                ? { color: 'gray' }
                : { color: 'red' }
            }
          />
        </Button>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  changeRoleAction: changeRole,
  removeFromGroupAction: removeFromGroup,
};

const mapStateToProps = state => ({
  currentUser: state.user.info,
  updatedInfo: state.user.updateInfo,
  deleteInfo: state.user.deleteInfo,
  groupCode: state.groups.current.info.groupCode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserRow));
