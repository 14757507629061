import React from 'react';
import { Avatar } from '@material-ui/core';
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';

const ProfilePic = ({ user, currentUser, containerStyle, size = 40 }) => {
  const { photoURL, displayName, color } = isNil(user) ? currentUser : user;
  let shortName = '';
  if (!isNil(displayName)) {
    shortName = `${displayName[0]}${displayName.split(' ').length > 1 ? displayName.split(' ')[1][0] : ''}`;
  }

  return (
    <div style={{ verticalAlign: 'middle', display: 'inline-block', ...containerStyle }}>
      {!isEmpty(photoURL) ? (
        <Avatar alt={displayName} src={photoURL} style={{ width: size, height: size }} />
      ) : (
        <Avatar
          style={{ backgroundColor: color, width: size, height: size }}
        >
          <span style={{ fontSize: size / 2 }}>{shortName}</span>
        </Avatar>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.info,
});

export default connect(mapStateToProps)(ProfilePic);
