const groupTypes = {
  family: {
    name: 'Familia',
    plans: ['familyBimonthly', 'familyMonthly', 'familyBimonthlyAnual', 'familyMonthlyAnual'],
    steps: [1, 2, 3, 4],
  },
  business: {
    name: 'Negocio',
    plans: ['businessMonthly', 'businessYearly'],
    steps: [1, 2, 3, 4],
  },
  geriatric: {
    name: 'Asilo',
    plans: ['geriatricMonthly', 'geriatricYearly'],
    steps: [1, 2, 3],
  },
  event: {
    name: 'Evento',
    plans: ['event'],
    steps: [1, 2, 4],
  },
};

export default groupTypes;
