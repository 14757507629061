import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { isNil } from 'lodash';
import clsx from 'clsx';
import { Card, CardContent, Typography, Divider, Button, CircularProgress, CardActions } from '@material-ui/core';
import { CloudDownload, Check, CancelOutlined } from '@material-ui/icons';
import DateFormatted from '../../common/DateFormatted';
import { updatePurchaseData } from '../../../actions';
import useStyles from '../../../theme/Styles';

const PurchaseCard = ({ t, purchase, updatePurchaseDataAction, isLoading }) => {
  const classes = useStyles()();
  const createdDate = new Date(purchase.createdAt);
  const sentDate = new Date(purchase.sentAt);
  return (
    <Card className={classes.adminCard}>
      <CardContent>
        <div style={{ width: '100%' }}>
          <Typography
            variant="h5"
            className={classes.titleColor}
            style={{ textAlign: 'left' }}
          >
            {`${purchase.group.name} (${purchase.group.groupCode})`}
          </Typography>
          <Typography
            variant="h6"
            className={classes.titleColor}
            style={{ textAlign: 'left' }}
          >
            {`${t('Revista')} `}
            <DateFormatted date={purchase.magazine.endDate} format="shortWithYear" />
            {` (#${purchase.magazine.edition})`}
          </Typography>
        </div>
        <Divider className={classes.adminCardDivider} />
        <table>
          <tbody>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Fecha compra')}
                :
              </th>
              <td>
                {createdDate.toDateString()}
              </td>
            </tr>
            {purchase.sentAt && (
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Fecha envio')}
                :
              </th>
              <td>
                {sentDate.toDateString()}
              </td>
            </tr>
            )}
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Nombre')}
                :
              </th>
              <td>
                {`${purchase.user.displayName}`}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Email')}
                :
              </th>
              <td>
                {purchase.user.email}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Publicaciones')}
                :
              </th>
              <td>
                {purchase.magazine.postsCount}
              </td>
            </tr>
            <tr>
              <th colSpan="2"><Divider className={classes.adminCardDivider} /></th>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {`${t('Direccion')}1`}
                :
              </th>
              <td>
                {purchase.deliveryAddress.address}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {`${t('Direccion')}2`}
                :
              </th>
              <td>
                {purchase.deliveryAddress.address2}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Ciudad')}
                :
              </th>
              <td>
                {`${purchase.deliveryAddress.city}`}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Estado')}
                :
              </th>
              <td>
                {`${purchase.deliveryAddress.state}`}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {`${t('Codigo Zip')}`}
                :
              </th>
              <td>
                {purchase.deliveryAddress.zipCode}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Pais')}
                :
              </th>
              <td>
                {`${purchase.deliveryAddress.country}`}
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      {purchase.sent
        ? (
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              className={classes.adminCardActiveBadge}
              variant="contained"
              onClick={() => updatePurchaseDataAction(purchase.key, { sent: false, sentAt: DateTime.local().toISO() })}
            >
              <CancelOutlined className={clsx(classes.iconLeft, classes.iconSmall)} />
              {t('Deshacer Envio')}
              {isLoading && <CircularProgress />}
            </Button>
          </CardActions>
        ) : (
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              className={classes.adminCardActiveBadge}
              variant="contained"
              href={purchase.magazine.pdfURL}
              target="_blank"
              disabled={isNil(purchase.magazine.pdfURL)}
            >
              <CloudDownload className={clsx(classes.iconLeft, classes.iconSmall)} />
              {t('PDF')}
            </Button>
            <Button
              className={classes.adminCardActiveBadge}
              variant="contained"
              onClick={() => updatePurchaseDataAction(purchase.key, { sent: true, sentAt: DateTime.local().toISO() })}
            >
              <Check className={clsx(classes.iconLeft, classes.iconSmall)} />
              {t('Enviar')}
              {isLoading && <CircularProgress />}
            </Button>
          </CardActions>
        )}
    </Card>
  );
};

const mapStateToProps = state => ({
  isLoading: state.purchases.updateDataPurchase.isLoading,
});

const mapDispatchToProps = {
  updatePurchaseDataAction: updatePurchaseData,
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchaseCard));
