import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import useStyles from '../../../theme/Styles';
import GroupUserRow from './GroupUserRow';
import GroupInfoEdit from './GroupInfoEdit';
import GroupMagazines from './GroupMagazines';

const GroupView = ({ t, group }) => {
  const classes = useStyles()();
  let userGrid = null;
  if (group.users) {
    userGrid = Object.keys(group.users).map((index) => {
      const user = group.users[index];
      return (
        <GroupUserRow key={user.uid} user={user} groupCode={group.info.groupCode} />
      );
    });
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <p className={classes.configFormTitle}>
        {t('Información')}
      </p>
      <GroupInfoEdit group={group} />
      <p className={classes.configFormTitle}>
        {t('Revistas')}
      </p>
      <GroupMagazines group={group} />
      <p className={classes.configFormTitle}>
        {t('Usuarios')}
      </p>
      <Grid container className={classes.membersCongifContainer}>
        {userGrid || t('El grupo no tiene usuarios.')}
      </Grid>
    </div>
  );
};

export default withTranslation()(GroupView);
