import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';
import NewPhoto from '../../common/NewPhoto';
import { Images } from '../../../theme';


const AvatarGroup = ({ group, currentPhoto, selectImageToUpload, t }) => {
  const classes = useStyles()();
  const [changeImage, setChangeImage] = useState(false);

  const handlePhotoUpload = (p) => {
    if (p) selectImageToUpload(URL.createObjectURL(p), p);
    else selectImageToUpload(null, null);
  };

  return (
    <React.Fragment>
      { !changeImage && !isEmpty(currentPhoto) && (
        <div className={classes.newPostImgPreviewContainerCircle} onClick={() => setChangeImage(true)}>
          <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', position: 'absolute', marginLeft: 140 }}>
            <Edit />
            {t('Edit group image')}
          </Typography>
          <img
            alt={group.name}
            title={group.name}
            src={currentPhoto}
            style={{ width: '100%' }}
          />
        </div>
      )
      }
      { !changeImage && isEmpty(currentPhoto) && (
        <div className={classes.newPostImgPreviewContainerCircle} onClick={() => setChangeImage(true)}>
          <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', position: 'absolute', marginLeft: 140 }}>
            <Edit />
            {t('Edit group image')}
          </Typography>
          <img
            alt={group.name}
            title={group.name}
            src={Images.family}
            style={{ width: '100%' }}
          />
        </div>
      )
      }
      {changeImage && (
      <NewPhoto
        onPhotoSelected={p => handlePhotoUpload(p)}
        optionsAspect="false"
      />
      ) }
    </React.Fragment>
  );
};

export default withTranslation()(AvatarGroup);
