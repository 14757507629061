import firebase from 'firebase';
import {
  SET_NEW_DATA_PURCHASE,
  RESET_NEW_DATA_PURCHASE,
  LOAD_TO_SEND_PURCHASES_START,
  LOAD_TO_SEND_PURCHASES_SUCCESS,
  LOAD_TO_SEND_PURCHASES_FAILURE,
  LOAD_PURCHASES_SENDED_START,
  LOAD_PURCHASES_SENDED_SUCCESS,
  LOAD_PURCHASES_SENDED_FAILURE,
  UPDATE_PURCHASE_DATA_START,
  UPDATE_PURCHASE_DATA_SUCESS,
  UPDATE_PURCHASE_DATA_FAILURE,
} from './types';

export const insertPurchase = dataPurchase => (
  async (dispatch) => {
    firebase.database().ref('/purchases').push(dataPurchase);
    dispatch({ type: RESET_NEW_DATA_PURCHASE });
  }
);

export const loadNewPurchaseData = newData => (
  (dispatch) => {
    const newDataPurchase = {
      magazine: {
        pdfURL: newData.magazine.pdfURL ? newData.magazine.pdfURL : '',
        key: newData.magazine.key ? newData.magazine.key : '',
        edition: newData.magazine.edition ? newData.magazine.edition : '',
        endDate: newData.magazine.endDate ? newData.magazine.endDate : '',
        postsCount: newData.magazine.posts ? newData.magazine.posts.length : 0,
      },
      group: {
        groupCode: newData.groupInfo.groupCode ? newData.groupInfo.groupCode : '',
        name: newData.groupInfo.name ? newData.groupInfo.name : '',
      },
      user: {
        displayName: newData.user.displayName ? newData.user.displayName : '',
        lastname: newData.user.lastname ? newData.user.lastname : '',
        email: newData.user.email ? newData.user.email : '',
        uid: newData.user.uid ? newData.user.uid : '',
      },
    };
    dispatch({ type: SET_NEW_DATA_PURCHASE, payload: newDataPurchase });
  }
);

export const updatePurchaseData = (purchaseId, data) => (
  async (dispatch) => {
    dispatch({ type: UPDATE_PURCHASE_DATA_START });
    try {
      firebase.database().ref(`/purchases/${purchaseId}`).update(data);
      dispatch({ type: UPDATE_PURCHASE_DATA_SUCESS });
    } catch (e) {
      dispatch({ type: UPDATE_PURCHASE_DATA_FAILURE });
    }
  }
);

export const loadPurchasesSended = () => (
  (dispatch) => {
    try {
      dispatch({ type: LOAD_PURCHASES_SENDED_START });
      firebase.database().ref('/purchases').orderByChild('sent').equalTo(true)
        .on('value', (snapshot) => {
          const purchases = [];
          snapshot.forEach((snap) => {
            purchases.push({ ...snap.val(), key: snap.key });
          });
          purchases.reverse();
          dispatch({ type: LOAD_PURCHASES_SENDED_SUCCESS, payload: { purchases } });
        });
    } catch {
      dispatch({ type: LOAD_PURCHASES_SENDED_FAILURE });
    }
  }
);

export const loadToSendPurchases = () => (
  (dispatch) => {
    try {
      dispatch({ type: LOAD_TO_SEND_PURCHASES_START });
      firebase.database().ref('/purchases').orderByChild('sent').equalTo(false)
        .on('value', (snapshot) => {
          const purchases = [];
          snapshot.forEach((snap) => {
            purchases.push({ ...snap.val(), key: snap.key });
          });
          purchases.reverse();
          dispatch({ type: LOAD_TO_SEND_PURCHASES_SUCCESS, payload: { purchases } });
        });
    } catch {
      dispatch({ type: LOAD_TO_SEND_PURCHASES_FAILURE });
    }
  }
);
