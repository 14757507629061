import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, InputLabel,
  FormControl, TextField, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { updateGroup, resetUpdateGroupState } from '../../actions';
import planTypes from '../common/planTypes';
import groupTypes from '../common/groupTypes';
import useStyles from '../../theme/Styles';
import GroupPlanForm from '../groups/create_group/GroupPlanForm';
import AvatarGroup from './config_group/AvatarGroup';

const GroupConfig = ({ t, groupInfo, isLoading, succeeded, errorMessage, updateGroupAction, currentUserRole,
  resetUpdateGroupStateAction }) => {
  const classes = useStyles()();
  const [groupType, setNewGroupType] = React.useState(groupInfo.type); // family, business, event
  const [groupName, setGroupName] = React.useState(groupInfo.name);
  const [groupLanguage, setGroupLanguage] = React.useState(groupInfo.language || 'es');
  const [groupPlan, setGroupPlan] = React.useState(groupInfo.planId); // basic, home, pro
  const [subscriptionStatus, setSubscriptionStatus] = React.useState(groupInfo.subscriptionStatus);
  const [defaultRole, setDefaultRole] = React.useState(
    groupInfo.defaultRole || 'publisher',
  ); // viewer, publisher, admin, payer
  const [open, setOpen] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(true);
  const { photoURL } = groupInfo;
  const [photoURLToUpload, setPhotoURLToUpload] = React.useState(isEmpty(photoURL) ? '' : photoURL);
  const [photoFileToUpload, setPhotoFileToUpload] = React.useState(null);

  const selectImageToUpload = (url, photoFile) => {
    if (url && photoFile) {
      setPhotoURLToUpload(url);
      setPhotoFileToUpload(photoFile);
    } else {
      setPhotoFileToUpload(null);
      setPhotoURLToUpload(isEmpty(photoURL) ? '' : photoURL);
    }
  };

  const groupPlanData = {
    t,
    getSteps: () => {},
    groupType,
    handlePlanSubmit: (plan) => { setGroupPlan(plan); setOpen(false); },
    goPreviousStep: () => { setOpen(false); },
  };

  const setGroupType = (newGroupType) => {
    const { plans } = groupTypes[newGroupType];
    if (plans.length === 1) setGroupPlan(plans[0]);
    setNewGroupType(newGroupType);
  };

  const handleSubmit = () => {
    // updategroup actions
    setShowMessage(true);
    const plan = planTypes[groupPlan] || { type: groupInfo.type, postsLimit: groupInfo.postsLimit };
    updateGroupAction({ ...groupInfo,
      type: groupType,
      name: groupName,
      subscriptionType: plan.type,
      postsLimit: plan.postsLimit,
      planId: groupPlan,
      language: groupLanguage,
      subscriptionStatus,
      defaultRole }, photoFileToUpload);
  };

  const canSendForm = (
    !isEmpty(groupType)
      && !isEmpty(groupName)
      && !isLoading
  );

  if (succeeded && showMessage) {
    setTimeout(() => resetUpdateGroupStateAction(),
      4000);
  }

  return (
    <div className={classes.configCardContainer}>
      <p className={classes.configFormTitle}>
        {t('Editar Grupo')}
      </p>
      <AvatarGroup group={groupInfo} currentPhoto={photoURLToUpload} selectImageToUpload={selectImageToUpload} />
      <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
        <TextField
          id="nameInput"
          label={t('Nombre del Grupo')}
          value={groupName}
          onChange={(e) => { setShowMessage(false); setGroupName(e.target.value); }}
          margin="normal"
          className={classes.landingInputField}
        />
      </FormControl>

      {currentUserRole === 'payer' && (
        <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
          <InputLabel htmlFor="age-customized-select">{t('Lenguaje del grupo')}</InputLabel>
          <Select
            value={groupLanguage}
            onChange={(e) => { setShowMessage(false); setGroupLanguage(e.target.value); }}
          >
            <MenuItem value="es">{t('Español')}</MenuItem>
            <MenuItem value="en">{t('Ingles')}</MenuItem>
          </Select>
        </FormControl>
      )}
      <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
        <InputLabel htmlFor="age-customized-select">{t('Rol para nuevos miembros')}</InputLabel>
        <Select
          value={defaultRole}
          onChange={(e) => { setShowMessage(false); setDefaultRole(e.target.value); }}
        >
          <MenuItem value="viewer">{t('Viewer')}</MenuItem>
          <MenuItem value="publisher">{t('Publisher')}</MenuItem>
          <MenuItem value="admin">{t('Admin')}</MenuItem>
          <MenuItem value="payer">{t('Payer')}</MenuItem>
        </Select>
      </FormControl>

      {currentUserRole === 'payer' && (
        <>
          <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
            <InputLabel htmlFor="age-customized-select">{t('Estado Suscripcion')}</InputLabel>
            <Select
              value={subscriptionStatus}
              onChange={(e) => { setShowMessage(false); setSubscriptionStatus(e.target.value); }}
            >
              <MenuItem value="true">{t('Activo')}</MenuItem>
              <MenuItem value="false">{t('Inactivo')}</MenuItem>
            </Select>
          </FormControl>
        </>
      )}

      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
      >
        <DialogContent>
          <GroupPlanForm data={groupPlanData} />
        </DialogContent>
      </Dialog>
      {currentUserRole === 'payer' && groupType !== 'event' && false && (
      <FormControl component="fieldset" className={classes.landingInputFormControlDialog}>
        <Button
          className={classes.configPlanChange}
          color="default"
          variant="contained"
          onClick={() => { setShowMessage(false); setOpen(true); }}
        >
          {t('Cambiar de Plan')}
        </Button>
      </FormControl>
      )}
      <div className={classes.landingUserActions}>
        <Button
          className={classes.configInputButton}
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!canSendForm}
        >
          { isLoading && <CircularProgress size={20} className={classes.configButtonProgress} />}
          {t('Guardar Cambios')}
        </Button>
      </div>
      {!isEmpty(errorMessage)
        && showMessage
        && (
        <div className={classes.configInputFormError}>
          {errorMessage}
        </div>
        )}
      {succeeded
          && showMessage
          && (
          <div className={classes.configInputFormSuccess}>
            {t('Los datos fueron actualizados correctamente')}
          </div>
          )}
    </div>
  );
};

const mapStateToProps = state => ({
  groupInfo: state.groups.current.info,
  isLoading: state.groups.newGroup.isLoading,
  errorMessage: state.groups.newGroup.errorMessage,
  succeeded: state.groups.newGroup.succeeded,
});

const mapDispatchToProps = {
  updateGroupAction: updateGroup,
  resetUpdateGroupStateAction: resetUpdateGroupState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(GroupConfig));
