import React from 'react';
import { AppBar, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Images } from '../../../theme';
import useStyles from '../../../theme/Styles';
import UserMenu from './UserMenu';
import GroupMenu from './GroupMenu';
import LanguageMenu from './LanguageMenu';
import DrawerMenu from './DrawerMenu';

const Header = () => {
  const classes = useStyles()();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.header}>
      <AppBar position="static">
        <div className={classes.content}>
          <Toolbar className={classes.toolbar}>
            {matches && <DrawerMenu /> }
            <div className={classes.grow}>
              <img src={Images.logo} className={classes.headerLogo} />
            </div>
            {!matches && (
            <div>
              <LanguageMenu />
              <GroupMenu />
              <UserMenu />
            </div>
            )}
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

export default (withTranslation()(Header));
