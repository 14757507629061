import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import PostLikeFooter from './like/PostLikeFooter';
import PostModal from './PostModal';
import ProfilePic from '../users/ProfilePic';
import DateFormatted from '../common/DateFormatted';
import PostActionButton from './PostActions/PostActionButton';
import useStyles from '../../theme/Styles';
import { openPostModal, closePostModal } from '../../actions';

const PostNewsFeed = ({ post, groupCode, magazineId, users, currentUid,
  openPostModalAction, postModalOpenedKey, closePostModalAction }) => {
  const classes = useStyles()();
  const onClosePostModal = () => closePostModalAction();

  const currentUser = users.find(x => x.uid === currentUid);
  let role = '';
  if (currentUser) role = currentUser.role || '';
  return (
    <div>
      <Card>
        <CardHeader
          avatar={<ProfilePic user={post.user} />}
          title={(
            <Link to={`/app/g/${groupCode}/profile/${post.user.uid}`} style={{ color: 'black' }}>
              {post.user.displayName}
            </Link>
          )}
          titleTypographyProps={{ noWrap: true }}
          subheader={<DateFormatted date={post.createdAt} />}
          subheaderTypographyProps={{ noWrap: true }}
          action={((currentUid === post.user.uid) || (role === 'payer') || (role === 'admin'))
            && (
            <PostActionButton post={post} magazineId={magazineId} />
            )}
        />
        <CardContent>
          {!isEmpty(post.body) && (
            <Typography variant="body1" className={classes.marginBottom}>
              {post.body}
            </Typography>
          )}
          <div onClick={() => openPostModalAction(post.key)}>
            <img
              src={post.photoURL}
              style={{ width: '100%', cursor: 'pointer' }}
            />
          </div>
          <PostLikeFooter post={post} groupCode={groupCode} magazineId={magazineId} />
        </CardContent>
      </Card>
      <PostModal
        post={post}
        groupCode={groupCode}
        magazineId={magazineId}
        open={post.key === postModalOpenedKey}
        onClose={onClosePostModal}
      />
    </div>
  );
};


const mapStateToProps = state => ({
  users: state.groups.current.users,
  currentUid: state.user.info.uid,
  postModalOpenedKey: state.groups.postModalOpened,
});

const mapDispatchToProps = {
  openPostModalAction: openPostModal,
  closePostModalAction: closePostModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostNewsFeed);
