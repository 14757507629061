import React from 'react';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CheckCircle } from '@material-ui/icons';
import useStyles from '../../../theme/Styles';

const PaymentConfirmation = ({ t }) => {
  const classes = useStyles()();

  return (
    <>
      <IconButton aria-label="CheckCircle">
        <CheckCircle className={classes.magazineBuySuccessIcon} />
      </IconButton>
      <h3 style={{ textAlign: 'center' }}>{t('Compra exitosa. Enviaremos tu revista impresa a tu dirección!')}</h3>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
});

export default connect(mapStateToProps, null)(withTranslation()(PaymentConfirmation));
