import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import useStyles from '../../../theme/Styles';
import { updateCard } from '../../../actions';


const NewCard = ({ user, t, stripe, updateCardAction, groupCode, isLoading, errorMessage }) => {
  const classes = useStyles()();
  const [cardError, setCardError] = useState('');
  const [loading, setLoading] = useState(false);

  const buyMagazine = () => {
    setCardError('');
    setLoading(true);

    stripe.createSource({
      type: 'card',
    }).then((response) => {
      if (!isEmpty(response.error)) { setCardError(response.error.message); } else {
        updateCardAction(user, response.source.id, groupCode);
      }
      setLoading(false);
    });
  };

  const handleChange = () => {
    setCardError('');
  };

  const spinning = isLoading || loading;
  const errorShowed = errorMessage || cardError;

  return (
    <>
      <p className={classes.landingInputFormTitle}>{t('Registrar tarjeta')}</p>
      <div className={classes.cardForm}>
        <CardElement
          hidePostalCode
          onChange={handleChange}

        />
      </div>
      <Button
        className={classes.landingUserButton}
        color="inherit"
        variant="outlined"
        onClick={buyMagazine}
        disabled={spinning}
      >
        {spinning && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
        {t('Registrar')}
      </Button>
      {!isEmpty(errorShowed)
        && <div className={classes.landingInputFormError}>{errorShowed}</div>
      }
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  groupCode: state.groups.current.info.groupCode,
  isLoading: state.user.updateInfo.isLoading,
  errorMessage: state.user.updateInfo.errorMessage,
});

const mapDispatchToProps = {
  updateCardAction: updateCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(injectStripe(NewCard)));
