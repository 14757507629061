export const LOAD_GROUP_INFO_START = 'LOAD_GROUP_INFO_START';
export const LOAD_GROUP_INFO_SUCCESS = 'LOAD_GROUP_INFO_SUCCESS';
export const LOAD_GROUP_INFO_FAILURE = 'LOAD_GROUP_INFO_FAILURE';
export const LOAD_GROUP_USERS_START = 'LOAD_GROUP_USERS_START';
export const LOAD_GROUP_USERS_SUCCESS = 'LOAD_GROUP_USERS_SUCCESS';
export const LOAD_GROUP_MAGAZINES_START = 'LOAD_GROUP_MAGAZINES_START';
export const LOAD_GROUP_MAGAZINES_SUCCESS = 'LOAD_GROUP_MAGAZINES_SUCCESS';
export const LOAD_GROUP_MAGAZINES_FAIL = 'LOAD_GROUP_MAGAZINES_FAIL';
export const LOAD_DESIGN_START = 'LOAD_DESIGN_START';
export const LOAD_DESIGN_SUCCESS = 'LOAD_DESIGN_SUCCESS';
export const LOAD_DESIGN_FAIL = 'LOAD_DESIGN_FAIL';
export const LOAD_ALL_GROUPS_START = 'LOAD_ALL_GROUPS_START';
export const LOAD_ALL_GROUPS_SUCCESS = 'LOAD_ALL_GROUPS_SUCCESS';
export const LOAD_ALL_GROUPS_FAIL = 'LOAD_ALL_GROUPS_FAIL';
export const POST_INSERT_START = 'POST_INSERT_START';
export const POST_INSERT_SUCCEESS = 'POST_INSERT_SUCCEESS';
export const POST_INSERT_FAILURE = 'POST_INSERT_FAILURE';
export const POST_DELETE_START = 'POST_DELETE_START';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const POST_DELETE_FAILURE = 'POST_DELETE_FAILURE';
export const POST_EDIT_START = 'POST_EDIT_START';
export const POST_EDIT_SUCCESS = 'POST_EDIT_SUCCESS';
export const POST_EDIT_FAILURE = 'POST_EDIT_FAILURE';
export const CLEAR_GROUP_INFO_START = 'CLEAR_GROUP_INFO_START';
export const GROUP_CREATE_START = 'GROUP_CREATE_START';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_FAILURE = 'GROUP_CREATE_FAILURE';
export const GROUP_UPDATE_RESET = 'GROUP_UPDATE_RESET';
export const GROUP_UPDATE_START = 'GROUP_UPDATE_START';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_FAILURE = 'GROUP_UPDATE_FAILURE';
export const GROUP_CREATE_INIT = 'GROUP_CREATE_INIT';
export const LOAD_ACTIVE_MAGAZINES_START = 'LOAD_ACTIVE_MAGAZINES_START';
export const LOAD_ACTIVE_MAGAZINES_FAIL = 'LOAD_ACTIVE_MAGAZINES_FAIL';
export const LOAD_ACTIVE_MAGAZINES_SUCCESS = 'LOAD_ACTIVE_MAGAZINES_SUCCESS';
export const LOAD_PAST_MAGAZINES_START = 'LOAD_PAST_MAGAZINES_START';
export const LOAD_PAST_MAGAZINES_FAIL = 'LOAD_PAST_MAGAZINES_FAIL';
export const LOAD_PAST_MAGAZINES_SUCCESS = 'LOAD_PAST_MAGAZINES_SUCCESS';
export const SET_BUYING_MAGAZINE_WITH_NEW_CARD = 'SET_BUYING_MAGAZINE_WITH_NEW_CARD';
export const POST_OPEN_MODAL = 'POST_OPEN_MODAL';
export const CLOSE_POST_MODAL = 'CLOSE_POST_MODAL';

export const USER_LOGIN_START = 'USER_LOGIN_START';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_SIGNUP_START = 'USER_SIGNUP_START';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE';
export const USER_LOGOUT_START = 'USER_LOGOUT_START';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_RECOVERY_PASSWORD_START = 'USER_RECOVERY_PASSWORD_START';
export const USER_RECOVERY_PASSWORD_SUCCESS = 'USER_RECOVERY_PASSWORD_SUCCESS';
export const USER_RECOVERY_PASSWORD_FAILURE = 'USER_RECOVERY_PASSWORD_FAILURE';
export const USER_DELETE_START = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_ERROR';
export const USER_UPDATE_INFO_RESET = 'USER_UPDATE_INFO_RESET';
export const USER_UPDATE_INFO_START = 'USER_UPDATE_INFO_START';
export const USER_UPDATE_INFO_SUCCEESS = 'USER_UPDATE_INFO_SUCCEESS';
export const USER_UPDATE_INFO_FAILURE = 'USER_UPDATE_INFO_FAILURE';
export const USER_JOIN_NEW_GROUP_START = 'USER_JOIN_NEW_GROUP_START';
export const USER_JOIN_NEW_GROUP_SUCCESS = 'USER_JOIN_NEW_GROUP_SUCCESS';
export const USER_JOIN_NEW_GROUP_FAILURE = 'USER_JOIN_NEW_GROUP_FAILURE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const USER_REDIRECT_AFTER_LOGIN = 'USER_REDIRECT_AFTER_LOGIN';

export const MAGAZINE_INSERT_START = 'MAGAZINE_INSERT_START';
export const MAGAZINE_INSERT_SUCCEESS = 'MAGAZINE_INSERT_SUCCEESS';
export const MAGAZINE_INSERT_FAILURE = 'MAGAZINE_INSERT_FAILURE';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_POSTS_START = 'GET_USER_POSTS_START';
export const GET_USER_POSTS_SUCCESS = 'GET_USER_POSTS_SUCCESS';
export const GET_USER_POSTS_FAILURE = 'GET_USER_POSTS_FAILURE';
// Purchases
export const SET_NEW_DATA_PURCHASE = 'SET_DATA_PURCHASE';
export const RESET_NEW_DATA_PURCHASE = 'RESET_NEW_DATA_PURCHASE';
export const LOAD_TO_SEND_PURCHASES_START = 'LOAD_TO_SEND_PURCHASES_START';
export const LOAD_TO_SEND_PURCHASES_SUCCESS = 'LOAD_TO_SEND_PURCHASES_SUCCESS';
export const LOAD_TO_SEND_PURCHASES_FAILURE = 'LOAD_TO_SEND_PURCHASES_FAILURE';
export const LOAD_PURCHASES_SENDED_START = 'LOAD_PURCHASES_SENDED_START';
export const LOAD_PURCHASES_SENDED_SUCCESS = 'LOAD_PURCHASES_SENDED_SUCCESS';
export const LOAD_PURCHASES_SENDED_FAILURE = 'LOAD_PURCHASES_SENDED_FAILURE';
export const UPDATE_PURCHASE_DATA_START = 'UPDATE_PURCHASE_DATA_START';
export const UPDATE_PURCHASE_DATA_SUCESS = 'UPDATE_PURCHASE_DATA_SUCESS';
export const UPDATE_PURCHASE_DATA_FAILURE = 'UPDATE_PURCHASE_DATA_FAILURE';
