import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';

import Header from './header';
import GroupPage from '../groups/GroupPage';
import AdminPage from '../admin';
import AppLoader from '../../router/AppLoader';

const AppPageContainer = ({ loggedIn, isAndcoAdmin }) => (
  <div>
    {loggedIn === null && <AppLoader /> }
    { loggedIn === false && <Redirect to="/auth/login" /> }
    { loggedIn === true && (
      <React.Fragment>
        <Header />
        <Switch>
          <Route path="/app/g/:groupCode" component={GroupPage} />
          { isAndcoAdmin && <Route path="/app/administration" component={AdminPage} /> }
          <Redirect exact from="/app/*" to="/" />
        </Switch>
      </React.Fragment>
    )
      }
  </div>
);

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  isAndcoAdmin: state.user.info.isAndcoAdmin,
});

export default connect(mapStateToProps)(AppPageContainer);
