import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormControl, Input, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import planTypes from '../../common/planTypes';
import { updateGroup } from '../../../actions';
import useStyles from '../../../theme/Styles';

const GroupView = ({ t, group, updateGroupAction, errorMessage, succeeded, isLoading }) => {
  const classes = useStyles()();
  const [groupName, setGroupName] = useState(group.info.name);
  const groupType = group.info.type;
  const [currentPlan, setCurrentPlan] = useState(group.info.planId in planTypes ? group.info.planId : '');
  const plansForTypeGroup = Object.keys(planTypes).filter(
    plan => plan.includes(group.info.type),
  );
  // const currentPlan = Object.keys(planTypes[groupType]).filter(
  //   key => (planTypes[groupType][key].type === group.info.subscriptionType),
  // )[0];
  // const [, setGroupPlan] = useState(currentPlan);
  const [showMessage, setShowMessage] = React.useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = React.useState(group.info.subscriptionStatus);

  const handleUpdate = () => {
    setShowMessage(true);
    updateGroupAction({
      ...group.info,
      subscriptionStatus,
      // type: groupType,
      name: groupName,
      planId: currentPlan,
      subscriptionType: planTypes[currentPlan].type,
      postsLimit: planTypes[currentPlan].postsLimit,
    });
  };

  const canSendForm = (
    !isEmpty(groupType)
    && !isEmpty(groupName)
    && !isEmpty(currentPlan)
    && !isLoading
  );

  // const updatePlansByGroup = (newGroupType) => {
  //   setPlansForTypeGroup(Object.keys(planTypes).filter(
  //     plan => plan.includes(newGroupType),
  //   ));
  //   setCurrentPlan('');
  // };

  return (
    <div>
      <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="my-input">{t('Nombre')}</InputLabel>
        <Input
          id="groupName"
          value={groupName}
          onChange={(e) => {
            setShowMessage(false); setGroupName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl style={{ width: '100%', marginTop: '10px' }}>
        <InputLabel htmlFor="my-input">{t('Tipo de Grupo')}</InputLabel>
        <Input
          id="groupType"
          value={groupType}
          inputprops={{
            readOnly: true,
          }}
        />
      </FormControl>
      <FormControl style={{ width: '100%', marginTop: '10px' }}>
        <InputLabel htmlFor="plans-group-select">{t('Tipo de Plan')}</InputLabel>
        <Select
          value={currentPlan}
          onChange={(e) => { setShowMessage(false); setCurrentPlan(e.target.value); }}
        >
          {
            plansForTypeGroup.map(
              key => <MenuItem value={key}>{t(planTypes[key].name)}</MenuItem>,
            )
          }
        </Select>
      </FormControl>
      <FormControl style={{ width: '100%', marginTop: '10px' }}>
        <InputLabel htmlFor="subscription-customized-select">{t('Estado Subscripcion')}</InputLabel>
        <Select
          value={subscriptionStatus}
          onChange={(e) => { setShowMessage(false); setSubscriptionStatus(e.target.value); }}
        >
          <MenuItem value="true">{t('Activo')}</MenuItem>
          <MenuItem value="false">{t('Inactivo')}</MenuItem>
        </Select>
      </FormControl>
      <div style={{ marginTop: '10px' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdate}
          style={{ marginRight: '10px' }}
          disabled={!canSendForm}
        >
          {t('Guardar cambios')}
        </Button>
        {!isEmpty(errorMessage)
          && showMessage
          && (
          <div className={classes.configInputFormError}>
            {errorMessage}
          </div>
          )}
        {succeeded
            && showMessage
            && (
            <div className={classes.configInputFormSuccess}>
              {t('Los datos fueron actualizados correctamente')}
            </div>
            )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.groups.newGroup.isLoading,
  errorMessage: state.groups.newGroup.errorMessage,
  succeeded: state.groups.newGroup.succeeded,
});

const mapDispatchToProps = {
  updateGroupAction: updateGroup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(GroupView));
