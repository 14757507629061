import React from 'react';
import { withTranslation } from 'react-i18next';

import useStyles from '../../theme/Styles';

const AdminUsers = ({ t }) => {
  const classes = useStyles()();
  return (
    <p className={classes.configFormTitle}>
      {t('Usuarios')}
    </p>
  );
};

export default withTranslation()(AdminUsers);
