import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { Close } from '@material-ui/icons';
import useStyles from '../../../theme/Styles';
import RegisteredCard from './RegisteredCard';
import NewCard from './NewCard';
import Succeed from './Succeed';

const PaymentConfirmation = ({ onClose, open, other, user }) => {
  const classes = useStyles()();
  const [succeed, setSucceed] = useState(false);
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="payment-dialog"
      onClose={onClose}
      {...other}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!succeed && user.stripeId && <RegisteredCard onSuccess={() => setSucceed(true)} />}
        {!succeed && !user.stripeId && <Elements><NewCard /></Elements>}
        {succeed && <Succeed />}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
});

export default connect(mapStateToProps, null)(PaymentConfirmation);
