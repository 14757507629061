import React from 'react';
import { isEmpty } from 'lodash';

import { Images } from '../../theme';
import useStyles from '../../theme/Styles';

export default ({ groupInfo, editing }) => {
  const classes = useStyles()();
  const getDefaultPhoto = () => {
    switch (groupInfo.type) {
      case 'family':
        return Images.family;
      case 'event':
        return Images.event;
      case 'business':
        return Images.business;
      case 'school':
        return Images.business;
      case 'geriatric':
        return Images.business;
      default:
        return '';
    }
  };
  const photoURL = isEmpty(groupInfo.photoURL) ? getDefaultPhoto() : groupInfo.photoURL;

  return (
    <img src={photoURL} alt="Grupo" className={editing ? classes.groupHeaderPhotoEdit : classes.groupHeaderPhoto} />
  );
};
