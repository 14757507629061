import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import UserRow from './UserRow';

import useStyles from '../../../theme/Styles';

const GroupConfig = ({ t, users }) => {
  const classes = useStyles()();

  const userGrid = users.map(user => <UserRow key={user.uid} user={user} />);
  return (
    <div className={classes.configCardContainer}>
      <p className={classes.configFormTitle}>
        {t('Usuarios Del Grupo')}
      </p>
      <Grid container className={classes.membersCongifContainer}>
        {userGrid}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  users: state.groups.current.users,
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation()(GroupConfig));
