import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { loadMagazines } from '../../../actions';
import NewsLoader from '../../common/NewsLoader';
import MagazineCard from '../admin_magazines/MagazineCard';

const GroupMagazines = ({ t, group, isLoading, errorMessage,
  actualGroupMagazines, loadSucceededGroupCode, loadMagazinesAction }) => {
  /* eslint-disable */
  useEffect(() => {
    if (!loadSucceededGroupCode !== group.info.groupCode) loadMagazinesAction(group.info.groupCode);
  }, []);
  /* eslint-enable */

  let magazineGrid = [];
  if (actualGroupMagazines) {
    magazineGrid = actualGroupMagazines.map(magazine => (
      <Grid item xs={12} sm={6} lg={4} key={magazine.key}>
        <MagazineCard magazine={magazine} groupName={group.info.name} />
      </Grid>
    ));
  }

  return (
    <div style={{ flexGrow: 1 }}>
      { isLoading && <NewsLoader /> }
      { (!isLoading && errorMessage) && <span>{errorMessage}</span>}
      { (!isLoading && !errorMessage && actualGroupMagazines.length === 0)
         && <span>{t('There are no magazines for this group,')}</span>
      }
      { (!isLoading && actualGroupMagazines.length !== 0)
        && (
        <Grid container spacing={2}>
          {magazineGrid}
        </Grid>
        )
      }
    </div>
  );
};


const mapDispatchToProps = {
  loadMagazinesAction: loadMagazines,
};

const mapStateToProps = state => ({
  loadSucceededGroupCode: state.magazines.loadSucceededGroupCode,
  isLoading: state.magazines.isLoading,
  errorMessage: state.magazines.errorMessage,
  actualGroupMagazines: state.magazines.actualGroupMagazines,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(GroupMagazines));
