import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Grow, Divider } from '@material-ui/core';
import { ArrowDropDown, Group } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import useStyles from '../../../theme/Styles';
import NewGroup from '../../groups/create_group/CreateGroupBasic';
import { loadGroupInfo, loadGroupUsers, loadMagazines } from '../../../actions';

const GroupMenu = ({ t, userGroups, history, currentCode,
  loadGroupInfoAction, loadGroupUsersAction, loadMagazinesAction }) => {
  const classes = useStyles()();

  const [open, setOpen] = useState(false);
  const [openNewGroup, setOpenNewGroup] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleToggle = () => setOpen(!open);

  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const openGroup = (code) => {
    setOpen(false);
    history.push(`/app/g/${code}`);
    loadGroupInfoAction(code);
    loadGroupUsersAction(code);
    loadMagazinesAction(code);
  };

  const menuItems = userGroups.map(group => (
    <MenuItem
      key={group.code}
      onClick={
      (group.code === currentCode) ? event => handleClose(event)
        : () => openGroup(group.code)}
    >
      {group.code === currentCode ? (
        <span style={{ fontWeight: 'bold' }}>
          <span dangerouslySetInnerHTML={{ __html: '&check;&nbsp' }} />
          {group.name}
        </span>
      ) : (
        <>{group.name}</>
      )}
    </MenuItem>
  ));

  return (
    <React.Fragment>
      <Button
        color="inherit"
        className={classes.margin}
        onClick={handleToggle}
        buttonRef={(node) => {
          setAnchorEl(node);
        }}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        <Group className={classNames(classes.iconLeft)} />
        {t('Grupos')}
        <ArrowDropDown className={classes.iconRight} />
      </Button>
      <Popper open={open} anchorEl={anchorEl} transition disablePortal className={classes.navbarDropdown}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'top' : 'bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuItems}
                  <Divider />
                  <MenuItem onClick={(e) => { setOpenNewGroup(true); handleClose(e); }}>{t('Crear Grupo')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      { openNewGroup && <NewGroup onClose={() => setOpenNewGroup(false)} />}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  userGroups: state.groups.userGroups,
  currentCode: state.groups.current.info.groupCode,
});

const mapDispatchToProps = {
  loadGroupInfoAction: loadGroupInfo,
  loadGroupUsersAction: loadGroupUsers,
  loadMagazinesAction: loadMagazines,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupMenu)));
