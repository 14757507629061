import {
  SET_NEW_DATA_PURCHASE,
  RESET_NEW_DATA_PURCHASE,
  LOAD_PURCHASES_SENDED_START,
  LOAD_PURCHASES_SENDED_SUCCESS,
  LOAD_PURCHASES_SENDED_FAILURE,
  LOAD_TO_SEND_PURCHASES_START,
  LOAD_TO_SEND_PURCHASES_SUCCESS,
  LOAD_TO_SEND_PURCHASES_FAILURE,
  UPDATE_PURCHASE_DATA_START,
  UPDATE_PURCHASE_DATA_SUCESS,
  UPDATE_PURCHASE_DATA_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  newPurchase: {
    magazine: {},
    group: {},
    user: {},
  },
  listToSentPurchases: {
    isLoading: false,
    error: false,
    succeeded: false,
    data: [],
  },
  listPurchasesSended: {
    isLoading: false,
    error: false,
    succeeded: false,
    data: [],
  },
  updateDataPurchase: {
    isLoading: false,
    errorMessage: '',
    succeeded: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_NEW_DATA_PURCHASE:
      return { ...state, newPurchase: { ...state.newPurchase, ...payload } };
    case RESET_NEW_DATA_PURCHASE:
      return { ...state, newPurchase: INITIAL_STATE.newPurchase };
    case LOAD_TO_SEND_PURCHASES_START:
      return { ...state, listToSentPurchases: { ...state.listToSentPurchases, isLoading: true } };
    case LOAD_TO_SEND_PURCHASES_SUCCESS:
      return { ...state,
        listToSentPurchases: { ...state.listToSentPurchases,
          isLoading: false,
          succeeded: true,
          data: payload.purchases } };
    case LOAD_TO_SEND_PURCHASES_FAILURE:
      return { ...state, listToSentPurchases: { ...state.listToSentPurchases, isLoading: false, error: true } };
    case LOAD_PURCHASES_SENDED_START:
      return { ...state, listPurchasesSended: { ...state.listPurchasesSended, isLoading: true } };
    case LOAD_PURCHASES_SENDED_SUCCESS:
      return { ...state,
        listPurchasesSended: { ...state.listPurchasesSended,
          isLoading: false,
          succeeded: true,
          data: payload.purchases } };
    case LOAD_PURCHASES_SENDED_FAILURE:
      return { ...state, listPurchasesSended: { ...state.listPurchasesSended, isLoading: false, error: true } };
    case UPDATE_PURCHASE_DATA_START:
      return { ...state,
        updateDataPurchase: { ...state.updateDataPurchase,
          isLoading: true,
          errorMessage: '',
          succeeded: false } };
    case UPDATE_PURCHASE_DATA_SUCESS:
      return { ...state,
        updateDataPurchase: { ...state.updateDataPurchase,
          isLoading: false,
          errorMessage: '',
          succeeded: true } };
    case UPDATE_PURCHASE_DATA_FAILURE:
      return { ...state,
        updateDataPurchase: { ...state.updateDataPurchase,
          isLoading: false,
          errorMessage: payload.errorMessage,
          succeeded: false } };
    default:
      return state;
  }
};
