import React from 'react';
import { connect } from 'react-redux';

import { IconButton } from '@material-ui/core';
import { FavoriteBorder, Favorite } from '@material-ui/icons';
import { likePost, unlikePost } from '../../../actions';

const PostLikeButton = ({ likePostAction, post, groupCode, userId, unlikePostAction, magazineId }) => {
  const { likes } = post;
  const postIsLiked = () => {
    let keys = 0;
    let response = false;
    if (likes) keys = Object.keys(likes).filter(x => likes[x].uid === userId);
    if (keys.length > 0) [response] = keys;
    return response;
  };

  const likePostHandler = () => {
    const postIsLikedApi = postIsLiked();
    // post, groupCode, magazineId
    if (postIsLikedApi) unlikePostAction(post, groupCode, postIsLikedApi, magazineId);
    else likePostAction(post, groupCode, magazineId);
  };

  const postIsLikedApi = postIsLiked();
  return (
    <IconButton
      onClick={likePostHandler}
    >
      {postIsLikedApi
        ? <Favorite />
        : <FavoriteBorder />
      }
    </IconButton>
  );
};

const mapDispatchToProps = {
  likePostAction: likePost,
  unlikePostAction: unlikePost,
};

const mapStateToProps = state => ({
  userId: state.user.info.uid,
});

export default connect(mapStateToProps, mapDispatchToProps)(PostLikeButton);
