/* eslint-disable */
export default {
  'Escribe tu mensaje': 'Escribe tu mensaje',
  'Publicar': 'Publicar',
  'Publicado': 'Publicado',
  'Miembro': 'Miembro',
  'Miembros': 'Miembros',
  'Revistas': 'Revistas',
  'Revista': 'Revista',
  'Publicación': 'Publicación',
  'Publicaciones': 'Publicaciones',
  'Familiares': 'Familiares',
  'Enviar Invitación': 'Enviar Invitación',
  'Ver Perfil': 'Ver Perfil',
  'Editar Perfil': 'Editar Perfil',
  'Configuración': 'Configuración',
  'Español': 'Español',
  'Ingles': 'Ingles',
  'Francés': 'Francés',
  'Cambiar foto': 'Cambiar foto',
  'Los datos fueron actualizados correctamente': 'Los datos fueron actualizados correctamente',
  'Nombre': 'Nombre',
  'Apellido': 'Apellido',
  'Información Personal': 'Información Personal',
  'Actualizar': 'Actualizar',
  'Menú': 'Menú',
  'No se pude guardar los cambios. Por favor intente nuevamente.': 'No se pude guardar los cambios. Por favor intente nuevamente.',
  'Nuevo Grupo': 'Nuevo Grupo',
  'Familia': 'Familia',
  'Negocio': 'Negocio',
  'Evento': 'Evento',
  'Atrás': 'Atrás',
  'Datos del nuevo grupo': 'Datos del nuevo grupo',
  'Continuar': 'Continuar',
  'Por favor seleccione su plan de suscripción': 'Por favor seleccione su plan de suscripción',
  'Basic': 'Basic',
  'Home': 'Home',
  'Pro': 'Pro',
  'Mes': 'Mes',
  'Usuarios': 'Usuarios',
  'Usuarios ilimitados': 'Usuarios ilimitados',
  'Seleccionar': 'Seleccionar',
  'Datos del administrador': 'Datos del administrador',
  'Apellido': 'Apellido',
  'Email': 'Email',
  'Contraseña': 'Contraseña',
  'Crear Grupo': 'Crear Grupo',
  'de': 'de', // 10 de 20...
  'Descargar': 'Descargar',
  'Recortar imagen': 'Recortar imagen',
  'Cancelar': 'Cancelar',
  'Grupos': 'Grupos',
  'Unirme a un grupo': 'Unirme a un grupo',
  'Vista Previa': 'Vista Previa',
  'Para nuestra querida abuelita': 'Para nuestra querida abuelita',
  'Revista del grupo': 'Revista del grupo',
  'Lenguaje por defecto grupo': 'Lenguaje por defecto grupo',
  'Titulo de la revista (portada)': 'Titulo de la revista (portada)',
  'Frase de la revista (portada)': 'Frase de la revista (portada)',
  'Dirección de envío': 'Dirección de envío',
  'Ciudad': 'Ciudad',
  'Pais': 'Pais',
  'Recuperar contrasena': 'Recuperar contraseña',
  'Hemos enviado un correo con las instrucciones para recuperar tu contrasena': 'Hemos enviado un correo con las instrucciones para recuperar tu contraseña',
  'Para Enviar': 'Para Enviar',
  'Enviados': 'Enviados',
  'Fecha compra': 'Fecha compra',
  'Fecha envio': 'Fecha envio',
  'Direccion': 'Dirección',
  'Descargar PDF': 'Descargar PDF',
  'Link': 'Link',
  'No disponible': 'No disponible',
  'Marcar como enviado': 'Marcar como enviado',
  'Estado Subscripcion': 'Estado Subscripción',
  'Editar foto de perfil': 'Editar foto de perfil',
  'Grupo': 'Grupo',
  'Faltan completar datos': 'Faltan completar datos',
  'Para invitar a otras personas a este grupo, enviales el siguiente codigo para que lo utilicen al momento de crear una cuenta': 'Para invitar a otras personas a este grupo, envíales el siguiente código para que lo utilicen al momento de crear una cuenta',
  'O enviales una invitacion por correo con el siguiente formulario': 'O envíales una invitación por correo con el siguiente formulario',
  'Faltan {{dias}} días': 'Faltan {{dias}} días',
  'Edit group image' : 'Editar imagen de grupo',
  'Direccion de envio': 'Dirección de envío',
  'Titulo de revista': 'Titulo de revista',
  'Frase de revista': 'Frase de revista',
  'Estado': 'Departamento',
  'Ciudad': 'Ciudad',
  'Codigo Zip': 'Codigo Zip',
  'No le diste Me Gusta a ninguna publicacion': 'No le diste Me Gusta a ninguna publicación',
  'Editar': 'Editar',
  'Eliminar': 'Eliminar',
  'Comprar': 'Comprar',
  'Cumpleanos': 'Cumpleaños',
  'Lenguaje del grupo': 'Lenguaje del grupo',
  'Estado Suscripcion': 'Estado de Suscripción',
  'Cerrar Sesion': 'Cerrar Sesión',
  'Generar PDF': 'Generar PDF',
  'Emails enviados': 'Emails enviados',
  'No disponible, intente mas tarde': 'No disponible, intente mas tarde',
  'Llenar emails': 'Llenar emails',
  'Escribe los correos electronicos seguidos por un espacio': 'Escribe los correos electrónicos seguidos por un espacio',
  'Enviar': 'Enviar',
  'Cerrar': 'Cerrar',
  'Esta no revista no contiene ninguna publicación': 'Esta no revista no contiene ninguna publicación',
  'Aún no hay ninguna publicación.': 'Aún no hay ninguna publicación.',
  'Se el primero en publicar!': 'Se el primero en publicar!',
  'Nueva Publicación': 'Nueva Publicación',
  'Notificaciones': 'Notificaciones',
  'Notificarme cuando alguien añada una publicacion': 'Notificarme cuando alguien añada una publicación',
  'Notificarme cuando la revista esta por cerrarse': 'Notificarme cuando la revista está por cerrarse',
  'Notificarme cuando una nueva revista fue creada': 'Notificarme cuando una nueva revista fue creada',
  'Suelta tu foto aquí': 'Suelta tu foto aquí',
  'Arrastra aquí tu foto o': 'Arrastra aquí tu foto o',
  'Selecciona un archivo': 'Selecciona un archivo',
  'Por favor ingresar correos electrónicos': 'Por favor ingresar correos electrónicos',
  'La invitación fue enviada correctamente': 'La invitación fue enviada correctamente',
  'No se pudo enviar la invitación, por favor intente mas tarde': 'No se pudo enviar la invitación, por favor intente mas tarde',
  'Deshacer Envio': 'Deshacer Envio',
};
