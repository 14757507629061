import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase';

import { userLoggedIn, userLoggedOut } from '../actions';
import AppLoader from './AppLoader';
import LandingPage from '../components/landing/LandingPage';
import MobileLandingPage from '../components/landing/MobileLandingPage';
import AppPageContainer from '../components/app/AppPageContainer';
import configureI18n from '../config/I18n';

class AppRouter extends Component {
  componentDidMount() {
    const { userLoggedInAction, userLoggedOutAction } = this.props;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        userLoggedInAction(user);
      } else {
        userLoggedOutAction();
      }
    });
  }

  render() {
    const { lang } = this.props;
    configureI18n(lang);

    return (
      <Router>
        <div>
          <Route exact path="/" component={AppLoader} />
          <Route path="/auth" component={LandingPage} />
          <Route path="/app" component={AppPageContainer} />
          <Route path="/mobile/auth" component={MobileLandingPage} />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  lang: state.user.config.lang,
});

const mapDispatchToProps = {
  userLoggedInAction: userLoggedIn,
  userLoggedOutAction: userLoggedOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
