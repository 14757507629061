import React from 'react';
import { Grid } from '@material-ui/core';

import useStyles from '../../theme/Styles';
import PostEmpty from '../posts/PostEmpty';
import PostNewsFeed from '../posts/PostNewsFeed';

const ProfilePosts = ({ posts }) => {
  const classes = useStyles()();
  return (
    <div className={classes.content} style={{ marginTop: '20px' }}>
      <Grid container>
        <Grid container spacing={4}>
          {posts.length > 0
            ? (
              <React.Fragment>
                {posts.map(post => (
                  <Grid item md={7} xs={12} key={post.key}>
                    <PostNewsFeed post={post} groupCode="sad" magazineId={post.key} />
                  </Grid>
                ))}
              </React.Fragment>
            )
            : (
              <Grid item md={7} xs={12} key={1}>
                <PostEmpty />
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfilePosts;
