import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Divider } from '@material-ui/core';

import useStyles from '../../../theme/Styles';

const GroupCard = ({ t, group, setShowGroup }) => {
  const classes = useStyles()();
  return (
    <Card
      className={classes.adminCard}
      style={{ cursor: 'pointer' }}
      onClick={() => setShowGroup(group)}
    >
      <CardContent>
        <Typography
          variant="h6"
          className={classes.titleColor}
          style={{ textAlign: 'left' }}
        >
          {group.info.name}
        </Typography>
        <Divider className={classes.adminCardDivider} />
        <table>
          <tbody>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Id')}
        :
              </th>
              <td>
                {group.info.groupCode}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Type')}
          :
              </th>
              <td>
                {group.info.type}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Subscription type')}
          :
              </th>
              <td>
                {group.info.subscriptionType}
              </td>
            </tr>
            <tr>
              <th className={classes.titleColor} style={{ textAlign: 'left' }}>
                {t('Users count')}
          :
              </th>
              <td>
                {group.users ? Object.keys(group.users).length : 0}
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};

export default withTranslation()(GroupCard);
