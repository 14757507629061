import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { TextField, FormControl, Button, CircularProgress,
  InputLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import AvatarChange from './AvatarChange';
import useStyles from '../../../theme/Styles';
import { updateUserInfo, resetUpdateUserState } from '../../../actions';

const UserConfig = ({ t, user, updateUserInfoAction, updatedInfo,
  groupCode, resetUpdateUserStateAction, groupType }) => {
  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.lastname);
  const [showMessage, setShowMessage] = useState(true);
  const [birthday, setBirthday] = useState(user.birthday);
  const classes = useStyles()();

  const { photoURL } = user;
  const [photoURLToUpload, setPhotoURLToUpload] = useState(isEmpty(photoURL) ? '' : photoURL);
  const [photoFileToUpload, setPhotoFileToUpload] = useState(null);

  const { notifications } = user;
  const [newPost, setNewPost] = useState(notifications === undefined ? true : notifications.newPost);
  const [dueDate, setDueDate] = useState(notifications === undefined ? true : notifications.dueDate);
  const [magazineCreated, setMagazineCreated] = useState(notifications === undefined
    ? true : notifications.magazineCreated);

  const selectImageToUpload = (url, photoFile) => {
    if (url && photoFile) {
      setPhotoURLToUpload(url);
      setPhotoFileToUpload(photoFile);
    } else {
      setPhotoFileToUpload(null);
      setPhotoURLToUpload(isEmpty(photoURL) ? '' : photoURL);
    }
  };

  const handleUpdateUser = () => {
    if (!isEmpty(name) && !isEmpty(lastName)) {
      setShowMessage(true);
      const updatedUser = {
        ...user,
        name,
        lastname: lastName,
        photoURL: photoURLToUpload,
        birthday,
        notifications: {
          newPost,
          dueDate,
          magazineCreated,
        },
      };
      updateUserInfoAction(updatedUser, photoFileToUpload, groupCode);
    }
  };

  if (updatedInfo.succeeded && showMessage) {
    setTimeout(() => resetUpdateUserStateAction(),
      4000);
  }

  return (
    <div className={classes.configCardContainer}>
      <p className={classes.configFormTitle}>
        {t('Información Personal')}
      </p>
      <AvatarChange user={user} currentPhoto={photoURLToUpload} selectImageToUpload={selectImageToUpload} />
      <FormControl className={classes.configInputFormControl}>
        <TextField
          id="nameInput"
          label={t('Nombre')}
          value={name}
          onChange={(e) => { setName(e.target.value); setShowMessage(false); }}
          margin="normal"
          className={classes.configInputField}
        />
        <TextField
          id="lastNameInput"
          type="lastName"
          label={t('Apellido')}
          value={lastName}
          onChange={(e) => { setLastName(e.target.value); setShowMessage(false); }}
          margin="normal"
          className={classes.configInputField}
        />
      </FormControl>
      { groupType === 'family'
        && (
        <FormControl className={classes.configInputFormControl}>
          <InputLabel htmlFor="birthday-input" className={classes.landingInputFieldLabel}>
            {birthday ? '' : t('Cumpleanos')}
          </InputLabel>
          <TextField
            id="birthday-input"
            label={birthday ? t('Cumpleanos') : ''}
            InputLabelProps={{ shrink: true }}
            value={birthday}
            type="date"
            onChange={(e) => { setBirthday(e.target.value); }}
            margin="normal"
            className={classes.landingInputField}
          />
        </FormControl>
        )}
      <p className={classes.configFormTitle}>
        {t('Notificaciones')}
      </p>
      <FormControl style={{ minWidth: '100%' }}>
        <FormControlLabel
          control={
            <Checkbox checked={newPost} name="newPost" color="primary" onChange={e => setNewPost(e.target.checked)} />
          }
          label={t('Notificarme cuando alguien añada una publicacion')}
        />
        <FormControlLabel
          control={
            <Checkbox checked={dueDate} name="dueDate" color="primary" onChange={e => setDueDate(e.target.checked)} />
          }
          label={t('Notificarme cuando la revista esta por cerrarse')}
        />
        <FormControlLabel
          control={
            <Checkbox checked={magazineCreated} name="magazineCreated" color="primary" onChange={e => setMagazineCreated(e.target.checked)} />
          }
          label={t('Notificarme cuando una nueva revista fue creada')}
        />
      </FormControl>
      <Button
        className={classes.configInputButton}
        color="inherit"
        variant="outlined"
        disabled={isEmpty(name) || isEmpty(lastName) || updatedInfo.isLoading}
        onClick={handleUpdateUser}
      >
        { updatedInfo.isLoading && <CircularProgress size={20} className={classes.configButtonProgress} />}
        {t('Actualizar')}
      </Button>
      {!isEmpty(updatedInfo.errorMessage)
        && showMessage
        && (
        <div className={classes.configInputFormError}>
          {updatedInfo.errorMessage}
        </div>
        )}
      {updatedInfo.succeeded
          && showMessage
          && (
          <div className={classes.configInputFormSuccess}>
            {t('Los datos fueron Actualizados correctamente')}
          </div>
          )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.info,
  updatedInfo: state.user.updateInfo,
  groupCode: state.groups.current.info.groupCode,
  groupType: state.groups.current.info.type,
});

const mapDispatchToProps = {
  updateUserInfoAction: updateUserInfo,
  resetUpdateUserStateAction: resetUpdateUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserConfig));
