import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TextField, FormControl, Button, CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useStyles from '../../../theme/Styles';
import { clearGroupInfo, signUp } from '../../../actions';

const SignUpForm = ({ groupInfo, clearGroupInfoAction, signUpAction, errorMessage, isLoginLoading, role, t }) => {
  const classes = useStyles()();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [showError, setShowError] = React.useState(false);

  const canSendForm = (
    !isEmpty(email)
      && !isEmpty(password)
      && !isEmpty(name)
      && !isEmpty(lastName)
      && !isLoginLoading
  );
  const handleSubmit = () => {
    if (canSendForm) {
      setShowError(true);
      signUpAction(groupInfo.groupCode, name, lastName, email, password,
        role || 'publisher', () => {}, null, null, null, groupInfo.language);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && canSendForm) {
      setShowError(true);
      signUpAction(groupInfo.groupCode, name, lastName, email, password, role || 'publisher', () => {});
    }
  };

  return (
    <React.Fragment>

      <div className={classes.ladingInputFormContainer}>
        <p className={classes.landingInputFormTitle}>{t('Registrarse')}</p>
        <p className={classes.signUpGroupName}>
          <strong>Grupo: </strong>
          {`${groupInfo.name} (${groupInfo.type})` }
          <a
            href="#"
            className={classes.signUpChangeGroup}
            onClick={() => clearGroupInfoAction()}
          >
            {t('Cambiar')}
          </a>
        </p>
        <FormControl className={classes.landingInputFormControl}>
          <TextField
            id="nameInput"
            label={t('Nombre')}
            value={name}
            onChange={(e) => { setName(e.target.value); setShowError(false); }}
            margin="normal"
            className={classes.landingInputField}
            onKeyPress={handleKeyPress}
          />
        </FormControl>
        <FormControl className={classes.landingInputFormControl}>
          <TextField
            id="lastNameInput"
            label={t('Apellido')}
            value={lastName}
            onChange={(e) => { setLastName(e.target.value); setShowError(false); }}
            margin="normal"
            className={classes.landingInputField}
            onKeyPress={handleKeyPress}
          />
        </FormControl>
        <FormControl className={classes.landingInputFormControl}>
          <TextField
            id="signUpEmailInput"
            label={t('Email')}
            value={email}
            onChange={(e) => { setEmail(e.target.value); setShowError(false); }}
            margin="normal"
            className={classes.landingInputField}
            onKeyPress={handleKeyPress}
          />
        </FormControl>
        <FormControl className={classes.landingInputFormControl}>
          <TextField
            id="signUpPasswordInput"
            type="password"
            label={t('Contraseña')}
            value={password}
            onChange={(e) => { setPassword(e.target.value); setShowError(false); }}
            margin="normal"
            className={classes.landingInputField}
            onKeyPress={handleKeyPress}
          />
        </FormControl>
        <Button
          className={classes.landingInputButton}
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!canSendForm}
        >
          {isLoginLoading && <CircularProgress size={20} className={classes.landingButtonProgress} /> }
          {t('Registrarme')}
        </Button>
        { !isEmpty(errorMessage)
              && showError
              && <div className={classes.landingInputFormError}>{errorMessage}</div>
            }
      </div>

    </React.Fragment>
  );
};

const mapDispatchToProps = {
  clearGroupInfoAction: clearGroupInfo,
  signUpAction: signUp,
};

const mapStateToProps = state => ({
  errorMessage: state.user.errorMessage,
  isLoginLoading: state.user.isLoginLoading,
  role: state.groups.current.info.defaultRole,
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignUpForm));
