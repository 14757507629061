import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { withTranslation } from 'react-i18next';

import useStyles from '../../../theme/Styles';
import { loadAllGroups } from '../../../actions';
import NewsLoader from '../../common/NewsLoader';
import ErrorCard from '../ErrorCard';
import GroupCard from './GroupCard';
import GroupView from './GroupView';

const AdminGroups = ({ t, loadAllGroupsAction, groups, succeeded, error, isLoading }) => {
  const classes = useStyles()();
  const [showGroup, setShowGroup] = useState(false);
  /* eslint-disable */
  useEffect(() => {
    if (!succeeded) loadAllGroupsAction();
  }, []);
  /* eslint-enable */

  let groupsGrid = [];
  if (groups) {
    groupsGrid = groups.map(group => (
      <Grid item xs={12} sm={6} lg={4} key={group.info.name + group.info.createdAt}>
        <GroupCard group={group} setShowGroup={setShowGroup} />
      </Grid>
    ));
  }


  return (
    <div>
      { isLoading && <NewsLoader /> }
      { (!isLoading && error) && <ErrorCard />}
      { (!isLoading && !error)
        && (
        <span>
          <p className={classes.configFormTitle}>
            {showGroup
              ? (
                <React.Fragment>
                  <span
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => setShowGroup(false)}
                  >
                    {t('Grupos')}
                  </span>
                  <span>
                    {` > ${showGroup.info.groupCode}`}
                  </span>
                </React.Fragment>
              )
              : t('Grupos')
            }
          </p>
          {showGroup ? <GroupView group={showGroup} /> : (
            <Grid container spacing={2}>
              {groupsGrid}
            </Grid>
          )}
        </span>
        )
    }
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.groups.allGroups.isLoading,
  error: state.groups.allGroups.error,
  succeeded: state.groups.allGroups.succeeded,
  groups: state.groups.allGroups.data,
});

const mapDispatchToProps = {
  loadAllGroupsAction: loadAllGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminGroups));
